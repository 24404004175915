import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import mobxState from 'routes/PublicComponents/Market/AllTeachers/store/mobxState';
import cl from './CheckboxFilter.module.css'
import _ from 'lodash'
import useGetFitredData from 'components/UI/Filters/ListFilter/API/useGetElemsByFilters';


const CheckboxFilter = observer(({ Titile, FilterParametr, urlFilterByParams }) => {
    const [defaultList, setDefultList] = useState([])
    const GetFitredData = useGetFitredData(urlFilterByParams)

    useEffect(() => {
        if (mobxState.CheckBoxListFilters[FilterParametr] != null && FilterParametr != null) {
            let deftList = mobxState.CheckBoxListFilters[FilterParametr].map(el => el.id)
            mobxState.SetData(
                {
                    FiltersToFetch: { ...mobxState.FiltersToFetch, [FilterParametr]: deftList },
                    DefaultFilters: { ...mobxState.FiltersToFetch, [FilterParametr]: deftList }
                }
            )
            setDefultList(deftList)
        }
    }, [mobxState.CheckBoxListFilters[FilterParametr]])

    const getObjParamByNamber = (obj, order) => {
        var keys = Object.keys(obj); 
        return (obj[keys[order]]); 
    }
    useEffect(() => {
        let ExistFilter = []
        if (
            mobxState.CoursesList != null && mobxState.CheckBoxListFilters[FilterParametr] != undefined) {
            mobxState.CoursesList.forEach((cur) => mobxState.CheckBoxListFilters[FilterParametr].findIndex(el => el.id === cur[getObjParamByNamber(mobxState.CheckBoxListFilters[FilterParametr], 2)]) > -1 ? ExistFilter.push(cur[getObjParamByNamber(mobxState.CheckBoxListFilters[FilterParametr], 2)]) : null);
        }
    }, [mobxState.CoursesList])

    const setToGlobalFilter = async (id) => {
        var arrayToFetch = []
        var mobxCheckboxFilter = mobxState.FiltersToFetch[FilterParametr]
        if (_.isEqual(mobxState.FiltersToFetch[FilterParametr], mobxState.DefaultFilters[FilterParametr])) {
            arrayToFetch.push(id)
        } else {
            if (mobxCheckboxFilter.indexOf(id) === -1) {
                var arrayToFetch = [...mobxCheckboxFilter]
                arrayToFetch.push(id)
            }
            else {
                var arrayToFetch = [...mobxCheckboxFilter]
                arrayToFetch = arrayToFetch.filter(el => el !== id)
            }
        }
        var currentArray = arrayToFetch.length === 0 ? defaultList : arrayToFetch
        var newFilters = { ...mobxState.FiltersToFetch, [FilterParametr]: currentArray }
        mobxState.SetData(
            {
                FiltersToFetch: newFilters,
                CurrentPage: 0
            }
        )
        var result = await GetFitredData({ page: 0, ...newFilters })
        mobxState.SetData({ ElementsList: result.data })
        mobxState.setTotalCountOfElements(result.headers['x-total-count'])
    }

    return (
        <>
            {mobxState.CheckBoxListFilters[FilterParametr] != null
                ?

                <>
                    <legend>{Titile}</legend>
                    <fieldset className={cl.FieldListWrap} >
                        {mobxState.CheckBoxListFilters[FilterParametr].map((param, index) => (
                            <div key={param.id} className={cl.filterFieldsetElement}>
                                <input
                                    checked={
                                        !(mobxState?.FiltersToFetch[FilterParametr] == null) &&
                                        !(_.isEqual(mobxState.FiltersToFetch[FilterParametr], mobxState.DefaultFilters[FilterParametr])) &&
                                        (mobxState.FiltersToFetch[FilterParametr].indexOf(param.id) > -1)
                                    }
                                    onChange={(e) => setToGlobalFilter(param.id)} id={FilterParametr + index} value={param.id} type="checkbox"></input>
                                <label htmlFor={FilterParametr + index}>
                                    {getObjParamByNamber(param, 1)}
                                </label>
                            </div>
                        ))}
                    </fieldset>

                </>

                :
                null
            }
        </>
    );
});
export default CheckboxFilter;