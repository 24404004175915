import React from 'react';
import cl from './Stepper.module.css'
import cn from 'classnames';

const Stepper = ({ CurrentStep, MaxSteps, className }) => {
    return (
        <div className={cn(cl.Stapper, className)}>
            <div className={cl.StapperWrap }>
                {[...Array(MaxSteps)].map((n, i) => 
                <div key = {i} className={cn(cl.StepperElement, { [cl.active]: CurrentStep >= (i + 1) }, cl['step' + Number(i + 1) ])}>
                    <p>{i + 1}</p>
                </div>)}
            </div>
        </div>
    );
};

export default Stepper;