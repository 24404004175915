import React, { useState, useRef, useEffect } from 'react';
import useOnClickOutside from 'components/hooks/useOnClickOutside';
import { CardWrap } from './styled/CardWrap.styled';
import { ButtonCard } from './styled/ButtonCard.styled';
import { FaGear } from "react-icons/fa6";

const CardPay = ({
    bank,
    numberCard,
    system,
    width,
    whRef,
    select,
    isPrimary,
    setPrimary,
    deleteCard
}) => {

    const [stateCard, setStateCard] = useState()
    const ref = useRef()
    useOnClickOutside(ref, () => setStateCard(false))

    useEffect(() => {
        setStateCard(select)
    }, [select])

    return (
        <>
            {
                stateCard ?
                    <CardWrap
                        isPrimary={isPrimary}
                        ref={ref}
                        width={width}
                        onClick={() => setStateCard(state => !state)}
                        active={stateCard}
                    >
                        {
                            !isPrimary ?
                                <ButtonCard onClick={setPrimary} type={'primary'}>
                                    <p>Основная</p>
                                </ButtonCard>
                                : null
                        }
                        <ButtonCard type={'delete'} onClick={deleteCard}>
                            <p>Удалить</p>
                        </ButtonCard>
                    </CardWrap>
                    :
                    < CardWrap
                        isPrimary={isPrimary}
                        onClick={() => !isPrimary && setStateCard(state => !state)}
                        width={width}
                        ref={whRef}
                        bank={bank}
                    >
                        <div>
                            <p>{bank?.toUpperCase()}</p>
                            {
                                isPrimary ?
                                    <p>основная</p>
                                    : <FaGear style={{ minWidth: '12px', minWidth: '12px', width: '12px', height: '12px' }} />
                            }
                        </div>
                        <div>
                            <p>{numberCard}</p>
                            <p>{system?.toUpperCase()}</p>
                        </div>
                    </CardWrap >
            }
        </>
    );
};

export default CardPay;