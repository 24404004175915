import React from 'react';
import cl from './CustomImage.module.css'
import cn from 'classnames';

const CustomImage = ({ data, style, classNameNames, config }) => {
    return (
        <div className={cn(cl.ImgWrap, data.stretched && cl.stretched,)}>
            <figure            
                className={cn(
                    cl.figure,
                    data.caption && cl.caption,
                    data.withBackground && cl.Background,
                    data.withBorder && cl.border,
                    !data.stretched && cl.stretched,
                )}>
                <img src={data.file.url} alt={data.caption || ''} className={cl.img} />
            </figure>
            {
                data.caption &&
                <figcaption className={cl.figcaption}>{data.caption}</figcaption>
            }
        </div>
    );
};
export default CustomImage;