import { MySelect } from 'components/UI/Selects';
import React, { useEffect, useState } from 'react';
import allCoursesState from 'store/allCoursesState';
import { observer } from 'mobx-react-lite';
import { getFiltredPage } from './CoursesAPI';

const CoursesSort = observer(({ parametrsList }) => {

    const [Selected, setSelected] = useState({ parametr: null, direction: null })
    useEffect(() => {
        if (Object.keys(allCoursesState.FetchFiltersList).length === 9) {
            const getListAwait = async () => {
                var result = await getFiltredPage(
                    allCoursesState.CurrentPage,
                    allCoursesState.FetchFiltersList,
                    allCoursesState.ElementsOnPage,
                    Selected.parametr,
                    Selected.direction,
                    allCoursesState.SearchValue
                )
                allCoursesState.setCoursesList(result.data)
                allCoursesState.setTotalCountOfElements(result.headers['x-total-count'])
            }
            getListAwait()
        }
    }, [Selected, allCoursesState.FetchFiltersList]);

    return (
        <div>
            <MySelect
                name="education_rank_id"
                current={'1'}
                onChange={(e) => setSelected({
                    ...Selected,
                    parametr: parametrsList[e.target.value].parametr,
                    direction: parametrsList[e.target.value].direction
                })}
                selectlist={parametrsList}
                selectedvalue='name'
            >
            </MySelect>

        </div>
    );
});
export default CoursesSort;