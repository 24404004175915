import React, { useEffect, useState } from 'react';
import cl from './QuestionText.module.css'
import _ from 'lodash'
import ListVertical from 'components/UI/Layouts/ListVertical';
import { host } from 'routes/globals';
import SimpleImg from 'components/UI/Images/SimpleImg/SimpleImg';
import { useForm } from 'react-hook-form';
import { api } from 'api';
import { BlueButton, GreenButton, RedButton } from 'components/UI/Buttons';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { useParams } from 'react-router-dom';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import { SpacedApartWrap } from 'routes/UserComponents/ProfilePage/pages/styled/ProfileStyles';
import { BlueQuestionButton, DarkGreenQuestionButton, IoChevronForwardRotate, QuestionImgStyled, QuestionTextStyled, RedQuestionBtn } from '../../styled/QuestionTextStyled';
import { IoChevronForward } from 'react-icons/io5';
import { ChevronRotate } from 'components/UI/Other';

const QuestionText = ({ question, isEdit, EditAction, DeleteAction, AddNewAnswer, QuestionIndex, CancelEUpdate }) => {
    const [QuestionImg, setQuestionImg] = useState(null)
    // const [OldQuesation, setOldQuesation] = useState(null)
    const [ShowFull, setShowFull] = useState(false)
    const [LocalQuestion, setLocalQuestion] = useState({})
    const { id } = useParams()

    const { register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onSubmit' });

    //получение изображеия и сброс данных
    useEffect(() => {
        if (question.imgQuest == null) {
            setQuestionImg(null)
        } else {
            fetch(`${host}/api/GetFile/${question.imgQuest
                }`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
                .then(response => response.status === 200 && response.blob(response))
                .then(blob => {
                    if (blob) {
                        const fileURL = URL.createObjectURL(blob)
                        setQuestionImg(fileURL)
                    }
                    else {
                        setQuestionImg(null)
                    }
                })
        }
        const qData = JSON.parse(JSON.stringify(question))
        const ValidateData = { textQuest: qData?.textQuest, questionAnswer: qData?.answerOptions[0]?.textAnswer || '' }
        reset(ValidateData)
        setLocalQuestion(qData)
    }, [question]);

    const uploadPhoto = async (event) => {
        var file = event.target.files;
        setQuestionImg(URL.createObjectURL(file[0]))
        const formData = new FormData();
        formData.append('file', file[0]);
        setLocalQuestion({ ...LocalQuestion, imgQuest: await api.post('/uploadfile', formData) })
    }

    //Сохранение данных вопроса и его ответов
    const saveQuestion = (data) => {
        var { answerOptions, ...mainData } = LocalQuestion
        mainData.textQuest = data.textQuest
        if (mainData.textQuest !== question.textQuest) {
            api.put(`Question/${LocalQuestion.id}`, { ...mainData, SurveyId: id })
                .then((result) => {
                    if (!result.status == 200 || !result.status == 204) {
                        var { answerOptions, ...other } = question
                        mainData = other
                    }
                })
        }

        question.answerOptions = [{ ...LocalQuestion?.answerOptions[0], textAnswer: watch('questionAnswer') }]
        // Сохранение ответов на вопрос если были изменения  
        if (!_.isEqual(LocalQuestion?.answerOptions, question.answerOptions)) {
            if (question?.answerOptions[0]?.id != null) { // Если списоку ответов не пустой
                //Если ответа существовал, то обноляем запросом в базеу и отправляем обновление в верхний компонент 
                api.put(`AnswerOption/${LocalQuestion?.answerOptions[0].id}`, { QuestionId: LocalQuestion.id, TextAnswer: data.questionAnswer , right: true, ImgId: 0  })
                    .then(result => {
                        AddNewAnswer(LocalQuestion.id, { ...LocalQuestion.answerOptions[0], textAnswer: data.questionAnswer }, mainData, LocalQuestion.type)
                    })
            }
            else {//Если список ответов пустой, значит мы создаём первый ответ 
                api.post(`AnswerOption`, { QuestionId: LocalQuestion.id, TextAnswer: watch('questionAnswer'), right: true, ImgId: null })
                    //Если ответ не существовал, то мы его создаём запросом в базу и отправляем обновление в верхний компонент 
                    .then(result => {
                        AddNewAnswer(LocalQuestion.id, { ...result.data, QuestionId: LocalQuestion.id, TextAnswer: data.questionAnswer, right: true, ImgId: null }, mainData, LocalQuestion.type)
                    })
            }
        } else {
            //Если ответы не менялись
            AddNewAnswer(LocalQuestion.id, LocalQuestion.answerOptions[0], mainData, LocalQuestion.type)
        }
        EditAction(false)
    }

    const saveCancel = () => {
        setLocalQuestion({ ...question })
        EditAction(false)
    }

    // const UpdateAnswer = (AnswerValue) => {
    //     var UpdateLocalQuestion = { ...LocalQuestion }
    //     if (UpdateLocalQuestion.answerOptions.length === 0) {
    //         UpdateLocalQuestion.answerOptions[0] =
    //         {
    //             QuestionId: LocalQuestion.id,
    //             Rigt: true,
    //             ImgId: null,
    //             Survey: id,
    //             textAnswer: null
    //         }
    //         UpdateLocalQuestion.answerOptions[0].textAnswer = AnswerValue
    //     } else {
    //         UpdateLocalQuestion.answerOptions[0].textAnswer = AnswerValue
    //     }
    //     setLocalQuestion(UpdateLocalQuestion)
    // }

    return (
        <>

            {isEdit
                ?
                <>
                    <InputValidateUseForm
                        title={`Вопрос ${QuestionIndex + 1}`}
                        type={'text'}
                        error={errors?.textQuest}
                        validateSet={register("textQuest", {
                            required: "Пожалуйста, введите текст вопроса",
                            minLength: {
                                value: 2,
                                message: 'Минимум 2 символа'
                            }
                        })}
                    />
                    {/* <p>Ответ:</p> */}
                    {QuestionImg !== null
                        ?
                        <QuestionTextStyled style={{ justifyContent: 'center' }} >
                            <SimpleImg
                                src={QuestionImg}
                                maxW={'400px'}
                            />
                        </QuestionTextStyled>
                        :
                        'Место под картинку'
                    }

                    <input
                        className={cl.InputImg}
                        type={'file'}
                        onChange={(e) => uploadPhoto(e)}
                        accept='image/jpeg,image/png'
                    />

                    <InputValidateUseForm
                        title={'Текст правильного ответа'}
                        type={'text'}
                        error={errors?.questionAnswer}
                        validateSet={register("questionAnswer", {
                            required: "Пожалуйста, введите текст ответа",
                            minLength: {
                                value: 2,
                                message: 'Минимум 2 символа'
                            },
                        })}
                    />
                    <SpacedApartWrap>
                        <RedQuestionBtn type={'submit'} onClick={() => saveCancel(false)} > Отменить</RedQuestionBtn>
                        <DarkGreenQuestionButton type={'submit'}
                            onClick={handleSubmit(saveQuestion)}
                        //onClick={() => saveQuestion()}
                        > Сохранить</DarkGreenQuestionButton>
                    </SpacedApartWrap>
                </>
                :
                <>
                    <QuestionTextStyled style={{ cursor: 'pointer' }} ShowUnderLine={!ShowFull}
                        onClick={() => setShowFull(!ShowFull)} >

                        №{QuestionIndex + 1 + ' ' + LocalQuestion?.textQuest}

                        <ChevronRotate triggerParam={ShowFull} />

                    </QuestionTextStyled>
                    {ShowFull
                        ?
                        <>
                            {
                                QuestionImg
                                    ?
                                    <QuestionTextStyled style={{ justifyContent: 'center' }}>
                                        <SimpleImg
                                            src={QuestionImg}
                                            maxW={'400px'}
                                        />
                                    </QuestionTextStyled>
                                    :
                                    null
                            }
                            <p>Ответ:</p>
                            {(LocalQuestion?.answerOptions?.length && LocalQuestion?.answerOptions?.length != 0)
                                ?
                                LocalQuestion?.answerOptions[0]?.textAnswer
                                :
                                <p> У вопроса нет ответа</p>
                            }
                            <SpacedApartWrap>
                                <RedQuestionBtn type={'button'}
                                    onClick={() => DeleteAction(true)} > Удалить</RedQuestionBtn>
                                <BlueQuestionButton type={'button'}
                                    onClick={() => EditAction(true)} > Редактировать</BlueQuestionButton>
                            </SpacedApartWrap>
                        </>
                        : null
                    }


                </>
            }
        </>
    );
};
export default QuestionText;