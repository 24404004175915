import React, { useState } from 'react';
import { FullWrap } from '../Payments/Components/PayHistoryElement/styled/HistoryElem.styled';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import { usePassEncrypt } from 'components/hooks';
import { api } from 'api';
import { AcceptButton } from 'components/UI/Buttons';
import { MainPopup } from 'components/UI/Popups';
import { MainWrap } from 'components/UI/Layouts';
import { DefaultWrapper } from 'components/UI/Wrappers';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import useDeviceInfomation from 'modules/PageLogin/hooks';
import { EditProfileForm } from '../styled/ProfileStyles';


const PasswordRecovery = () => {
    const [SendOnMail, setSendOnMail] = useState(false)
    const [redirect, setRedirect] = useState(null)
    // const [success, setSuccess] = useState(location.state)
    const { link } = useParams()
    const device = useDeviceInfomation()
    const location = useLocation();
    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'OnInput' });

    const passEncrypt = usePassEncrypt()

    const SetNewPassWord = (data) => {
        setSendOnMail(true);
        data.newpassword !== data.newPasswordConffirm
        ? setError('newPasswordConffirm', { validate: '' })
        : (() => {
        // ---------------------------------------
        api.post(`/user/confirmation/${location.pathname.split('/').pop()}`, {
            password: passEncrypt(data.newpassword),
            device_type_id: device.type,
            platform: '123',
            geo: device.geo == {} ? "" : JSON.stringify(device.geo),
            system: device.system
        })
            .then(result =>  
                setRedirect(`/confirmation/SuccessRecovery`))
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    setError('password', { type: 'custom', message: 'Неверный пароль' });
                }
                if (error.response.status === 403) {
                    setError('password', { type: 'custom', message: 'Недействительная ссылка' });
                }
            })
        })()
    }
    return (
        // <DefaultWrapper width={'100%'}></DefaultWrapper>
        redirect !== null ?
            <Navigate to={'/login'} state={{ change: 'success', message: 'Ваш пароль успешно сброшен, пожалуйста авторизуйтесь' }} />
            :
            <MainWrap width={'100%'}>
                <DefaultWrapper>
                    <EditProfileForm onSubmit={handleSubmit(SetNewPassWord)} >
                        <ListVertical className={'account_Data'}>
                            <h3>Введите новый пароль</h3>
                            <InputValidateUseForm
                                title={'Новый пароль'}
                                type={'password'}
                                error={errors?.newpassword}
                                validateSet={register("newpassword", {
                                    required: "Пожалуйста введите новый пароль.",
                                    // validate: value => value == watch('newPasswordConffirm') || 'пароли не ссвпадают' 
                                })}
                            />
                            <InputValidateUseForm
                                title={'Подтверждение пароля'}
                                type={'password'}
                                error={errors?.newPasswordConffirm}
                                validateSet={register("newPasswordConffirm", {
                                    required: "Пожалуйста введите новый пароль ещё раз",
                                    validate: value => value == watch('newpassword') || 'пароли не совпадают'
                                })}
                            />
                            <AcceptButton type={'submit'} title={'Сменить'} />
                        </ListVertical>
                    </EditProfileForm>
                    <MainPopup
                        isOpened={SendOnMail}
                        onClose={e => setSendOnMail(false)}
                        title={'!!!'}
                        showHeader={true}>
                        Письмо с подтверждением смены пароля отправлено вам на электроную почту!!
                        <AcceptButton title={'Отлично'} onClick={() => setSendOnMail(false)}></AcceptButton>
                    </MainPopup>

                </DefaultWrapper >
            </MainWrap >
    );
};
export default PasswordRecovery;