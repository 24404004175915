import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Loader } from 'components/UI/Loaders';
import cl from '../PublicCoursStyle.module.css'
import { SimpleWhiteButton } from 'components/UI/Buttons';
import { api } from 'api';
import { useParams } from 'react-router';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { ReviewsCounter } from 'components/UI/Other';
import _ from 'lodash'
import { MainPopup } from 'components/UI/Popups';
import { host } from 'routes/globals';
import BuyConfirmCard from 'components/UI/Cards/BuyConfirmCard/BuyConfirmCard';
import { useListbyFetchPost } from 'components/hooks';
import { EmptyFile } from 'components/UI/Fillers';

const CoursDescription = ({ CourseData, directories_data, buyCourse }) => {
    const [ByCoursVisibility, setByCoursVisibility] = useState(false)
    const [SelectedGroupToBy, setSelectedGroupToBy] = useState(null)
    const [BuyStatus, setBuyStatus] = useState(false)
    const { id } = useParams()

    useEffect(() => {
        api.get("/Course/cupil/" + id)
            .then(result => {
                setBuyStatus(result.data)
            })
    }, [CourseData])

    return (
        <div className={cn(cl.PublicTextParams, 'default-style')}>
            {(CourseData == null && directories_data) ? <Loader /> :
                <div className={cn(cl.publicCourseInformation)}>
                    <ListVertical className={cl.MainContent}>
                        <div className={cl.MainBlockText}>
                            <div className={cl.publicСourseImg}>
                                <img src={CourseData?.previewImg ? host + '/api/getFile/' + CourseData?.previewImg : EmptyFile} />
                            </div>
                            <div className={cl.PublicСourseParams}>
                                <div className={cl.PublicCourseInput}>
                                    <h2>{CourseData?.label}</h2>
                                </div>
                                <ReviewsCounter />
                                <p>
                                    <span className={cl.coursParametr}>Предмет: </span>
                                    <span className={cl.paramValue}>
                                        {directories_data?.learning_subjects.map(subject => (subject.id == CourseData?.courseSubjectID ? subject.subject : null))}
                                    </span>
                                </p>

                                <p>
                                    <span className={cl.coursParametr}>Длительность курса: </span>
                                    <span className={cl.paramValue}>
                                        {CourseData?.duration} ч
                                    </span>
                                </p>
                            </div>
                            <MainPopup
                                isOpened={ByCoursVisibility}
                                onClose={e => setByCoursVisibility(false)}
                                title='Подтверждение покупки'
                                className={cl.buyPopup}
                            >
                                <BuyConfirmCard
                                    item={{
                                        ...CourseData,
                                        courseSubject: { ...directories_data?.learning_subjects?.find(subject => subject.id == CourseData?.courseSubjectID) }
                                    }}

                                    // }}
                                    // buyAction={setAlreadyBuy}
                                    buyAction={() => { setBuyStatus(true) }}
                                    Photo={host + '/api/getFile/' + CourseData?.previewImg}
                                />
                            </MainPopup>
                        </div>
                        <div className={cl.description}>
                            <p className={cl.DescriptionTitle}>Описание</p>
                            <p className={cl.DescriptionText}>{CourseData?.description}</p>
                        </div>
                    </ListVertical>
                    <div className={cl.rightButtonsSection}>
                        <div className={cl.PriceAndFavor}>
                            {buyCourse == null
                                ? null
                                : <span className={cl.price}> {CourseData?.price} P </span>
                            }
                        </div>

                        {buyCourse == null ? null :
                            BuyStatus ?
                                <SimpleWhiteButton className={cn(cl.buyBtn, cl.disabled)} disabled={true} title={'куплено'} />
                                :
                                <SimpleWhiteButton className={cl.buyBtn} onClick={e => setByCoursVisibility(true)} title={CourseData?.price > 0 ? 'Купить' : 'Получить'} />
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default CoursDescription;