import { api } from 'api';
import { DefaultWrapper } from 'components/UI/Wrappers';
import React, { useEffect, useState } from 'react';
import cl from './CardCourseTeacher.module.css'
import cn from 'classnames';
import ListVertical from 'components/UI/Layouts/ListVertical';
import SimpleImg from 'components/UI/Images/SimpleImg/SimpleImg';
import { MySwitch } from 'components/UI/Switches';
import { AcceptButton, BlueButton, ButtonNo, ButtonYes, RedButton, SimpleWhiteButton } from 'components/UI/Buttons';
import { useNavigate } from 'react-router-dom';
import { ModalSmall } from 'components/UI/Modals';
import { MainPopup } from 'components/UI/Popups';
import { EmptyFile } from 'components/UI/Fillers';
import { IoChevronForward, IoChevronForwardOutline } from 'react-icons/io5';
import { ReviewsCounter } from 'components/UI/Other';
import { host } from 'routes/globals';
import CourseActionBtn from './components/CourseActionBtn/CourseActionBtn';
import PublicateCours from './components/PublicateCours/PublicateCours';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { ContentHeader } from 'routes/UserComponents/ProfilePage/pages/styled/ProfileStyles';
import { useRef } from 'react';
import { BlueQuestionButton, RedQuestionBtn } from 'routes/TeacherComponents/TestConstructor/components/TeacherQuestionsList/styled/QuestionTextStyled';

const CardCourseTeacher = ({ Courses, setCourses, cours, index, ChangePublick, Subjects }) => {

    const navigate = useNavigate();
    const [LocalCourseData, setLocalCoursData] = useState(cours)
    const [DeleteVisibility, setDeleteVisibility] = useState(false)
    const [FullCard, setFullCard] = useState(true)
    const [PublicateOnOver, setPublicateOnOver] = useState(false)
    const [PublicationeError, setPublicationeError] = useState(false)
    const [CoursImg, setCoursImg] = useState('')
    const [ErrorCode, setErrorCode] = useState(null)
    const [ShowAll, setShowAll] = useState(false)

    const DescriptiontRef = useRef(null);
    useEffect(() => {
        cours != {} && setLocalCoursData(cours)
        cours.previewImg != null ?
            fetch(`${host}/api/GetFile/${cours.previewImg}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
                .then(response => response.status == 200 && response.blob(response)
                )
                .then(blob => {
                    if (blob) {
                        setCoursImg(URL.createObjectURL(blob))
                    } else {
                        setCoursImg(EmptyFile)
                    }
                })
            : setCoursImg(EmptyFile)

    }, [cours])

    const changePublic = () => {
        api.put(`/course/${cours.id}`, { ...LocalCourseData, isPublic: !LocalCourseData.isPublic })
            .then((result) => {
                setLocalCoursData({ ...LocalCourseData, isPublic: !LocalCourseData.isPublic })
            })
            .catch(e => {
                setPublicationeError(true)
                setErrorCode(e.response.status)
            }
            )
    }
    const DeleteCourse = (courseId) => {
        api.delete(`course/${LocalCourseData.id}`)
        setCourses(Courses.filter(el => el.id != LocalCourseData.id))
        setDeleteVisibility(false)
    }
    return (
        <>
            <DefaultWrapper className={cl.courseCard}>
                <ContentHeader className={cn(cl.cardTitle, FullCard ? cl.full : null)} >
                    <ContentHorizontal onClick={() => setFullCard(!FullCard)}
                        style={{ width: '100%', maxWidth: '60%', padding: '28px 30px' }} as='div' >
                        {FullCard
                            ? <IoChevronForward className={cl.arrowIcon} />
                            : <IoChevronForward className={cn(cl.arrowIconcn, cl.down)} />
                        }
                        {LocalCourseData.label}
                    </ContentHorizontal>
                    <ContentHorizontal style={{ padding: '26px 30px' }} as='div' >
                        <PublicateCours
                            pubStatus={LocalCourseData.isPublic}
                            overAction={value => setPublicateOnOver(value)}
                            texts={{ publicate: 'Опубликовать', unpublicate: 'Снять', isPublicate: 'Опубликовано' }}
                            mainAction={() => changePublic()}
                            PublicateOnOver={PublicateOnOver}
                            className={cn(cl.courseBtn, cl.publicate,
                                PublicateOnOver === true
                                    ? LocalCourseData.isPublic == 0
                                        ? null
                                        : cl.active
                                    : LocalCourseData.isPublic == 0
                                        ? null
                                        : cl.active)}
                        />
                        <CourseActionBtn text='Перейти'
                            action={() => navigate(`/teachercabinete/course/${LocalCourseData.id}/info`)}
                            className={cn(cl.courseBtn, cl.change)} />
                        <CourseActionBtn
                            text={'Удалить'}
                            action={() => setDeleteVisibility(true)}
                            className={cn(cl.courseBtn, cl.delete)}
                        />
                    </ContentHorizontal>
                </ContentHeader>

                <div className={cn(cl.mainContent, FullCard ? cl.fullContent : null)}>

                    <ListVertical className={cl.ImageSection}>
                        <SimpleImg className={cl.courseImg} src={CoursImg} />
                    </ListVertical>

                    <ListVertical className={cl.ContentSection}>
                        <p className={cl.price}> {LocalCourseData.price != null ? <>{LocalCourseData.price} P</> : 'бесплатно'} </p>
                        <ReviewsCounter />
                        <ContentHorizontal style={{ alignItems: 'end' }}>
                            <p className={cl.Label}>Предмет:</p>
                            {Subjects?.map(subject => (
                                subject.id == LocalCourseData.courseSubjectID &&
                                <p key={String(subject.id) + String(cours.id)} >{subject.subject} </p>
                            ))}

                            <p className={cl.Label}>Длительность: </p>
                            <p> {LocalCourseData.duration} ч </p>
                        </ContentHorizontal>


                        {LocalCourseData.description &&
                            <div className={cl.descriptionWrap}>
                                <p className={cl.bigLabel}><span className={cl.Label}>Описание:</span></p>
                                <p ref={DescriptiontRef} className={cn(cl.descriptionWrap, ShowAll ? cl.full : null)}>
                                    {LocalCourseData.description}
                                </p>
                                {DescriptiontRef?.current?.clientHeight > 50
                                    &&
                                    <p className={cl.ShowDescriptionBtn} onClick={() => { setShowAll(!ShowAll) }}>{ShowAll ? 'Скрыть' : 'Показать ещё'}</p>}
                            </div>
                        }
                    </ListVertical>

                    {/* <ListVertical className={cl.ButtonsSection}>






                    </ListVertical> */}
                </div>

                <MainPopup
                    isOpened={DeleteVisibility}
                    onClose={e => setDeleteVisibility(false)}
                    title={'Вы точно хотите удалить курс?'}
                >

                    {/* <div className={cl.DeleteBtnsWrap}> */}
                        <ContentHorizontal style = {{gap: '15px'}} >
                            <RedQuestionBtn text='Да' onClick={e => DeleteCourse()} />
                            <BlueQuestionButton text='Нет' onClick={e => setDeleteVisibility(false)} />
                        </ContentHorizontal>
                    {/* </div> */}
                </MainPopup>

                <MainPopup
                    isOpened={PublicationeError}
                    onClose={e => setPublicationeError(false)}
                    className={cl.InfoPopup}
                    title={'Невозможно опубликовать!!!'}
                    showHeader={true}
                >
                    <p >
                        {{
                            480: 'Возможно в курсе нет уроков',
                            481: 'Пожалуйста, привяжите карту',
                            null: null
                        }[ErrorCode]}
                    </p>
                    <div className={cl.DeleteBtnsWrap}>
                        <AcceptButton className={cl.AcceptBtn}
                            title={'ОК'}
                            onClick={e => { setPublicationeError(false); setErrorCode(null) }}
                        />
                    </div>
                </MainPopup>
            </DefaultWrapper>
        </>
    );
};

export default CardCourseTeacher;