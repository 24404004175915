import styled from 'styled-components';

export const RoundImg = styled.div`
width: ${props => props.width ? props.width : 'auto'};
height:  ${props => props.height ? props.height : 'auto'};
aspect-ratio:  1;
border-radius: 50%;
overflow: hidden;
object-position: center;
object-fit: cover;
& img {    
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
`
