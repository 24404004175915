import React from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import HeaderMain from 'modules/HeaderMain';
import SuppWidget from 'components/system/SuppWidget';

const Layout = observer((props) => {

    return (
        <>
            <HeaderMain />
            <Outlet />
            <SuppWidget />
        </>
    );
}
)

export default Layout;