import { useEffect, useState } from 'react';

export const useGetDeviceType = () => {
    const [type, setType] = useState('')

    useEffect(() => {
        if (/ Android | webOS | iPhone | iPad | iPod | BlackBerry | BB | PlayBook | IEMobile | Windows Phone | Kindle | Silk | Opera Mini /i.test(navigator.userAgent)) {
            setType('Mobile')
        } else {
            setType('PC')
        }
    }, [])

    return type;
};
