import React from 'react';
import { ModerationHeaderWrap } from './styled';

const ModerationHeader = ({ Status }) => {
    return (
        <ModerationHeaderWrap Status={Status}>
            <p>
                {Status === 0 ? 'Принято.Курс Опубликован' :
                    Status === 1 ? 'Отправленно на модерацию' :
                        Status === 2 ? 'Отклонено.Узнать причину' :
                            Status === 3 ? 'Опубликовать' : null}
            </p>
        </ModerationHeaderWrap>
    );
};

export default ModerationHeader;