import React, { useState, useEffect, useCallback, } from 'react';
import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from '../tools'
import { TRANSLATE_EDITOR_JS } from '../translations'
import defaultLessonText from '../DdefaultLessonText';
import cl from '../EditorJS.module.css'
import cn from 'classnames';
import '../style_ce-block.css'
import '../style.css';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import { DragDrop } from 'editorjs-drag-drop';
import { Undo } from 'editorjs-undo';
import { api } from 'api';
import { CourseDescription, EscapeLesson, Lesson, LessonArea, LessonHeader, LessonsSidebar, MoveBtn, NavBtns, StudyingWrap } from 'routes/UserComponents/MyLearn/LessonShow/LearnPrev/LearmPrev.styled';
import { observer } from 'mobx-react-lite';
import EditorStore from 'store/EditorStore';

const ConstructorComponent = observer(({ IdParams }) => {
    const navigate = useNavigate();
    const [currentIdParams, setCurrentIdParams] = useState(null)
    const [FullLessonData, setFullLessonData] = useState({})
    const [CourseInfo, setCourseInfo] = useState(null)

    const [currentCourse, setCurrentCourse] = useState(null)

    const ReactEditorJS = createReactEditorJS()
    const editorCore = React.useRef(null)





    useEffect(() => {
        async function getLessonContent() {
            var response = await api.get("/Lesson/" + IdParams.lesson_id)
            var CourseInformation = await api.get("/Module/Course/" + IdParams.course_id)
            var IncommingData = null
            if (await response.data[0].content == '' || await response.data[0].content == '{}' || await response.data[0].content == null || JSON.parse(response.data[0].content).blocks.length == 0) {
                IncommingData = defaultLessonText
            } else {
                IncommingData = JSON.parse(response.data[0].content)
            }
            var result = await { ...response.data[0], content: IncommingData }
            setFullLessonData(await result)
            setCourseInfo(IdParams.course_id != currentCourse ? await CourseInformation.data.cource : CourseInfo)
            editorCore?.current?.dangerouslyLowLevelInstance?.render(result.content)
        }

        if (IdParams.lesson_id != null) {
            setCurrentIdParams(IdParams)
            getLessonContent()
        }
    }, [IdParams])

    useEffect(() => {
        if (EditorStore.Command != null) {
            EditorStore.Command === 'save' && handleSave();
            EditorStore.Command === 'clear' && handleClear();
            EditorStore.setCommand(null)
            // EditorStore.Command !== null && EditorStore.setCommand(null)
        }
        // handleSaveSimple()
    }, [EditorStore.Command])

    const handleSave = async () => {
        const savedData = await editorCore.current.save();
        api.put(`/Lesson/sovok/${IdParams.lesson_id}`, { ...FullLessonData, content: JSON.stringify(savedData) })
            .then((result) => {
                setFullLessonData({ ...FullLessonData, content: savedData })
                EditorStore.setCommand(null)
            })
    }

    const handleSaveSimple = async () => {
        // await editorCore.current.dangerouslyLowLevelInstance?.save();
    }

    const handleClear = async () => {
        await editorCore?.current?.dangerouslyLowLevelInstance?.clear();
        EditorStore.setCommand(null)
    }

    const CompareHandler = async () => {
    }

    const handleInitialize = (instance) => {
        instance._editorJS.isReady
            .then(() => {
                editorCore.current = instance;
            })
            .catch((err) => console.log("An error occured", err));
    };

    const handleReady = async () => {
        if (editorCore.current != null) {
            const editor = editorCore.current._editorJS;
            new Undo({ editor })
            new DragDrop(editor);
        }
    };


    return (
        <>
            {FullLessonData.content != null
                ?
                <LessonArea>
                    <ReactEditorJS
                        holder="custom"
                        onInitialize={handleInitialize}
                        onReady={handleReady}
                        tools={EDITOR_JS_TOOLS}
                        i18n={TRANSLATE_EDITOR_JS}
                        defaultValue={FullLessonData.content != null ? { ...FullLessonData.content } : { ...defaultLessonText }}
                        onChange={(e) => CompareHandler()}
                        onLoad={() => handleSaveSimple()}
                    />
                </LessonArea>
                : null
            }
        </>
    )
})
export default ConstructorComponent

