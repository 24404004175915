import React, { useEffect, useState } from 'react';
import cl from './BuyConfirmCard.module.css'
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { BlueButton, GreenButton, RedButton } from 'components/UI/Buttons';
import cn from 'classnames';
import ImgDefault from 'components/UI/Images/ImgDefault/ImgDefault';
import { CustomLink } from 'components/UI/Links';
import { LextLinesIOverflow } from 'components/UI/Containers/TextLinesOveflow/TextLinesOveflowStyle.styed';
import { api } from 'api';
import { EmptyFile } from 'components/UI/Fillers';

const BuyConfirmCard = ({ item, Photo, buyAction }) => {
    const [FullInfo, SetFullInfo] = useState(false)
    const [isBought, setisBought] = useState(false)
    const [isError, setisError] = useState(false)

    const Buy = () => {
        api.post('/PayCours/' + item.id)
            .then(() => {
                setisBought(true);
                setisError(false)
                buyAction()
            }
            )
            .catch(e => {
                setisBought(true);
                setisError(true)
            })
    }

    useEffect(() => {

    }, [item])
    return (
        <>

            {isBought
                ?
                !isError
                    ?
                    <ListVertical>
                        <h3>Поздравляем</h3>
                        <h3>Покупка совершена</h3>
                        <CustomLink className={cl.ConfirmButton} to={'/mylearn/courseshow/' + item.id}>Перейти к курсу</CustomLink>
                    </ListVertical>
                    :
                    <ListVertical>
                        <h3>Что то пошло не так!!!</h3>
                        <h3>Попробовать снова</h3>
                        <BlueButton className={cl.ConfirmButton} onClick={() => { setisBought(false); setisError(false) }}>
                            Вернуться назад
                        </BlueButton>
                    </ListVertical>
                :
                <ListVertical className={cl.Card}>
                    <h2 className={cl.CourseLabel}>
                        {item.label}
                    </h2>
                    <ContentHorizontal className={cl.upSection} >
                        <ImgDefault $maxHeight={'145px'} $maxWidth={'230px'} src={item?.previewImg ? Photo : EmptyFile} />
                        <ListVertical className={cn(cl.CourseParams)} >
                            <h4>Предмет: <span className={cl.subject}>{item?.courseSubject?.subject}</span></h4>
                            <h4 className={cn(cl.Duration, cl.dark)}>Время: {item.duration} часов</h4>
                            <h4>Цена:  {item.price}Р </h4>
                            <RedButton text={'Купить'} className={cl.agreeButton} onClick={e => Buy(false)}>Купить </RedButton>
                        </ListVertical>
                    </ContentHorizontal>
                    <LextLinesIOverflow overflow={FullInfo} className={cl.buttons}>
                        {item.description}
                    </LextLinesIOverflow  >
                    <ContentHorizontal className={cl.buttons}>
                        <div onClick={() => SetFullInfo(!FullInfo)} className={cl.left}>
                            {FullInfo
                                ?
                                'Скрыть информацию'
                                :
                                'Показать информацию'
                            }
                        </div>
                        <CustomLink className={cl.right} to={'/courses/coursedetail/' + item.id}>Подробнее</CustomLink>
                    </ContentHorizontal>
                </ListVertical>
            }
        </>

    );
};

export default BuyConfirmCard;