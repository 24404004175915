import styled from 'styled-components';

export const CheckBoxZone = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: ${({ size }) => size || '30px'};
height: ${({ size }) => size || '30px'};
min-width: ${({ size }) => size || '30px'};
min-height: ${({ size }) => size || '30px'};
border-radius: ${({ radius }) => radius || '10px'};
background: ${({ value }) => value ? '#0049D4' : '#fff'};
border: ${({ value }) => !value && '1px solid #A0A0A0'};
color: #fff;

&:hover {
        cursor: pointer;
        background-color: ${({ value }) => !value && '#A0A0A0'};
    }
`