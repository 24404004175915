import React from 'react';
import cl from './AcceptButtons.module.css'
import  cn  from 'classnames';

const CancelButton = ({title, onClick, disabled, className}) => {
    return (
        <button title={title} className={cn( cl.cancel, className)} onClick={onClick}>{title}</button>
    );
};

export default CancelButton;