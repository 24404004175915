import styled from "styled-components";

export const Icon = styled.button`
    position: absolute;
    top: 50%;
    ${props => props.right ? 'right:0;' : 'left: 0;'};
    ${props => props.right ? 'transform: translate(50%, -50%)' : 'transform: translate(-50%, -50%)'};
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #0E5FFF;

    &>svg {
        font-size: 22px;
        color: #fff;
        ${props => !props.right && 'rotate: 180deg'}
    }
`