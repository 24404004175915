import React from 'react';
import cl from './CustomAlert.module.css'
import { IoIosAlert } from "react-icons/io";

const CustomAlert = ({ data }) => {
    return (
        <div className={cl.alertBlock}>
            <p>  <IoIosAlert />{data.title}</p>
            <p>{data.message}</p>
        </div >
    );
};

export default CustomAlert;

