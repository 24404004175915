import ImgDefault from 'components/UI/Images/ImgDefault/ImgDefault';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { DefaultWrapper } from 'components/UI/Wrappers';
import React, { useEffect, useState } from 'react';
import { HystoryElem, FullWrap } from './styled/HistoryElem.styled';
import { api } from 'api';
import _ from 'lodash'
import { DateCreation, DateHighlighter } from './styled/DateHighlighter';
import { PriceHighlighter } from './styled/PriceHighlighter';
import { host } from 'routes/globals';
import { EmptyFile } from 'components/UI/Fillers';

const PayHistoryElement = () => {
    const [PayState, setPayState] = useState(null)

    useEffect(() => {
        //Самовызывающася функция      
        (async () => {
            await api.get('/GetPay')
                .then(res => setPayState(_.orderBy(res.data, (element) => new Date(element.createdAt).getTime(), 'desc')))
        })()



    }, [])

    return (

        <FullWrap width={'100%'}>
            <ListVertical>
                <h2>История платежей</h2>
                {PayState?.map((pa, index) => (
                    <ListVertical>
                        {/*  ------------- на года ------------ */}
                        {index != 0 && (new Date(pa?.createdAt).getFullYear() - new Date(PayState[(Number(index)) - 1].createdAt).getFullYear())
                            ? <DateHighlighter>{new Date(pa?.createdAt).getFullYear()} г</DateHighlighter>
                            : null}
                        {/* ----------на месяца ---------- */}
                        {index != 0 && new Date().getFullYear() == (new Date(pa?.createdAt)).getFullYear() &&
                            'месяца' && ((new Date(pa?.createdAt).getMonth() - new Date(PayState[(Number(index)) - 1].createdAt).getMonth()) !== 0)
                            ?
                            <DateHighlighter>{new Date(pa?.createdAt).toLocaleString('default', { month: 'long' })}</DateHighlighter>
                            : null
                        }
                        {
                            ((new Date(pa?.createdAt).getFullYear()) == new Date().getFullYear()) && //совпадают года
                            ((new Date(pa?.createdAt).getMonth()) == new Date().getMonth()) && //совпадает месяц
                            ((new Date(pa?.createdAt).getDate()) == (new Date().getDate())) &&  // один день
                            (PayState[Number(index - 1)] == undefined) &&
                            <DateHighlighter>Cегодня, {new Date(pa.createdAt).getDate()}  {new Date(pa?.createdAt).toLocaleString('default', { month: 'long' })} </DateHighlighter> //нет предыдущего
                        }
                        {
                            ((new Date(pa?.createdAt).getFullYear()) == new Date().getFullYear()) && //совпадают года
                            ((new Date(pa?.createdAt).getMonth()) == new Date().getMonth()) && //совпадает месяц
                            (new Date(pa?.createdAt).getDate() + 1) == (new Date().getDate()) && // 'это предыдущий день'
                            (((new Date(PayState[(Number(index - 1))]?.createdAt).getDate()) == new Date().getDate()) ||
                                ((new Date(PayState[(Number(index - 1))]?.createdAt).getDate()) == undefined)) &&
                            <DateHighlighter>
                                Вчера, {new Date(pa?.createdAt).getDate()}  {new Date(pa?.createdAt).toLocaleString('default', { month: 'long' })}
                            </DateHighlighter>  // предыдущтий это сегодня
                        }
                        {index != 0 &&
                            ((new Date(PayState[(Number(index - 1))].createdAt).getFullYear()) == new Date().getFullYear()) && //совпадают года
                            ((new Date(PayState[(Number(index - 1))].createdAt).getMonth()) == new Date().getMonth()) && //совпадает месяц
                            ((new Date()).getDate() - (new Date(PayState[(Number(index - 1))]?.createdAt).getDate())) == 1 && //  у предыдущего разница с сегодня 1 день разницы
                            ((new Date()).getDate() - (new Date(pa?.createdAt).getDate())) > 1 && // но у текущего нет этой разницы
                            <DateHighlighter>Ранее</DateHighlighter>
                        }
                        {/* ----------на дни ---------- */}
                        <HystoryElem
                            key={pa.id} >
                            <ContentHorizontal >
                                <ImgDefault
                                    imgId={pa.prewiewImg}
                                    width={'60px'}
                                    height={'50px'}
                                    borderRadius={'10px'}
                                />
                                <div className='uuuu'>
                                    <p>{pa.courseName}</p>
                                    <DateCreation>{new Date(pa?.createdAt).toLocaleString()}</DateCreation>
                                </div>
                            </ContentHorizontal>
                            {(() => {
                                switch (pa.status) {
                                    case "оплачено": return <PriceHighlighter textcolor={'#313131'}> <span> {pa.sum} P</span>  <span>{pa.status}</span></PriceHighlighter>
                                    case "выведено": return <PriceHighlighter textcolor={'#39AA95'}> <span> + {pa.sum} P</span>  <span>{pa.status}</span></PriceHighlighter>
                                    case "отменено": return <PriceHighlighter textcolor={'#FF3F3F'}> <span> - {pa.sum} P</span>  <span>{pa.status}</span></PriceHighlighter>
                                    default: return null
                                }
                            })()}
                        </HystoryElem>
                    </ListVertical>
                ))}
            </ListVertical>
        </FullWrap>
    );
};

export default PayHistoryElement;