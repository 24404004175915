import React from 'react';
import cl from './BackButton.module.css';
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';

const BackButton = ({ className, additionalText }) => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    return (
        <button onClick={goBack} type={'button'} className={cn(cl.backButton, className)}>
            <span> {additionalText ? additionalText : null} Назад</span>
        </button>
    );
};

export default BackButton;