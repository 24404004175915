import styled from "styled-components";

export const ModerationHeaderWrap = styled.div`
background-color: ${({ Status }) => 
        Status === 0 ? 'var(--base-green-color)' :
        Status === 1 ? '#FFC56F' :
            Status === 2 ? '#FF5F5F' :
                Status === 3 ? '#E8F1FF' : null

    }; 
color: ${({ Status }) => 
        Status === 0 ? '#fff' :
        Status === 1 ? '#000' :
            Status === 2 ? '#fff' :
                Status === 3 ? 'var( --base-blue-color)' : null
    
    }; 
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
`