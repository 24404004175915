import React, { useEffect, useState } from 'react';
import cl from '../PublicCoursStyle.module.css'
import { SimpleAvatar } from 'components/UI/Avatars';
import { Link } from 'react-router-dom';
import { EmptyAvatar } from 'components/UI/Fillers';
import WrapperNoShadow from 'components/UI/Wrappers/WrapperNoShadow';
import { ReviewsCounter } from 'components/UI/Other';
import { host } from 'routes/globals';

const CreatorInformation = ({ Creator_id, CurrentCoursId, CreatorInfo }) => {
    const [CreatorInformation, setCreatorInformation] = useState()
    const [CreatorCourses, setCreatorCourses] = useState()
    useEffect(() => {
        setCreatorInformation(CreatorInfo)
    }, [CreatorInfo])
    return (
        <>
            {CreatorInformation != null ?
                <div className={cl.CreatorCard}>
                    <Link className={cl.creator} to={"/searchteachers/pageteacher/" + CreatorInfo.id}>
                        <div className={cl.CreatorAvatar}   >
                            <img src={host + '/api/GetFile/' + CreatorInfo?.user_photo_ID ?? EmptyAvatar} alt="" />
                        </div>
                    </Link >
                    <div className={cl.CreatorInformation}>
                        <div className={cl.content}>
                            <p className={cl.creator}>
                                {(CreatorInformation?.user_name && CreatorInformation?.user_patronymic && CreatorInformation?.user_surname) === undefined ? 'Создатель курса неизвестен' :
                                    ((CreatorInformation.user_name ?? '') + ' ' + (CreatorInformation?.user_patronymic ?? '') + ' ' + (CreatorInformation?.user_surname ?? ''))}
                            </p>
                            <div className={cl.profession}>
                                {CreatorInformation?.user_gmt_time
                                    ?
                                    <p>
                                        <span className={cl.label}>Время по МСК</span>
                                        <span> {CreatorInformation?.user_gmt_time}</span>
                                    </p>
                                    : null}
                                {CreatorInformation?.user_birthday
                                    ?
                                    <p> <span className={cl.label}>Возраст</span>
                                        <span> {Math.floor(Number(new Date() - new Date(CreatorInformation.user_birthday)) / (1000 * 60 * 60 * 24 * 30 * 12))}</span>
                                        <span> {CreatorInformation?.age}</span>
                                    </p>
                                    : null}
                                {CreatorInformation?.experience
                                    ?
                                    <p>
                                        <span className={cl.label}>Стаж</span>
                                        <span> {CreatorInformation?.experience}</span>
                                    </p>
                                    : null
                                }

                            </div>

                            <ReviewsCounter />
                            {CreatorInformation?.user_description
                                ?
                                <p>
                                    <span className={cl.label}>О себе</span>
                                    <span> {CreatorInformation?.user_description}</span>
                                </p>
                                : null
                            }
                        </div>
                    </div>
                </div>
                : null}
        </ >
    );
};

export default CreatorInformation;