import React from 'react';
import cl from './MyModal.module.css'
import { AcceptButton, CancelButton } from 'components/UI/Buttons';
import ClosePopupBtn from './ClosePopupBtn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';

const MyModal = ({ children, visible, setVisible, title, confirmButtons = true, addNewItem, refInput, className, AcceptButtonTitle, confirmDisabled = false,  }) => {
    const rootClasses = [cl.myModal]
    if (visible) {
        rootClasses.push(cl.active)
    }
    const cancel = () => {
        setVisible(false);
        if (refInput != null) {
            refInput.current.value = "";
        }
    }
    const save = () => {
        addNewItem();
        if (refInput != null) {
            refInput.current.value = "";
        }
        setVisible(false);
    }

    return (
        <div className={rootClasses.join(' ')} onClick={() => setVisible(false)}>
            <div className={cn(cl.myModalWrap, className)}>
                <div className={[cl.myModalContent, 'default-style' ].join(' ')}  onClick={e => e.stopPropagation()}>
                    <div className={cl.topWrap}>
                        <p>{title}</p>
                        <ClosePopupBtn onClick={cancel} icon={<FontAwesomeIcon icon={solid('xmark')} className='icon' />} />
                    </div>
                    {children}
                </div>
                {confirmButtons ?
                    <div className={[cl.confirmWrap, 'default-style'].join(' ')} onClick={e => e.stopPropagation()}>
                        <AcceptButton title={AcceptButtonTitle ?? 'Сохранить'}  disabled = {confirmDisabled} onClick={save} />
                        <CancelButton title={'Отменить'} onClick={cancel} />
                    </div>
                    : null}
            </div>
        </div>
    );
};

MyModal.defaultProps = {
    confirmButtons: true
}

export default MyModal;