import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MainWrap } from 'components/UI/Layouts';
import cl from './CourseShow.module.css'
import ListVertical from 'components/UI/Layouts/ListVertical';
import _ from 'lodash';
import { api } from "api"
import CourseProgress from './CourseProgress/CourseProgress';
import MyLearnCourseLesson from './MyLearnCourseLesson/MyLearnCourseLesson';
import { DefaultWrapper } from 'components/UI/Wrappers';
import CourseContent from './CourseContent/CourseContent';
import CourseContentDetailed from './CourseContentDetailed/CourseContentDetailed';
import { TextAndBackHead } from 'components/UI/Other';
import { Layout1200 } from '../share/EducationLayout/styled/EducationLayoutStyled';

const CourseShow = () => {
    const [dataCourse, setData] = useState(null)
    const [Directions, setDirections] = useState()
    const [ModulesAndLessons, setModulesAndLessons] = useState({})
    const [LessonsAmount, setLessonsAmount] = useState(0)
    var newlessonOrder;
    const { id } = useParams()

    useEffect(() => {
        api.get(`/Module/Courses/${id}`)
            .then((result) => {
                setData(result.data.courseAndPhoto)
                setModulesAndLessons(_.sortBy(result.data.results, 'order'))
                var Lessons = 0
                result.data.results.map(module => Lessons += Number(module.lesson.length))
                setLessonsAmount(Lessons)
            })
        api.get(`/Course/Subjects`)
            .then((result) => setDirections({ ...Directions, learning_subjects: result.data }))
    }, [])

    if (dataCourse !== null && ModulesAndLessons !== null) {
        if (ModulesAndLessons[ModulesAndLessons.length - 1] === undefined) {
            newlessonOrder = 1;
        } else {
            newlessonOrder = Number(ModulesAndLessons[ModulesAndLessons.length - 1]['order']) + 1;
        }
        return (
            <>
                <MainWrap headerTitle={'Просмотр курса'} >
                    <Layout1200>
                        <TextAndBackHead />
                    </Layout1200>
                    <ListVertical className={cl.CoursShowWrap} >
                        <CourseProgress
                            CourseData={dataCourse}
                            directories_data={Directions}
                            lessonsCount={LessonsAmount}
                            Directions={Directions?.learning_subjects}
                        />
                        {/* <CourseContent
                            ModulesAndLessons={ModulesAndLessons}
                        /> */}
                        {ModulesAndLessons.map((module, index) => (
                            <DefaultWrapper className={cl.coursesModules}>
                                <CourseContentDetailed
                                    module={module}
                                    index={index}
                                    key={index}
                                    cid={id} />
                            </DefaultWrapper>
                        ))}
                    </ListVertical>

                </MainWrap >
            </>
        )
    }
}
export default CourseShow