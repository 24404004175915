import ContentHorizontal from "components/UI/Layouts/ContentHorizontal";
import styled from "styled-components";



export const SurveyLobbyParamsWrap = styled.div`
display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 800px;
    margin: 0 auto;
    gap: 40px 30px;
`

export const SurveyLobbyTestparam = styled.p`
display: flex;
gap: 20px;
`

export const SurveyLobbyLabel = styled.p`
line-height: 100%;
color : var(--base-blue-color);
font-size: 22px;
font-weight: 600;

`

export const SurveyLobbyValue = styled(SurveyLobbyLabel)`
color : var(--base-green-color);
`
export const ContentHorizontalCenter = styled(ContentHorizontal)`
justify-content: center;
`



