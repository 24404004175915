import React from 'react';
import Works from './Сomponents/Works';
import Educations from './Сomponents/Eduaction';
import PersData from './Сomponents/PersonalData';

function Documents() {

    return (
        <div className='accountSection'>
            <div>
                Страница документов
            </div>

            <div>
                <h3>Личные документы</h3>
                <PersData userId={Number(localStorage.getItem('userId'))} />
            </div>
            <div>
                <h3>Обучение</h3>
                <Educations userId={Number(localStorage.getItem('userId'))} />
            </div>
            <div>
                <h3>Работы</h3>
                <Works userId={Number(localStorage.getItem('userId'))} />
            </div>
        </div>
    );
}

export default Documents;
