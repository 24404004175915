import React, { useEffect, useState } from 'react';
import { GetPagesArray } from './getPagesArray';
import SimpleWhiteButton from '../Buttons/SimpleWhiteButton/SimpleWhiteButton';
import allCoursesState from 'store/allCoursesState';
import { MySelect } from '../Selects';
const PaginationList = ({ totalPages, page, changePageEvent }) => {

    let PagesArray = GetPagesArray(totalPages) ?? []
    return (
        <div>
            {PagesArray.map(p => (
                <SimpleWhiteButton key = {p} title={p} onClick={() => changePageEvent(p)}></SimpleWhiteButton>
            ))}            
        </div>
    );
};
export default PaginationList;