import React, { useState } from 'react';
import cl from './AddQestionForm.module.css'
import ListVertical from 'components/UI/Layouts/ListVertical';
import { GreenButton, RedButton, SimpleWhiteButton } from 'components/UI/Buttons';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { useForm } from 'react-hook-form';
import { InputValidateRadio, InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import AnswersCB from './components/AnswersCB';
import { api } from 'api';
import SimpleImg from 'components/UI/Images/SimpleImg/SimpleImg';
import { EmptyFile } from 'components/UI/Fillers';
import { BlueButton2, DarkGreenButton } from 'routes/UserComponents/MyLearn/courseShow/MyLearnCourseLesson/styled/MyLearnCourseLessonStyled';
import { AddQuestionSidebar, SelectQParamBtn } from './styled/AddQuestionStyled';
import { QuestionTypeCard } from './components/QuestionTypeCard';
import { RedQuestionBtn } from '../../styled/QuestionTextStyled';
import { ContentHorizontalCenter } from 'routes/UserComponents/MyLearn/courseShow/SurveyLobby/SurveyLobbyStyled';
const AddQuestionForm = ({ addAction, cancel }) => {
    const [NewQestionAnswers, setNewQestionAnswers] = useState(null)
    const [QuestionPhoto, setQuestionPhoto] = useState(null)
    const defautltTest = {
        imgQuest: null,
        type: null,
        manyAnswers: false,
        imgAnswers: false,
    }

    const { register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors, isTouched, isDirty, isValid }
    } = useForm(
        {
            mode: 'onChange',
            defaultValues: { ...defautltTest }
        },
    );

    //функция сохранения вопроса 
    const saveQuestion = (questionData) => {
        var { writeOrChose, manyAnswers, imgAnswers, ...SendingData } = questionData
        // if (JSON.parse(writeOrChose) == true) {
        //     SendingData.type = '1'
        // } else {
        //     if (JSON.parse(manyAnswers) == true) {
        //         SendingData.type = '2'
        //         SendingData.manyAnswers = true
        //         SendingData.rightAnswer = []
        //         NewQestionAnswers !== null && NewQestionAnswers.map((element, index) => { element.true == true && SendingData.rightAnswer.push(index) })
        //     } else {
        //         SendingData.type = '3'
        //         SendingData.manyAnswers = false
        //         SendingData.rightAnswer = null
        //         NewQestionAnswers !== null && NewQestionAnswers.map((element, index) => {
        //             if (element.true == true) { SendingData.rightAnswer = index }
        //         })
        //     }
        // }




        if (questionData.type === 1) {
            SendingData.type = '1'
        } else
            if (questionData.type === 2) {
                SendingData.type = '2'
                SendingData.manyAnswers = true
                SendingData.rightAnswer = []
            } else
                if (questionData.type === 3) {
                    SendingData.type = '3'
                    SendingData.manyAnswers = false
                    SendingData.rightAnswer = null
                    NewQestionAnswers !== null && NewQestionAnswers.map((element, index) => {
                        if (element.true == true) { SendingData.rightAnswer = index }
                    })
                }
        JSON.parse(imgAnswers) == true
            ? SendingData.imgAnswers = true
            : SendingData.imgAnswers = false
        SendingData.answers = NewQestionAnswers
        SendingData.textQuest = ''

        addAction(SendingData)
    }

    const saveCancel = () => {
        reset({ ...defautltTest })
        cancel()
    }

    //Функция загрузки файлов
    const uploadQuestionPhoto = async (event) => {
        var file = event.target.files;
        setQuestionPhoto(URL.createObjectURL(file[0]))
        const formData = new FormData();
        formData.append('file', file[0]);
        const resUpload = await api.post('/uploadfile', formData)
        setValue("imgQuest", await resUpload.data.id)
    }

    //Сброс фотки
    const resetPhoto = () => {
        setQuestionPhoto(null)
    }

    return (
        <form>
            <AddQuestionSidebar>
                <SelectQParamBtn active={watch('type') == 1} type='button' onClick={() => { setValue('type', 1); setValue('imgAnswers', null) }}>Текст</SelectQParamBtn>
                <SelectQParamBtn active={watch('type') == 3} type='button' onClick={() => { setValue('type', 3); setValue('imgAnswers', null) }}>Один ответ</SelectQParamBtn>
                <SelectQParamBtn active={watch('type') == 2} type='button' onClick={() => { setValue('type', 2); setValue('imgAnswers', null) }}>Много ответов</SelectQParamBtn>
            </AddQuestionSidebar>
            <ListVertical>
                {
                    watch('type') == 1
                        ?
                        <>
                            <h2>Текстовые вопросы</h2>
                            {/* {/* <p onClick={() => setValue('imgAnswers', true)}>С картинками</p> */}
                            <QuestionTypeCard
                                title='Текстовый вопрос'
                                text='Вопрос с ответом в виде текста'
                                srcImg={window.location.origin + '/images/QuestionsPreviews/TextAnserPrev.png'}
                                active={watch('imgAnswers') == false}
                                onClick={() => setValue('imgAnswers', false)} />
                        </>
                        :
                        watch('type') == 3
                            ?
                            <>
                                <h2> Один ответ</h2>
                                <ContentHorizontalCenter>
                                    <QuestionTypeCard
                                        title='Ответы с картинками'
                                        text={'Один ответ, ответы с картинками'}
                                        srcImg={window.location.origin + '/images/QuestionsPreviews/ManyRpund.png'}
                                        active={watch('imgAnswers') === true}
                                        onClick={() => setValue('imgAnswers', true)} />
                                    <QuestionTypeCard
                                        title='Ответы без картинок'
                                        text={'Один ответ, ответы без картинок'}
                                        active={watch('imgAnswers') === false}
                                        srcImg={window.location.origin + '/images/QuestionsPreviews/QuestionWithEllipse.png'}                                      
                                        onClick={() => setValue('imgAnswers', false)} />
                                </ContentHorizontalCenter>
                            </>
                            :
                            watch('type') == 2
                                ?
                                <>
                                    <h2> Много ответов</h2>
                                    <ContentHorizontalCenter>
                                        <QuestionTypeCard
                                            title='Ответы с картинками'
                                            text={'Несколько правильных ответов, ответы с картинками'}
                                            srcImg={window.location.origin + '/images/QuestionsPreviews/ManyBox.png'}
                                            active={watch('imgAnswers') === true}
                                            onClick={() => setValue('imgAnswers', true)} />
                                        <QuestionTypeCard
                                            title='Ответы без картинок'
                                            text={'Несколько правильных ответов, ответы без картинок'}
                                            srcImg={window.location.origin + '/images/QuestionsPreviews/QuestionWithBox.png'}
                                            active={watch('imgAnswers') === false}
                                            onClick={() => setValue('imgAnswers', false)} />
                                    </ContentHorizontalCenter>
                                </>
                                :
                                <p>Выберите тип создаваемого вопроса</p>
                }
                {watch('type') !== null
                    ?
                    <ContentHorizontal style={{ width: ' 100%' }}>
                        <RedQuestionBtn style={{ width: ' 100%', padding: ' 16px 0' }} type={'button'} onClick={e => { saveCancel() }} > Отмена</RedQuestionBtn>
                        <DarkGreenButton style={{ width: ' 100%', padding: ' 16px 0', }} onClick={handleSubmit(saveQuestion)} type={'submit'}>Создать</DarkGreenButton>
                    </ContentHorizontal>
                    : null
                }
            </ListVertical>

        </form>
    );
};

export default AddQuestionForm;


// <ListVertical>
// <ListVertical className={cl.testContent}>
//     <div className={cl.questionParametr} >
//         <p>Текст вопроса</p>
//         <InputValidateUseForm
//             type={'text'}
//             error={errors?.textQuest}
//             validateSet={register("textQuest", {
//                 required: 'Пожалуйста, придумайте вопрос',
//                 minLength: {
//                     value: 3,
//                     message: 'Минимум 3 буквы в название'
//                 },
//             })}
//         />
//     </div>
//     <div className={cl.questionParametr}>
//         <p>Картинка вопроса</p>
//         <ContentHorizontal className={cl.centred}>
//             {
//                 QuestionPhoto &&
//                 <SimpleImg className={cl.QuestionPhoto}
//                     src={QuestionPhoto ?? EmptyFile}
//                 />
//             }
//             <button type={'button'} onClick={e => resetPhoto()} >
//                 Удалить
//             </button>
//             <input
//                 className={cl.InputImg}
//                 id={'addPhotoInput'} type={'file'}
//                 onChange={(e) => uploadQuestionPhoto(e)}
//                 accept='image/jpeg,image/png'
//             />
//         </ContentHorizontal>
//     </div>

//     <div className={cl.questionParametr} >
//         <p>Пожалуйста выберите тип ответа</p>
//         <InputValidateRadio
//             RadioClass={cl.RadioField}
//             InputClass={cl.Radios}
//             RadioList={
//                 [
//                     { id: 'write', value: true, name: 'writeOrChose', title: 'Вписываемый' },
//                     { id: 'chose', value: false, name: 'writeOrChose', title: 'Выбираемый' }
//                 ]
//             }
//             error={errors?.showAny}
//             validateSet={{ ...register("writeOrChose", { required: "Пожалуйста выберите" }) }}
//         />
//     </div>
//     {
//         (watch('writeOrChose') && (JSON.parse(watch('writeOrChose')) !== false))
//             ?
//             null
//             :
//             <>

//                 {
//                     (watch('writeOrChose') !== "true") &&
//                     <>
//                         <div className={cl.questionParametr} >
//                             <p>Ответы с картинками</p>
//                             <InputValidateRadio
//                                 RadioClass={cl.RadioField}
//                                 InputClass={cl.Radios}
//                                 RadioList={
//                                     [
//                                         { id: 'withImg', value: true, name: 'imgAnswers', title: 'Да' },
//                                         { id: 'withoutImg', value: false, name: 'imgAnswers', title: 'Нет' }
//                                     ]
//                                 }
//                                 error={errors?.showAny}
//                                 validateSet={{ ...register("imgAnswers", { required: "Пожалуйста выберите" }) }}
//                             />
//                         </div>
//                         <div className={cl.questionParametr}>

//                             <p>Несколько правильных ответов</p>
//                             <InputValidateRadio
//                                 RadioClass={cl.RadioField}
//                                 InputClass={cl.Radios}
//                                 RadioList={
//                                     [
//                                         { id: 'manyAnswer', value: true, name: 'manyAnswers', title: 'да' },
//                                         { id: 'SingleAnswer', value: false, name: 'manyAnswers', title: 'нет' }
//                                     ]
//                                 }
//                                 error={errors?.showAny}
//                                 validateSet={{ ...register("manyAnswers", { required: "Пожалуйста выберите" }) }}
//                             />
//                         </div>
//                     </>
//                 }
//             </>
//     }

// </ListVertical>
// <ContentHorizontal>
//     <GreenButton onClick={handleSubmit(saveQuestion)} type={'submit'}>Создать</GreenButton>
//     <RedButton onClick={e => {saveCancel()}} type={'button'}>Отмена</RedButton>
// </ContentHorizontal>
// </ListVertical>

// ------------------------------------------------------------------------