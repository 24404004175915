import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { api } from 'api';
import { useScroll } from 'framer-motion';
import { MainWrap } from 'components/UI/Layouts';
import { DefaultWrapper } from 'components/UI/Wrappers';

const SetNewEmail = observer(() => {
    const [error, setError] = useState()
    const location = useLocation()
    useEffect(() => {
        SetNewMail()
    }, [])

    const SetNewMail = () => {
        api.post(`/user/confirmation/${location.pathname.split('/').pop()}`, {
        })
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    setError({ message: 'Неверный пароль' });
                }
                if (error.response.status === 403) {
                    setError({ message: 'Недействительная ссылка' });
                }
            })
    }
    return (
        <MainWrap width={'100%'}>
            <DefaultWrapper>
                {error?.message ? error?.message : 'На новую почту отправлено письмо для её подтверждения'}
            </DefaultWrapper>
        </MainWrap>
    );
});

export default SetNewEmail;