import React from 'react';
import cl from './BlueButton.module.css'
import cn from 'classnames';

const BlueButton = ({ text, className, onClick, children, ...props }) => {
    return (
        <button onClick = {onClick}  {...props} className={cn(cl.BlueButton, className)}>
            {text || children}
        </button>
    );
};

export default BlueButton;