import React, { useState, useEffect } from 'react';
import cl from './CoursInformation.module.css'
import cn from 'classnames';
import { MyTextarea } from 'components/UI/Inputs';
import _ from 'lodash'
import { MainSelect } from 'components/UI/Selects';
import { Loader } from 'components/UI/Loaders';
import { useParams } from 'react-router-dom';
import { api } from 'api'
import CourseImgChanger from 'routes/TeacherComponents/TeacherCabinete/Components/CoursItem/CourseImgChanger/CourseImgChanger';
import { AcceptButton, BlueButton, GreenButton, RedButton, YellowButton } from 'components/UI/Buttons';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { Controller, useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import InputArea from 'components/UI/Inputs/InputArea';
import { MainPopup } from 'components/UI/Popups';
import CourseContent from 'routes/UserComponents/MyLearn/courseShow/CourseContent/CourseContent';
import ModerationHeader from './Components/ModerationHeader/ModerationHeader';
import DescriptionElement from 'components/UI/Cards/CardCourseTeacher/components/Description/Description';
import CourseContentDetailed from 'routes/UserComponents/MyLearn/courseShow/CourseContentDetailed/CourseContentDetailed';
import { CustomLink } from 'components/UI/Links';
import { ProfileBackButton } from 'routes/UserComponents/ProfilePage/pages/styled/ProfileStyles';
import { ModuleCardDetailed } from 'routes/UserComponents/MyLearn/courseShow/CourseContentDetailed/styled/CourseContentDetailedStyled';
import { Layout1200 } from 'routes/UserComponents/MyLearn/share/EducationLayout/styled/EducationLayoutStyled';
import { TextAndBackHead } from 'components/UI/Other';

function CoursInformation() {
    const [CourseData, setCourseData] = useState({})
    const [DefaultCourseData, setDefaultCourseData] = useState({})
    const [Subjects, setSubjects] = useState([])
    const { cid } = useParams()
    const [EditInfo, setEditInfo] = useState(false)
    const [PublicationError, setPublicationError] = useState(false)
    const [modulesAndLessons, setModulesAndLessons] = useState()

    const {
        register,
        reset,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
    });

    //функция получения
    useEffect(() => {
        const SetFirstData = async () => {
            const subjects = await api.get("/Course/Subjects")
            setSubjects(subjects.data)
            const course = await api.get("/Module/Course/" + cid)
            setDefaultCourseData(course.data.cource)
            setModulesAndLessons(_.sortBy(course.data.results, 'order'))
            reset(course.data.cource);
        }
        SetFirstData()
    }, [])

    //функция обновления
    const saveCoursChange = (Date) => {
        var { fio, fileData, mimeType, ...fields } = Date;
        api.put("/Course/" + cid, { ...fields, previewImg: CourseData.previewImg, PreviewImg: CourseData.PreviewImg, isPublic: false })
            .then(result => {
                setDefaultCourseData(Date)
                setEditInfo(false)
                setValue('isPublic', false)
            }
            )
    }

    const Publicate = () => {
        var { fio, fileData, mimeType, ...fields } = watch();
        api.put("/Course/" + cid, { ...fields, previewImg: CourseData.previewImg, PreviewImg: CourseData.PreviewImg, isPublic: !watch('isPublic') })
            .then(result => {
                setDefaultCourseData({ CourseData, isPublic: !watch('isPublic') })
                setEditInfo(false)
                setValue('isPublic', !watch('isPublic'))
            }
            )
            .catch(error =>
                error.response.status == 480 && setPublicationError(true)
            )
    }

    return (
        <Layout1200>
            <TextAndBackHead text={'Изменение курса'} />
            {watch() === null ? <Loader /> :
                <ListVertical>
                    <div className={cn(cl.createCourseInform)}>
                        <ModerationHeader Status={3} />

                        <ContentHorizontal style={{ padding: '10px 32px', height: '87px', alignItems: 'center', borderBottom: '1px solid #c0c0c0' }} className={cl.ControlPannel}>
                            {EditInfo
                                ?
                                <InputValidateUseForm
                                    type={'text'}
                                    className={cn(cl.EditInput, cl.input)}
                                    error={errors?.label}
                                    validateSet={register("label", {
                                        required: 'Поле должно быть заполнено',
                                    })}
                                />
                                :
                                <p className={(cl.input)} style={{ height: 'auto' }}>{watch('label')}</p>
                            }
                            <ContentHorizontal className={cl.Buttons}>
                                {
                                    EditInfo
                                        ?
                                        <>
                                            <BlueButton
                                                type='submit'
                                                text='Сохранить'
                                                disabled={JSON.stringify(DefaultCourseData) == JSON.stringify(CourseData)}
                                                onClick={handleSubmit(saveCoursChange)} />
                                            <RedButton
                                                type='button'
                                                text='Отменить'
                                                disabled={JSON.stringify(DefaultCourseData) == JSON.stringify(CourseData)}
                                                onClick={e => {
                                                    setEditInfo(false); reset(DefaultCourseData)
                                                }}
                                            />
                                        </>
                                        :
                                        <>
                                            {
                                                watch('isPublic')
                                                    ?
                                                    <YellowButton className={cl.changeBtn}
                                                        text='Снять'
                                                        onClick={() => Publicate()}
                                                    />
                                                    :
                                                    <GreenButton className={cl.changeBtn}
                                                        text='Опубликовать'
                                                        onClick={() => Publicate()}
                                                    />
                                            }
                                            <BlueButton className={cl.changeBtn}
                                                text='Изменить'
                                                onClick={e => {
                                                    setEditInfo(true);
                                                }}
                                            />
                                        </>
                                }
                            </ContentHorizontal>
                        </ContentHorizontal>
                        <ContentHorizontal style={{ padding: '14px 32px 32px', margin: '0' }} className={cl.MainBlock}>
                            <CourseImgChanger
                                EditAloow={EditInfo}
                                id={'img' + watch('id')}
                                DefaultCoursData={watch()}
                                updatePhotoAction={setCourseData}
                                RemovePhotoAction={e => {
                                    setCourseData({ ...watch(), fileData: null, mimeType: null, PreviewImg: null, previewImg: null })
                                }}
                            />
                            <ListVertical viewMode={'title'} className={cl.MainBlockText}>
                                <ListVertical className={cl.horisontal}>
                                    <ContentHorizontal className={cn(cl.horisontal)}>
                                        <p className={cl.Label}>Длительность курса</p>
                                        {EditInfo ?
                                            <InputValidateUseForm
                                                type={'number'}
                                                className={cn(cl.EditInput, cl.input)}
                                                error={errors?.duration}
                                                validateSet={register("duration", {
                                                    required: 'Поле должно быть заполнено',
                                                    min: {
                                                        value: 0,
                                                        message: 'Длительность должна быть положительной',
                                                    }
                                                })}
                                            />
                                            : <p>{watch('duration')} ч</p>
                                        }
                                    </ContentHorizontal >
                                    <ContentHorizontal className={cn(cl.horisontal, cl.end)}>
                                        <p className={cl.Label}>Стоимость</p>
                                        {EditInfo ?
                                            <InputValidateUseForm
                                                type={'number'}
                                                className={cn(cl.EditInput)}
                                                error={errors?.price}
                                                validateSet={register("price", {
                                                    required: 'Поле должно быть заполнено',
                                                    min: {
                                                        value: 0,
                                                        message: 'Цена должна быть от 0 и выше',
                                                    }
                                                })}
                                            />
                                            : <p>{watch('price')}</p>
                                        }
                                        руб
                                    </ContentHorizontal >
                                </ListVertical >



                               
                                <ContentHorizontal className={cn(cl.horisontal, cl.radio)}>
                                    <p className={cl.Label}>Предмет </p>
                                    {EditInfo
                                        ?
                                        <Controller
                                            name="courseSubjectID"
                                            control={control}
                                            rules={{ required: 'Пожалуйста, выберите параметр' }}
                                            render={({ field: { onChange, onBlur, value } }) => (
                                                <MainSelect
                                                    placeholder={Subjects?.filter(sub => sub.id == watch("courseSubjectID", false))[0]?.subject}
                                                    map={Subjects}
                                                    value={['id']}
                                                    label={['subject']}
                                                    onChange={e => setValue("courseSubjectID", e.value)}
                                                    error={errors?.CourseSubjectID}
                                                    defaultValue={Subjects?.filter(sub => sub.id == watch("courseSubjectID", false))[0]?.subject}

                                                />
                                            )}
                                        />
                                        :
                                        Subjects.map(element => (element.id == watch('courseSubjectID')) && element.subject)
                                    }
                                </ContentHorizontal>
                               
                                <ListVertical className={cn(cl.vertical, cl.description)}>
                                    <p className={cl.Label}>Описание</p>

                                    {EditInfo
                                        ?
                                        <InputArea
                                            type={'text'}
                                            id={'description'}
                                        >
                                            <MyTextarea
                                                minRows={4}
                                                validateSet={{ ...register("description", {}) }}
                                            />
                                        </InputArea>
                                        :
                                        <DescriptionElement
                                            descriptionText={watch('description')}
                                            hiddenHeight={35}
                                            stringCount={2}
                                        />
                                        // watch('description')
                                    }
                                </ListVertical>
                            </ListVertical>
                        </ContentHorizontal>
                                  
                        <MainPopup
                            isOpened={PublicationError}
                            onClose={e => setPublicationError(false)}
                            className={cl.InfoPopup}
                            title={'Невозможно опубликовать!!!'}
                            showHeader={true}
                        >
                            <p >Возможно в курсе нет уроков</p>
                            <div className={cl.DeleteBtnsWrap}>
                                <AcceptButton className={cl.AcceptBtn}
                                    title={'ОК'}
                                    onClick={e => setPublicationError(false)}
                                />
                            </div>
                        </MainPopup>
                    </div>

                    {
                        modulesAndLessons == null || modulesAndLessons.length == 0 ?
                            <ModuleCardDetailed style={{ alignItems: 'center' }}>
                                <p>
                                    В вашем курсе пока что нет уроков, чтобы опубликовать курс, наполните его модулями и уроками
                                </p>
                                <CustomLink to={`/teachercabinete/course/${cid}/edit`}>
                                    <ProfileBackButton>
                                        Перейти к наполнению
                                    </ProfileBackButton>
                                </CustomLink>

                            </ModuleCardDetailed>
                            :
                            modulesAndLessons?.map((module, index) => (
                                <CourseContentDetailed
                                    module={module}
                                    index={index}
                                    cid={cid}
                                    mode='edit'
                                />))}

                    {/* <ModulesAndLessons /> */}
                </ListVertical>}
        </Layout1200>

    )
}
export default CoursInformation;