import styled from 'styled-components'

export const SliderWrap = styled.div`
    overflow-x: hidden;
    width: 100%;
    height: ${props => props.height};
    position: relative;

    &>div {
    gap: 10px;
    display: flex;
    width: max-content;
    position: absolute;
    }
`