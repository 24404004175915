import React from 'react';
import cl from './errorField.module.css'
import cn from 'classnames'

const ErrorField = ({ error, confirm }) => {
    return (
        <>
            {error ?
                <div className={cn(cl.errorField, { [cl.confirm]: confirm === 0 })}> {error} </div>
                : null}
        </>
    );
};

export default ErrorField;