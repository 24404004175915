import React, { useEffect, useState } from 'react';
import cl from './CourseImgChanger.module.css'
import SimpleWhiteButton from 'components/UI/Buttons/SimpleWhiteButton/SimpleWhiteButton';
import { FaFileUpload } from "react-icons/fa"
import { sendFile } from 'components/API/FileService/SendFile';
import { api } from 'api';
import { EmptyFile } from 'components/UI/Fillers';
import { BlueButton, RedButton } from 'components/UI/Buttons';
import { HiTrash } from 'react-icons/hi';
import { TfiDownload } from 'react-icons/tfi';
import { host } from 'routes/globals';
import ListVertical from 'components/UI/Layouts/ListVertical';


const CourseImgChanger = ({ id, EditAloow, DefaultCoursData, setDefaultCoursData, updatePhotoAction, RemovePhotoAction }) => {
    const [DisplayImg, SetDisplayImg] = useState()
    useEffect(() => {
        DefaultCoursData?.previewImg &&
            fetch(`${host}/api/GetFile/${DefaultCoursData.previewImg}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
                .then(response => response.status == 200 && response.blob(response)
                )
                .then(blob => {
                    if (blob) {
                        SetDisplayImg(URL.createObjectURL(blob))
                    } else {
                        SetDisplayImg(EmptyFile)
                    }
                })


    }, [DefaultCoursData.fileData])
    const uploadPhoto = async (event) => {
        var file = event.target.files;
        var f = file[0];
        var prevUrl = URL.createObjectURL(f)
        SetDisplayImg(prevUrl)
        const formData = new FormData();
        formData.append('file', f);
        const resUpload = await api.post('/uploadfile', formData)
        updatePhotoAction({ ...DefaultCoursData, PreviewImg: await resUpload.data.id, previewImg: await resUpload.data.id })
    };

    const RemovePhoto = (e) => {
        RemovePhotoAction()
        updatePhotoAction({ ...DefaultCoursData, PreviewImg: null, previewImg: null })
        SetDisplayImg(EmptyFile)
    }

    return (
        <div className={cl.ChangePhotoInput}>
            <div className={cl.ImgPreview}>
                <img className={cl.CourseSmallImg} src={DisplayImg ?? EmptyFile} alt="" />
            </div>
            {EditAloow && <div className={cl.BtnsWrap}>
                {/* <div > */}
                <input
                    id={'addPhoto' + id} type={'file'}
                    onChange={(e) => uploadPhoto(e)}
                    accept='image/jpeg,image/png'
                />
                {/* <ListVertical style={{ gap: ' 10px' }}> */}
                    <BlueButton
                    style={{width: '50%' }}
                     className={cl.InputButton}>
                        <label className={cl.InputButtonLabel} htmlFor={'addPhoto' + id}>
                            <span>Загрузить</span>
                            {/* <TfiDownload /> */}
                        </label>
                    </BlueButton>
                    <RedButton
                        className={cl.manageBtn}
                        text={<>Удалить 
                        {/* <HiTrash /> */}
                        </>}
                        onClick={e => RemovePhoto(e)}
                    />
                {/* </ListVertical> */}
            </div>

                // </div>
            }
        </div>
    );
};

export default CourseImgChanger;