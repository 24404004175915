import { useEffect, useState } from "react";

export const useGetPlatform = () => {
    const [platform, setPlatform] = useState('')
    var userDeviceArray = [
        { device: 'Android', platform: /Android/ },
        { device: 'iPhone', platform: /iPhone/ },
        { device: 'iPad', platform: /iPad/ },
        { device: 'Symbian', platform: /Symbian/ },
        { device: 'Windows Phone', platform: /Windows Phone/ },
        { device: 'Tablet OS', platform: /Tablet OS/ },
        { device: 'Linux', platform: /Linux/ },
        { device: 'Windows', platform: /Windows NT/ },
        { device: 'Macintosh', platform: /Macintosh/ }
    ];

    useEffect(() => {
        setPlatform(navigator.userAgent)

        for (var i in userDeviceArray) {
            if (userDeviceArray[i].platform.test(platform)) {
                setPlatform(userDeviceArray[i].device) ;
            }
        }
    }, [])

    return platform
}
