import React, { useState, useEffect } from 'react';
import PersonalData from './PersonalData';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import userStore from 'store/userStore';
import { useForm } from 'react-hook-form';

const UserForm = observer(() => {
    const [fields, setFields] = useState([]);
    const [oldFields, setOldFields] = useState()

    useEffect(() => {
        setFields(userStore.data)
        setOldFields(userStore.data)
    }, [])   

    const fieldsList = [{ title: 'Никнейм', type: 'text', connect: 'user_nikname' },
    { title: 'Имя', type: 'text', connect: 'user_name', require: true},
    { title: 'Фамилия', type: 'text', connect: 'user_surname' },
    { title: 'Отчество', type: 'text', connect: 'user_patronymic' },
    { title: 'Пол', inputType: 'gender', connect: 'user_sex', set: (e) => setFields({ ...fields, user_sex: e }) },
    { title: 'День рождения', inputType: 'date', type: 'text', connect: 'user_birthday' },
    { title: 'Почта', disabled: true,  type: 'email', connect: 'mail' , type: 'email'},
    { title: 'Номер телефона', type: 'mask', connect: 'user_phone_number', inputType: 'mask', mask: '+7(999)-999-99-99' },
    { title: 'О себе', type: 'text', required: false, connect: 'user_description', inputType: 'textarea', minRows: 7 },
    { title: 'Время от москвы',inputType: 'number' , connect: 'user_gmt_time', min: -1, max: 9 },
    ]
    return (
        <div className='account_Data'>
            <PersonalData
                fieldsList={fieldsList}
                setFields={setFields}        
            />         
        </div>
    );
});

export default UserForm;