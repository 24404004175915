import { api } from 'api';
async function sendFile(File, urlPath, Folder) {

    const formData = new FormData();
    formData.append('file', File);
    formData.append('path_way', Folder);
    const res = await api.post(
        urlPath,
        formData,
    )
    await api.put(`updatefile`, { id: res.data.id, IsOpenToEveryone: true })
    await api.put(`user`, { user_photo_ID: res.data.id })
    return res.data.id
}
export { sendFile }