import { makeAutoObservable } from "mobx"

class EditorStore {
    Command = null
    SaveMarker = true


    constructor() {
        makeAutoObservable(this)
    }

    setCommand(result) {
        this.Command = result
    }
    setSaveMarker(result) {
        this.SaveMarker = result
    }
}

export default new EditorStore()