import React from 'react';
import cl from './SearchCourses.module.css'
import { ListAndFilters } from 'components/UI/Filters';
import CardCoursWrapper from './components/CardCoursWrapper';
import { MainWrap } from 'components/UI/Layouts';

const SearchCourses = ({ headerTitle }) => {

    return (
        <MainWrap headerTitle={headerTitle}>           
             <ListAndFilters
                urlFilterByParams='/Course/Filter'
                urlGetDirectories='/Course/SA'
                getmMinMaxFilters='/Course/Filter'
                gFiltersArray={[
                    { parametr: 'price', title: 'Цена', type: 'Interval' },
                    { parametr: 'subjectsId', title: 'Предмет', type: 'Checkbox' },
                    // { parametr: 'supportTyp', title: 'Группа', type: 'Checkbox' },
                ]}
                gPageElemsArray={
                    [
                        { id: 5 },
                        { id: 10 },
                        { id: 20 },
                    ]
                }
                viewMode = {{                 
                    topMenu: false
                }}
            >
                <CardCoursWrapper />
            </ListAndFilters>
        </MainWrap>
    );
};
export default SearchCourses;