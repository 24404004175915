export const TRANSLATE_EDITOR_JS = {
  /**
   * @type {I18nDictionary}
   */
  messages: {
   
    ui: {
      "blockTunes": {
        "toggler": {
          "Click to tune": "Нажмите, чтобы настроить",
          "or drag to move": "или перетащите"
        },
      },
      "inlineToolbar": {
        "converter": {
          "Convert to": "Конвертировать в"
        }
      },
      "toolbar": {
        "toolbox": {
          "Add": "Добавить"
        }
      },   
      "popover": {
        "Filter": "Поиск",
        "Nothing found": "Ничего не найдено"
      }
    },
 
    toolNames: {
      "Text": "Параграф",
      "Heading": "Заголовок",
      "List": "Список",
      "Warning": "Важное",
      "Checklist": "Чеклист",
      "Quote": "Цитата",
      "Code": "Код",
      "Delimiter": "Разделитель",
      "Raw HTML": "HTML-фрагмент",
      "Table": "Таблица",
      "Link": "Ссылка",
      "Marker": "Маркер",
      "Bold": "Полужирный",
      "Italic": "Курсив",
      "InlineCode": "Код в строчку",
      "Attachment": "Файл",
      "Image": "Изображение",
    },   
    tools: {    
      "warning": {
        "Title": "Название",
        "Message": "Сообщение",
      },    
      "link": {
        "Add a link": "Вставьте ссылку"
      },      
      "stub": {
        'The block can not be displayed correctly.': 'Блок не может быть отображен'
      },
      "image": {
        "Caption": "Подпись",
        "Select an Image": "Выберите изображение",
        "With border": "Добавить рамку",
        "Stretch image": "Растянуть",
        "With background": "Добавить подложку",
      },
      "code": {
        "Enter a code": "Код",
      },
      "linkTool": {
        "Link": "Ссылка",
        "Couldn't fetch the link data": "Не удалось получить данные",
        "Couldn't get this link data, try the other one": "Не удалось получить данные по ссылке, попробуйте другую",
        "Wrong response format from the server": "Неполадки на сервере",
      },
      "header": {
        "Header": "Заголовок",     
        "Heading 1": "Заголовок 1",
        "Heading 2": "Заголовок 2",
        "Heading 3": "Заголовок 3",
        "Heading 4": "Заголовок 4",
        "Heading 5": "Заголовок 5",
        "Heading 6": "Заголовок 6",

      },
      "paragraph": {
        "Enter something": "Введите текст"
      },
      "list": {
        "Ordered": "Нумерованный",
        "Unordered": "Маркированный",
      }
      ,
      "attaches": {
        "File title": "Заголовок файла",        
      },      
      "quote": {
          "Quote's author": "Автор цитаты",
          "Enter a quote": "Введите цитату"
      },

      "table": {
        "Add row above":"Добавить строку после",
        "Add row below":"Добавить строку до",
        "Delete row":"Удалить строку",
        "Add column to right":"Добавить столбец справа",
        "Add column to left":"Добавить столбец слева",
        "Delete column":"Удалить столбец",
        "Without headings":"Без заголовков",
        "With headings":"С заголовками",
        "Heading": "Заголовок",
      },

      "Attachment": {
        "Select file to upload":"Выберите файл для загрузки"
      },
    },
 
    blockTunes: {     
      "delete": {
        "Delete": "Удалить",
        "Click to delete": "клик для подтверждения"
      },
      "moveUp": {
        "Move up": "Переместить вверх"
      },
      "moveDown": {
        "Move down": "Переместить вниз"
      }
    },
  }
}
