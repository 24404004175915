import styled from "styled-components";

export const ButtonCard = styled.button`
background-color: ${({ type }) =>
        type === 'primary' ? '#39AA95'
            : type === 'delete' ? '#FFC0C0'
                : null};
width: 100%;
padding: 6px;
border-radius: 9px;
color: ${({ type }) =>
        type === 'primary' ? '#ffffff'
            : type === 'delete' ? '#FF3F3F'
                : null
    };
    font-weight: 600;

&:hover {
background-color: ${({ type }) =>
        type === 'primary' ? '#46D1B7'
            : type === 'delete'
                ? '#FFA9A9'
                : null};
}
`