import React, { useState } from 'react';
import AcceptButton from '../../../../../components/UI/Buttons/AcceptButtons/AcceptButton';
import cl from './AddLessonForm.module.css'
import cn from 'classnames';
import { MyInput, MyTextarea } from 'components/UI/Inputs';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import InputArea from 'components/UI/Inputs/InputArea';
import { BlueButton, GreenButton } from 'components/UI/Buttons';

function AddLessonForm(props) {
    const [newLesson, setNewLesson] = useState([])

    const { register,
        handleSubmit,
        reset,
        watch,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({ mode: 'onTouched' });
    const watchDuration = watch("duration", false)
    const watchAllFields = watch();

    const AddLesson = (data) => {
        props.setLessons(data)
    }

    return (
        <form onSubmit={handleSubmit(AddLesson)} className={cn(cl.addLessonSection, props.mainStyle)}>
            <InputValidateUseForm
                title={'Название урока'}
                type={'text'}
                className={cl.addModuleInput}
                error={errors?.name}
                validateSet={register("name", {
                    required: true,
                    minLength: {
                        value: 2,
                        message: 'Минимум 5 символа'
                    },
                    pattern: {
                        value: /[А-Я][а-яё]/,
                        message: 'Введите Название с большой буквы',
                    }
                })}
            />
            <InputArea
                title={'Описание урока'}
                type={'text'}
                id={'description'}
            >
                <MyTextarea
                    minRows={3}
                    validateSet={{ ...register("description", {}) }}
                />
            </InputArea>
            <div className={cl.durationlesson}>
                <InputValidateUseForm
                    title={'Длительность'}
                    type={'number'}
                    className={cl.addLessonTime}
                    error={errors?.duration}
                >
                    <MyInput
                        type={'number'}
                        validateSet={register("duration", {
                            required: "Пожалуйста укажите длительность урока",
                        })} />
                    <p>мин. или</p>
                    <p>
                        {
                            watchDuration
                                ?
                                watchDuration < 60
                                    ?
                                    watchDuration + ' мин.'
                                    :
                                    Math.floor((Number(watchDuration) / 60)) + ' ч. ' + (Math.floor(Number(watchDuration) % 60)) + ' мин.'
                                : '0 ч. 0 мин.'
                        }
                    </p>
                </InputValidateUseForm>
            </div>
            <BlueButton
                type='submit'
                className={cl.addLessonBtn}
                text="Создать урок"
            />
        </form>
    )
}
export default AddLessonForm;