import React, { useState, useEffect, } from 'react';
import { AiOutlineRollback, AiOutlineSave, AiOutlineClear } from 'react-icons/ai';
import { RiSlideshow3Fill } from "react-icons/ri";
import { Link, useParams } from 'react-router-dom';
import { createReactEditorJS } from 'react-editor-js'
import { EDITOR_JS_TOOLS } from './tools'
import { TRANSLATE_EDITOR_JS } from './translations'
import defaultLessonText from './DdefaultLessonText';
import cl from './EditorJS.module.css'
import cn from 'classnames';
import './style_ce-block.css'
import './style.css';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import DragDrop from 'editorjs-drag-drop';
import Undo from 'editorjs-undo';
import { api } from 'api';
import { MainWrap } from 'components/UI/Layouts';
import { CustomLink } from 'components/UI/Links';
import { CourseDescription, EscapeLesson, Lesson, LessonArea, LessonHeader, LessonsSidebar, MoveBtn, NavBtns, StudyingWrap } from 'routes/UserComponents/MyLearn/LessonShow/LearnPrev/LearmPrev.styled';
import NavigateBtns from 'routes/UserComponents/MyLearn/LessonShow/NavigateBtns/NavigateBtns';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';

const Constructor = () => {
    const { course_id, lesson_id } = useParams()
    const navigate = useNavigate();
    const [FullLessonData, setFullLessonData] = useState({})
    const [SaveMarker, setSaveMarker] = useState(false)
    const [CourseInfo, setCourseInfo] = useState(null)   

    useEffect(() => {
        const getLessonContent = async () => {

            var response = await api.get("/Lesson/" + lesson_id)
            var CourseInformation = await api.get("/Module/Course/" + course_id)
            var IncommingData = null
            if (await response.data[0].content == '' || await response.data[0].content == '{}' || await response.data[0].content == null) {
                IncommingData = defaultLessonText
            } else {
                IncommingData = JSON.parse(response.data[0].content)
            }
            var result = await { ...response.data[0], content: IncommingData }
            setFullLessonData(await result)
            setCourseInfo(await CourseInformation.data.cource)
        }
        getLessonContent()
    }, [])

    const ReactEditorJS = createReactEditorJS()
    const editorCore = React.useRef(null)
    const handleInitialize = (instance) => {
        editorCore.current = instance
    }
    const handleReady = () => {
        const editor = editorCore.current._editorJS;
        new Undo({ editor })
        new DragDrop(editor);
    };

    const handleSave = async () => {
        const savedData = await editorCore.current.dangerouslyLowLevelInstance?.save();
        api.put(`/Lesson/sovok/${lesson_id}`, { ...FullLessonData, content: JSON.stringify(savedData) })
            .then((result) => {
                setFullLessonData({ ...FullLessonData, content: savedData })
                setSaveMarker(false)
            })
    }
    const handleClear = async () => {
        const clearData = await editorCore.current.dangerouslyLowLevelInstance?.clear();
    }
    const CompareHandler = async () => {
        const savedData = await editorCore.current.dangerouslyLowLevelInstance?.save();
        _.isEqual(savedData.blocks, FullLessonData.content.blocks)
            ?
            setSaveMarker(false)
            :
            setSaveMarker(true)
    }
    return (
        <MainWrap >
            <Lesson>
                <LessonsSidebar>
                    <CourseDescription>
                        <h2>{CourseInfo?.label}</h2>
                        <p> редактирование урока </p>
                    </CourseDescription>
                    <NavigateBtns
                        id_course={course_id}
                        wrap={<NavBtns />}
                        from={'constructor'}
                        buttons={{
                            save:
                                <MoveBtn
                                    type="button"
                                    title={'Сохранить'}
                                    onClick={() => handleSave()}
                                    disabled={!SaveMarker}
                                    className={SaveMarker
                                        ? cn(cl.LessonManageBtn, cl.green)
                                        : cn(cl.LessonManageBtn, cl.while)}
                                >
                                    <AiOutlineSave />
                                </MoveBtn>,
                            clear:
                                <MoveBtn
                                    className={cn(cl.LessonManageBtn, cl.clear)}
                                    type="button"
                                    title={'Очистить'}
                                    onClick={() => handleClear()}
                                >
                                    <AiOutlineClear />
                                </MoveBtn>,
                            preview:
                                <Link className={cl.LessonManageBtn}
                                    title={'Предпросмотр'}
                                    to={"/lesson/edit/preview/" + course_id + "/" + lesson_id}>
                                    <MoveBtn>
                                        <RiSlideshow3Fill />
                                    </MoveBtn>
                                </Link>
                        }}
                    />
                    <CustomLink state = {{ module: FullLessonData?.moduleId || null, lesson: lesson_id }} to={`/courses/edit/${course_id}/lessons`}>
                        <EscapeLesson>
                            К уроку
                        </EscapeLesson>
                    </CustomLink>
                </LessonsSidebar>
                {/* Страница с редактором */}
                <StudyingWrap >
                    <LessonHeader> {FullLessonData?.name} </LessonHeader>
                    <p> {FullLessonData.content == null && 'нулевой контент'}</p>
                    {
                        FullLessonData.content != null
                            ?
                            <LessonArea >
                                <ReactEditorJS holder="custom"
                                    onInitialize={handleInitialize}
                                    onReady={handleReady}
                                    tools={EDITOR_JS_TOOLS}
                                    i18n={TRANSLATE_EDITOR_JS}
                                    defaultValue={FullLessonData.content != null ? { ...FullLessonData.content } : { ...defaultLessonText }}
                                    onChange={(e) => CompareHandler()}
                                >
                                </ReactEditorJS>
                            </LessonArea>
                            : null
                    }
                </StudyingWrap >
            </Lesson>
        </MainWrap >
    )
}

export default Constructor

