import React, { useEffect, useState } from 'react';

const SuppWidget = (props) => {
    useEffect(() => {
        (function (w, d, u) {
            var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 60000 | 0);
            var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
        })(window, document, 'https://cdn-ru.bitrix24.ru/b1218725/crm/site_button/loader_5_kjlvoe.js')
    }, [])

    return (
        <>        
        </>
    );
};

export default SuppWidget;