import React from 'react';
import cn from 'classnames';
import cl from './MyRadioBtns.module.css'


const MyRadioBtns = ({ radioArray, label, labelClassName, InputclassName, defaultCheckedElement, selectEvent, InputWrapClassName, RadioBtnsAreaClassName, onClick, disabled }) => {

    const ChangeEvent = (value) => {
        selectEvent(value)
    }
    return (
        <div className={cn(cl.StageField, InputWrapClassName)}>
            {label &&
                
                <h2 className={cn(labelClassName)}>
                    {label}
                </h2>}
             
            <div className={cn(cl.RadioBtnsArea, RadioBtnsAreaClassName)}>
                {radioArray.map(radio => (
                    <div key={radio.id} className={cn(cl.flexInput, InputclassName, radio.ClassName)}>
                        <input
                            className={cl.radioInput}
                            name={radio.name}
                            defaultChecked={defaultCheckedElement == null ? null : defaultCheckedElement == radio.value}
                            id={radio.id}
                            type="radio"
                            value={radio.value}
                            title = {radio.alt}
                            onChange={() => ChangeEvent(radio.value)}
                            disabled = {disabled  ?? false}
                        />
                        <label htmlFor={radio.id}> {radio.title}</label>
                    </div>
                ))}

            </div>

        </div>
    );
};

export default MyRadioBtns;