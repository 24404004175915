import React from 'react';
import cl from './NavigateSection.module.css'
import cn from 'classnames'
import ListVertical from 'components/UI/Layouts/ListVertical';

const NavigateSection = ({ children, className }) => {

    return (
        <ListVertical className={cn(cl.navigateSection, className)}>
            {children}
        </ListVertical>
    );
};

export default NavigateSection;