import React from 'react';
import cl from './MainWrap.module.css'
import cn from 'classnames';

const MainWrap = ({ headerTitle, children, className }) => {
    return (
        <div className={cn(cl.mainWrap, className)}>
            {children}
        </div>
    );
};

export default MainWrap;