import styled from "styled-components";

export const CardWrap = styled.button`
    display: flex;
    cursor: ${({isPrimary}) => isPrimary && 'unset !important'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${({ active }) => active ? '8px' : '30px'};
    padding: ${({ active }) => active ? '10px' : '14px'};
    height: 100%;
    background-color: ${({ bank }) =>
        bank === 'альфабанк' ? '#FF3F3F'
            : bank === 'тинькофф' ? '#000000'
                : bank === 'сбербанк' ? '#21BA72'
                    : "#777"
    };
    width: ${props => props.isPrimary ? '209px' : props.width + 'px'};
    max-width: ${props => props.isPrimary ? '209px' : '156px'};
    min-width: ${props => props.isPrimary ? '209px' : '130px'};
    min-height: 86px;
    border-radius: 14px;
    /* box-shadow: 1px 1px 8px #00000050; */
    border: ${props => props.active ? '3px solid #0E5FFF' : null};
    
    & p {
    font-size: 14px;
}

    &> div {
    color: #fff;
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: space-between;
}

    &>div:nth-of-type(odd) {
    font-weight: 600;
}

`