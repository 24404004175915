import styled from "styled-components";
export const DescriptionWrap = styled.div`
    font-size: 17px;
    font-weight: 400;
    /* line-height: 100%; */
    line-height: 18px;
    word-break: break-all;
    width: 100%;
    display: -webkit-box;   
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px; 

    & > p  { 
        line-height: inherit ;
    }
`