import React from 'react';

const PublicateCours = ({ pubStatus, className, PublicateOnOver, overAction, mainAction, texts, }) => {
    return (
        <button
         
            onMouseOver={() => overAction(true)}
            onMouseOut={() => overAction(false)}
            className={className}
            onClick={() => mainAction()}
            width={'102px'}
        >
            {pubStatus === true
                ?
                PublicateOnOver == true
                    ? <p>{texts?.unpublicate}</p>
                    : <p>{texts?.isPublicate}</p>
                :
                PublicateOnOver == false
                    ? <p>{texts?.publicate}</p>
                    : <p>{texts?.publicate}</p>
            }
        </button>
    );
};
export default PublicateCours;