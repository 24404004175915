import styled from "styled-components";
export const QuestionTypeCardStyled = styled.div`
display: flex;  
    flex-direction: column;
    max-width: 360px;
    max-height: 281px;
    width: 100%;
    height: 100%;
    border-radius: 21px;
    padding: 18px;
    color: #000000;
    transition: 0.3s linear;
    gap: 12px;
    background-color: ${props => props.active ? '#E8F1FF' : 'transparent'};
    cursor:pointer;
    &:hover {
        background-color :#BDD7FF ;
       
    }
    &>div>label {
       font-size: 16px;
       font-weight: 500;
    }
    &>div>p {
        font-size: 11px;
       font-weight: 500;
    }
`