import React, { useState } from 'react';
import AcceptButton from '../../../../../components/UI/Buttons/AcceptButtons/AcceptButton';
import cl from './AddLessonTest.module.css'
import cn from 'classnames';
import { MyInput, MyTextarea } from 'components/UI/Inputs';
import { useForm } from 'react-hook-form';
import { InputValidateRadio, InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import InputArea from 'components/UI/Inputs/InputArea';
import { CancelButton, RedButton } from 'components/UI/Buttons';
import { api } from 'api';

const AddLessonTest = ({ LessonId, mainStyle, createTest, onClose, ...props }) => {

    const { register,
        handleSubmit,
        reset,
        setError,
        watch,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({ mode: 'onTouched', defaultValues: { trycount: 1 } });
    const watchDuration = watch("duration", false)
    const watchAllFields = watch();
    const [errorResult, setErrorResult] = useState({ status: false, message: '' })

    const CreateLesson = (data) => {
        const newData = Object.entries(data).reduce(
            (fields, [label, value]) => {
                if (value === 'true') {
                    return { ...fields, [label]: true }
                } else if (value === 'false') {
                    return { ...fields, [label]: false }
                } else if (Number(value)) {
                    return { ...fields, [label]: Number(value) }
                }
                return { ...fields, label: value }
            }, {}
        )
        api.post('Survey', { ...newData, LessonId: LessonId, pointSystem: !data.pointSystem })
            .then(result => createTest(result.data))
            .catch(({ response }) => {
                setErrorResult({ status: true, message: response.data })
            }
            )
    }

    return (
        <>
            {
                !errorResult.status ?
                    <form
                        onSubmit={handleSubmit(CreateLesson)}
                        className={cn(cl.addLessonSection, mainStyle)}>
                        <InputValidateUseForm
                            title={'Название теста'}
                            type={'text'}
                            className={cl.addModuleInput}
                            error={errors?.Label}
                            validateSet={register("Label", {
                                required: true,
                                minLength: {
                                    value: 2,
                                    message: 'Минимум 5 символа'
                                },
                                pattern: {
                                    value: /[А-Я][а-яё]/,
                                    message: 'Введите Название с большой буквы',
                                }
                            })}
                        />
                        <div className={cl.durationlesson}>
                            <InputValidateUseForm
                                title={'Длительность'}
                                type={'number'}
                                className={cl.addLessonTime}
                                error={errors?.duration}
                            >
                                <MyInput
                                    type={'number'}
                                    validateSet={register("duration", {
                                        required: "Пожалуйста укажите длительность теста",
                                    })} />
                                <p>
                                    {
                                        watchDuration
                                            ?
                                            watchDuration < 60
                                                ?
                                                'мин.'
                                                :
                                                <p>
                                                    {`мин. или `}
                                                    {Math.floor((Number(watchDuration) / 60)) + ' ч. ' + (Math.floor(Number(watchDuration) % 60)) + ' мин.'}
                                                </p>
                                            : 'мин.'
                                    }
                                </p>
                            </InputValidateUseForm>
                            <InputValidateUseForm
                                title={'Количество попыток'}
                                placeholder={'1'}
                                type={'number'}
                                className={cl.addModuleInput}
                                error={errors?.Label}
                                validateSet={{
                                    ...register("trycount", {
                                        required: true,
                                        min: {
                                            value: 1,
                                        }
                                    })
                                }}
                            />
                        </div>
                        <div className={cl.durationlesson}>
                            <InputValidateRadio
                                title='Порядок вопросов для ученика'
                                InputClass={cl.Radio}
                                RadioClass={cl.RadioField}
                                // defaultValue={settings?.random}
                                // className={cl.validateInput}
                                // checked={settings?.random}
                                RadioList={
                                    [
                                        { id: 'random1', value: true, name: 'random', title: 'Случайно' },
                                        { id: 'random2', value: false, name: 'random', title: 'Упорядоченно' }
                                    ]
                                }
                                error={errors?.random}
                                validateSet={{
                                    ...register("random", {
                                        defaultValues: true,
                                        required: "Не выбрано",
                                    })
                                }}
                            />
                            <InputValidateRadio
                                title='Переход к пройденым вопросам'
                                InputClass={cl.Radio}
                                RadioClass={cl.RadioField}
                                // defaultValue={settings?.random}
                                // className={cl.validateInput}
                                // checked={settings?.random}
                                RadioList={
                                    [
                                        { id: 'questJump1', value: true, name: 'questJump', title: 'Да' },
                                        { id: 'questJump2', value: false, name: 'questJump', title: 'Нет' }
                                    ]
                                }
                                error={errors?.questJump}
                                validateSet={{ ...register("questJump", { required: "Выберите тип группы" }) }}
                            />
                        </div>
                        <InputValidateRadio
                            title='Система оценивания'
                            InputClass={cl.Radio}
                            RadioClass={cl.RadioField}
                            // defaultValue={settings?.random}
                            // className={cl.validateInput}
                            // checked={settings?.random}
                            RadioList={
                                [
                                    { id: 'limit', value: true, name: 'pointSystem', title: 'Зачёт' },
                                    { id: 'point', value: false, name: 'pointSystem', title: 'Баллы' }
                                ]
                            }
                            error={errors?.pointSystem}
                            validateSet={{ ...register("pointSystem", { required: "Выберите тип группы" }) }}
                        />
                        <hr style={{ border: '1px solid #a1a1a1' }} />
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', gap: '20px' }}>
                            <RedButton
                                onClick={() => onClose()}
                                className={cl.addLessonBtn}
                                text="Отменить"
                            />
                            <AcceptButton
                                type='submit'
                                className={cl.addLessonBtn}
                                title="Создать тест"
                            >
                            </AcceptButton>
                        </div>
                    </form>
                    : <p>{errorResult.message}</p>
            }
        </>

    )
}
export { AddLessonTest };