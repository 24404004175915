import { AcceptButton, BlueButton, GreenButton, RedButton, YellowButton } from 'components/UI/Buttons';

import cn from 'classnames';
import _ from 'lodash';
import { MainPopup } from 'components/UI/Popups';
import React, { useEffect, useState } from 'react';
import AddLessonForm from 'routes/TeacherComponents/CourseEdit/Components/AddLessonForm/AddLessonForm';
import LessonDetail from 'routes/TeacherComponents/CourseEdit/Components/LessonRedactor/LessonDetail';
import { LessonString, } from '../styled/ModulesLessons.styled';
import { ContentHeader, ListVerticalStart } from 'routes/UserComponents/ProfilePage/pages/styled/ProfileStyles';
import { api } from 'api';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { DefaultWrapperFull, DefaultWrapperFullNP, LessonHeader, MoveBtn, NavBtns } from 'routes/UserComponents/MyLearn/LessonShow/LearnPrev/LearmPrev.styled';
import { MyInput } from 'components/UI/Inputs';
import { BlueWrap, BlueWrapSBCenter } from 'routes/PublicComponents/Pages/PageRegTeacher/StyledComponents/BlueWrap';
import { MdCancel, MdModeEditOutline } from 'react-icons/md';
import { HiTrash } from 'react-icons/hi2';
import { IoSaveSharp } from 'react-icons/io5';
import NavigateBtns from 'routes/UserComponents/MyLearn/LessonShow/NavigateBtns/NavigateBtns';
import { RiSlideshow3Fill } from 'react-icons/ri';
import { AiOutlineClear, AiOutlineSave } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';

const ModuleWindow = ({ ModuleData, UpdateModuleData, deleteLesson, rebaseLesson, deleteModule, CurrentLessonId, setLesson, CurrentModuleId, AddNewLesson, AllModules, course_id }) => {

    const [PopupCreateLesson, SetPopupCreateLesson] = useState(false)
    const [ModuleChange, setModuleChange] = useState(false)
    const [ModuleName, setModuleName] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        setModuleName(ModuleData?.name)
    }, [ModuleData])

    useEffect(() => {
        setModuleChange(false)
    }, [CurrentModuleId, CurrentLessonId])

    const filterLessons = (newModule, lessonId, oldModuleID, lessonData) => {
        api.put(`/Lesson/sovok/${lessonId}`, { ModuleId: newModule })
            .then(() => {
                newModule !== ModuleData.id &&
                    rebaseLesson(newModule, lessonId, oldModuleID, { ...lessonData, ModuleId: newModule })
            }
            )
    }
    const SaveModuleChange = () => {
        UpdateModuleData({ Name: ModuleName }) // отправка нового названия на сохранение]
        setModuleChange(false) //скрытие инпута
    }

    return (
        <>
            {CurrentLessonId === null && CurrentModuleId === null  //Если урока не выбрано
                ?
                <DefaultWrapperFull>
                    Пожалуйста, выберите модуль из списка слева или создайте новый
                </DefaultWrapperFull>
                :
                null
            }
            {CurrentLessonId == null && CurrentModuleId !== null //Если выбран модуль
                ?
                <>
                    <LessonHeader style={{ gap: '10px' }}>
                        {ModuleChange
                            ?
                            <ContentHorizontal style={{ alignItems: 'center', width: '100%' }}>
                                <p style={{ whiteSpace: 'nowrap' }}>
                                    Модуль  {ModuleData?.order}:
                                </p>
                                <MyInput value={ModuleName} onChange={e => setModuleName(e.target.value)}>
                                </MyInput>
                            </ContentHorizontal>
                            :
                            <p>
                                {`Модуль  ${ModuleData?.order}:  ${ModuleName}`}
                            </p>
                        }
                        <ContentHorizontal>
                            {ModuleChange
                                ?
                                <>
                                    <GreenButton style={{ padding: '1px 6px' }} onClick={e => SaveModuleChange()}>
                                        <IoSaveSharp /> Сохранить
                                    </GreenButton>
                                    <YellowButton
                                        onClick={e => setModuleChange(false)}
                                    >
                                        < MdCancel /> Отменить
                                    </YellowButton>
                                </>
                                :
                                <>
                                    <BlueButton onClick={e => setModuleChange(true)}><MdModeEditOutline />
                                        Изменить</BlueButton>
                                    {/* <RedButton onClick={e => deleteModule(CurrentModuleId)}><HiTrash />
                                        Удалить</RedButton> */}
                                </>
                            }
                        </ContentHorizontal>
                    </LessonHeader>
                    <DefaultWrapperFull>
                        <ListVerticalStart>
                            <ContentHeader>
                                <p>Содержание модуля</p>
                                <ContentHorizontal >{`Уроков: `}<sapn style={{ minWidth: '50px', textAlign: 'end' }}>{ModuleData?.lesson?.length}</sapn></ContentHorizontal>
                            </ContentHeader>
                            {_.sortBy(ModuleData?.lesson, 'order').map((lesson, index) =>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-end' }}>
                                    <BlueWrapSBCenter style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                        <LessonString key={lesson.id}
                                            onClick={e => setLesson(lesson.id)}>
                                            {`Урок ${ModuleData.order}.${index + 1} ${lesson.name}`}
                                        </LessonString>
                                        <AcceptButton onClick={e => {
                                            setLesson(lesson.id)
                                            navigate(`module/${CurrentModuleId}/lesson/${lesson.id}`)
                                        }}
                                            title={'перейти'} />
                                    </BlueWrapSBCenter>
                                    {
                                        lesson?.surveys?.length > 0 && lesson?.surveys[0] ?
                                            <div style={{
                                                background: 'rgb(246, 246, 246)',
                                                padding: '10px 20px',
                                                width: '90%',
                                                borderRadius: '14px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}>
                                                <p>{lesson?.surveys[0]?.label}</p>
                                                <p>Длительность: {lesson?.surveys[0]?.duration}</p>
                                                <AcceptButton onClick={e => {
                                                    navigate(`module/${CurrentModuleId}/lesson/${lesson?.id}/survey/${lesson?.surveys[0]?.id}`)
                                                }}
                                                    title={'изменить'} />
                                            </div>
                                            : null
                                    }
                                </div>
                            )}
                            <AcceptButton title='Создать урок'
                                onClick={e => SetPopupCreateLesson(true)} />
                        </ListVerticalStart>
                    </DefaultWrapperFull>
                    <MainPopup
                        isOpened={PopupCreateLesson}
                        onClose={e => SetPopupCreateLesson(false)}
                        title={'Создание Урока'}>
                        <AddLessonForm
                            cours_id={course_id}
                            newlessonOrder={1}
                            setLessons={Lesson => {
                                AddNewLesson(Lesson, ModuleData.id);
                                SetPopupCreateLesson(false)
                            }}
                        />
                    </MainPopup>
                </>
                :
                null
            }
            {/* Если выбран конкретный урок */}
            {
                CurrentLessonId !== null && CurrentModuleId !== null //Если выбран конкретный урок    
                    ?
                    <>
                        {/* <LessonHeader>
                            {`Модуль  ${ModuleData?.order}  ${ModuleName}`}
                        </LessonHeader> */}
                        <DefaultWrapperFullNP>
                            <LessonDetail
                                lesson={ModuleData?.lesson.find(elem => elem.id == CurrentLessonId)}
                                cours_id={course_id}
                                deleteLesson={deleteLesson}
                                lessonsState={ModuleData?.lesson}
                                index={ModuleData.order}
                                // OrderInLessonsList={ModuleData.lesson.order}
                                rebaseLesson={rebaseLesson}
                                AllModules={AllModules}
                                CurrentModulId={ModuleData.id}
                                filterLessons={filterLessons}
                            />
                        </DefaultWrapperFullNP>
                    </>
                    : null
            }
        </>
    );
};

export default ModuleWindow;