import ContentHorizontal from "components/UI/Layouts/ContentHorizontal";
import { DefaultWrapper } from "components/UI/Wrappers";
import styled from "styled-components";

export const PriceHighlighter = styled.div`

color: ${props => props.textcolor };
display: flex;
flex-direction: column;
gap: 5px;
justify-content: flex-end;
align-items: flex-end;

`
