import { useGetPlatform } from "./useGetPlatform";
import { useGetDeviceType } from "./useGetDeviceType";
import { useGetGeo } from "./useGetGeo";
import { useGetSystem } from "./useGetSystem";

const useDeviceInfomation = () => {

    const getDeviceType = useGetDeviceType()
    const getPlatform = useGetPlatform()
    const getSystem = useGetSystem()
    const getGeo = useGetGeo()

    return {
        type: getDeviceType,
        platform: getPlatform,
        system: getSystem,
        geo: getGeo
    }
}

export default useDeviceInfomation