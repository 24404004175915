import React from 'react';
import cl from './LoaderBlueFullView.module.css'
import Loader from '../LoaderBlueSmall';

const LoaderBlueFullView = (props) => {

    return (
        <div className={cl.loaderBlueFullView}>
            <Loader className={cl.full}/>
        </div>
    );
};

export default LoaderBlueFullView;