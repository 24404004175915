import { observer } from 'mobx-react-lite'
import userStore from 'store/userStore';
import { LoaderBlueFullView } from 'components/UI/Loaders';
import { Navigate, Outlet, redirect, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { api } from 'api';
import useDeviceInfomation from 'modules/PageLogin/hooks';
import { PasswordRecovery } from 'routes/UserComponents/ProfilePage/pages/PasswordRecovery';
import SetNewEmail from 'routes/UserComponents/ProfilePage/pages/EmailChange/SetNewEmail';
import PageConfirmation from 'modules/PageConfirmation';

const HocConfirmRoute = observer(() => {
    const device = useDeviceInfomation()
    const [renderResut, setRenderResult] = useState(null)
    const location = useLocation();
    const params = useParams()

    useEffect(() => {
        location.pathname.indexOf('/confirmation/RecoveryPassword') > -1
            ?
            setRenderResult('RecoveryPassword')
            :
            location.pathname.indexOf('/SuccessRecovery') > -1
                ?
                setRenderResult('SuccessRecovery')
                :
                location.pathname.indexOf('/EmailChange') > -1
                    ?
                    setRenderResult('EmailChange')
                    :
                    location.pathname.indexOf('/confirmation') > -1
                        ?
                        setRenderResult('Confirm')
                        :
                        ConfirmChange()
    }, [])

    const ConfirmChange = () => {
        api.post(`/user${location.pathname}`, {
            device_type_id: device.type,
            platform: '123',
            geo: device.geo == {} ? "" : JSON.stringify(device.geo),
            system: device.system
        })
            .catch(error => {
                setRenderResult('error');
                if (error.response.status === 403) {
                }
            })
            .then(result => {
                if (result.data.type) {
                    setRenderResult(result.data.type)
                }
                localStorage.setItem('token', result.data.token)
            })
    }
    return (
        <>
            {(() => {
                switch (renderResut) {
                    case 'RecoveryPassword':
                        return (<PasswordRecovery />);
                    case 'Confirm':
                        return (<PageConfirmation />);
                    case 'password':
                        return (<Navigate to={'/profile/password'} state={{ change: 'success', message: 'пароль успешно изменен' }} />);
                    case 'EmailChange':
                        return (<SetNewEmail />);
                    case 'mailChange':
                        return (<Navigate to={'/profile/mail'} state={{ change: 'success', message: 'Почта успешно изменена' }} />);
                    case 'error':
                        return <p>Ссылка более недействительна</p>;
                    case null:
                        return setRenderResult('Неизвестная ошибка');

                }
            })()
            }
        </>
    );
});

export default HocConfirmRoute;