import React from 'react';
import MyInput from '../../MyInput/MyInput';
import cl from './InputValidateUseForm.module.css'
import cn from 'classnames';
import { InputError, MyInputStyled, ValidateInputStyled, ValidationInputWrap } from './styled/BlueValidateInput.styled';

const InputValidateUseForm = ({ validateSet, type, error, title, disabled, className, children, after, isEdit, ...props }) => {
    return (
        <ValidateInputStyled>
            {title ? <label>{title}</label> : null}
            <ValidationInputWrap >
                {
                    children !== undefined ?
                        children : !isEdit?.isAuth ?
                            <MyInputStyled
                                $error={error != null}
                                type={type}
                                disabled={disabled}
                                validateSet={validateSet}
                                {...props}
                            />
                            :
                            <p
                                type={type}
                                disabled={disabled}
                                {...validateSet}
                                {...props}
                            >
                                {isEdit?.mail}
                            </p>
                }
                {after}
            </ValidationInputWrap>
            {error && <InputError className={cl.ValidateError}> {error.message || "Пожалуйста заполните поле"}</InputError>}
        </ValidateInputStyled>
    );
};

export default InputValidateUseForm;