import { host } from "routes/globals";
import axios from "axios";
import allCoursesState from "store/allCoursesState";

async function getFiltredPage(page,
   filter = allCoursesState.FetchFiltersList,
   count = allCoursesState.ElementsOnPage,
   order = allCoursesState.OrderParams.parametr ?? 'label' ,
   direction =allCoursesState.OrderParams.direction ?? 'asc',
   SearchValue =allCoursesState.SearchValue
   ) {  
         const response = await axios.post(host + "/courses/getfiltredpage", {
            filters: filter ,
            count: count ?? '10',
            page: page ?? '1',
            order: order ?? 'label',
            direction: direction ?? 'asc' ,          
            SearchValue: SearchValue
         })
         return response
 
   
}
export { getFiltredPage }