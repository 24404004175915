import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import userStore from 'store/userStore';
import { api } from 'api';
import { AcceptButton } from 'components/UI/Buttons';
import PersonalInput from './PersonalInput';

const PersonalData = observer(({ fieldsList, reg, saveDisable, ...props }) => {
    const [oldFields, setOldFields] = useState()
    useEffect(() => {
        const LocalData = { ...userStore.data, user_birthday: userStore?.data?.user_birthday?.split('T')[0] }
        setOldFields(LocalData)
        reset(LocalData)
    }, [])

    const { register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onChange' });

    const SaveUserData = (data) => {
        const { user_photo_ID, ...newData} = data
        const FixedData = { ...newData, user_birthday: data.user_birthday + 'T00:00:00.307869Z'}
        api.put('user', FixedData)
            .then(
                (result) => {
                    setOldFields(data)
                },
                (error) => {console.log(error.data)
                }
            )
    }
    return (
        <>
            {fieldsList.map((el, id) =>
            (
                <>
                    <PersonalInput
                        key={id}
                        inputType={el.inputType}
                        title={el.title}
                        type={el.type}
                        id={el.id}
                        disabled={el.disabled}
                        validateSet={
                            register(String(el.connect), {
                                required:  el.required !== false ? 'Пожалуйста, заполните поле ' + el.title : false,
                                min: el.min ? {
                                    value: el.min,
                                    message: 'Значение не может быть меньше ' + el.min
                                } : false,
                                max: el.max ? {
                                    value: el.max,
                                    message: 'Значение не может быть меньше ' + el.max
                                } : false,
                                pattern: el.connect == 'mail' ?
                                    {
                                        value: /^(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#%&*\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,15}$/,
                                        message: 'Неверный формат email',
                                    } :
                                    el.connect == 'user_phone_number'
                                        ? {
                                            value: /^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/,
                                            message: 'Пожалуйста, заполните номер',
                                        }
                                        : false,
                            })
                        }
                        value={el.inputType != 'date' ? watch(el.connect) == null ? '' : watch(el.connect) : watch(el.connect)?.split('T')[0]}
                        min={el.min}
                        max={el.max}
                        mask={el.mask}
                        minRows={el.minRows}
                        set={el.set}
                        name={el.connect}
                        error={errors?.[el.connect]}
                        checked={watch(el.connect)}
                    />
                </>

            )
            )}
            <AcceptButton
                title='Сохранить'
                type='submit'
                onClick={handleSubmit(SaveUserData)}
                disabled={_.isEqual(watch(), oldFields)}
            />
        </>

    );
});

export default PersonalData;