import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Output from 'editorjs-react-renderer';
import _ from 'lodash';
import cn from 'classnames';
import { ShowClasses } from './../ShowConfigs/DefaultConfig';
import renderers from './../CustomRerenders/CustomRerenders'
import EmptyLesson from './../EmptyLesson';
import { api } from 'api';
import { LoaderBlueFullView } from 'components/UI/Loaders';
import { MainWrap } from 'components/UI/Layouts';
import cl from './../LessonShow.module.css'
import NavigateBtns from '../NavigateBtns/NavigateBtns';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { StudyingWrap, CourseDescription, EscapeLesson, Lesson, LessonArea, LessonHeader, LessonsDescription, LessonsNavigate, LessonsSidebar, NavigateLesson, NavigateModule, MoveBtn, NavBtns, LessonsNavigateWrap, } from './LearmPrev.styled';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { CustomLink } from 'components/UI/Links';
import { AiOutlineClear, AiOutlineSave } from 'react-icons/ai';
import { RiSlideshow3Fill } from 'react-icons/ri';

const LearnPrev = () => {
    const { id_course, id_lesson } = useParams()
    const [lessonData, setLessonData] = useState(null)
    const [LessonContent, setLessonContent] = useState({})
    const [Loader, setLoader] = useState(false)
    const [NavigateArrary, setNavigateArrary] = useState({})
    const [courseInfo, setCourseInfo] = useState({})
    const [modulesAndLessons, setModulesAndLessons] = useState()
    const scrollNavigation = useRef(null);



    const SortSubArrays = (array, param) => {
        return ([...array.map(subarray =>
            subarray = { ...subarray, [param]: _.sortBy(subarray[param], 'order') })])
    }

    useEffect(() => {
        setLoader(true)
        // входящая сортировка подмассивов
        const getLessonContent = async () => {
            var lessonsList = (await api.get('/Module/Course/' + id_course))
            const OrderedLessonsList = SortSubArrays([...lessonsList.data.results], 'lesson')
            var response = await api.get("/Lesson/" + id_lesson)
            var result = { ...response.data[0], content: JSON.parse(response.data[0].content) }
            setLessonData(await result)
            setLessonContent(await result?.content || {})
            setNavigateArrary(await getNeighbours(OrderedLessonsList, id_lesson))
            setModulesAndLessons(OrderedLessonsList)
            setCourseInfo(lessonsList.data.cource)

            // ---------------------------------------------------
            const scrollInRef = (ref, number) => {
                ref.current.scroll({
                    top: [0, 1, 2, 3].indexOf(number) != -1 ? 0 :
                        (number - 5) * 40,
                    behavior: 'smooth'
                });
            };

            // Скроллинг при переходах
            var counter = 0
            lessonsList.data.results?.map(module => {
                counter += 1
                _.sortBy(module.lesson, 'order').map(lesson => {
                    if (lesson.id != id_lesson) {
                        counter += 1
                    } else {
                        scrollInRef(scrollNavigation, counter)
                    }
                })
            })
        }
        getLessonContent()
        setLoader(false)
    }, [id_lesson])

    const getNeighbours = async (list, current) => {
        var idList = []
        list.map(module => {
            var moduleLessons = []
            module.lesson.map(lesson => moduleLessons.push(lesson.id))
            idList = [...idList, ...moduleLessons]
        })
        const cuurentIndex = idList.indexOf(Number(current))
        return {
            prev: idList[cuurentIndex - 1] || 'back',
            currentIndex: cuurentIndex,
            next: idList[cuurentIndex + 1] || 'end'
        }   
    }
    return (
        <MainWrap>
            <Lesson>
                {
                    !Loader
                        ?
                        <>
                            <LessonsSidebar>
                                <CourseDescription>
                                    <h2>Курс:{courseInfo.label}</h2>
                                </CourseDescription>
                                <NavigateBtns
                                    id_course={id_course}
                                    NavigateArrary={NavigateArrary}
                                    wrap={<NavBtns />}
                                    from={'learn'}
                                    buttons={{
                                        back:
                                            <CustomLink>
                                                <MoveBtn><IoIosArrowRoundBack />Назад</MoveBtn>
                                            </CustomLink>,
                                        next:
                                            <CustomLink>
                                                <MoveBtn>Далее<IoIosArrowRoundForward /></MoveBtn>
                                            </CustomLink>
                                    }}
                                />
                                <LessonsNavigateWrap>
                                    <LessonsNavigate ref={scrollNavigation} >
                                        {modulesAndLessons?.map((module, Mindex) => (
                                            <>
                                                <NavigateModule
                                                    focus={false}
                                                    to={`/mylearn/courseshow/${id_course}/lessonshow/${(module.lesson[0]?.id)}`}>
                                                    <p title={(Mindex + 1) + '. ' + module.name}>
                                                        {(Mindex + 1) + '. ' + module.name}
                                                    </p>
                                                </NavigateModule>
                                                {module?.lesson?.map((lesson, Lindex) => (
                                                    <>
                                                        <NavigateLesson to={`/mylearn/courseshow/${id_course}/lessonshow/${lesson.id}`}
                                                            key={'lesson' + Mindex + lesson.id}
                                                            active={lesson.id == id_lesson}
                                                        >
                                                            <p title={`${Mindex + 1}.${Lindex + 1} ${lesson.name}`}>
                                                                {`${Mindex + 1}.${Lindex + 1} ${lesson.name}`}
                                                            </p>


                                                            <IoIosArrowRoundForward />
                                                        </NavigateLesson>
                                                        {lesson?.surveys?.length > 0
                                                            ?
                                                            <NavigateLesson
                                                                style={{ paddingLeft: '50px' }}
                                                                to = {`/mylearn/courseshow/${id_course}/lesson/${lesson.id}/surveyLobby/${lesson?.surveys[0]?.id}`}
                                                                // to={`/mylearn/courseshow/${id_course}/lessonshow/${lesson.id}`}
                                                                key={'lesson' + Mindex + lesson.id}
                                                            // active={lesson.id == id_lesson}
                                                            >
                                                                <p>Тест: {lesson?.surveys[0].label}</p>
                                                                {/* <p title={`${Mindex + 1}.${Lindex + 1} ${lesson.name}`}>
                                                                    {`${Mindex + 1}.${Lindex + 1} ${lesson.name}`}
                                                                </p> */}
                                                                <IoIosArrowRoundForward />
                                                            </NavigateLesson>
                                                            :
                                                            null
                                                        }

                                                    </>
                                                ))}
                                            </>
                                        )
                                        )}
                                    </LessonsNavigate>
                                </LessonsNavigateWrap>
                                <CustomLink to={`/mylearn/courseshow/${id_course}`}>
                                    <EscapeLesson>
                                        Выйти с обучения
                                    </EscapeLesson>
                                </CustomLink>
                            </LessonsSidebar>

                            <StudyingWrap >
                                <LessonHeader>  {lessonData?.name} </LessonHeader>
                                <LessonArea >
                                    <Output
                                        classNames={ShowClasses}
                                        data={(JSON.stringify(LessonContent) === '{}')
                                            ?
                                            EmptyLesson
                                            :
                                            lessonData?.content
                                        }
                                        renderers={renderers}
                                    />
                                </LessonArea>
                            </StudyingWrap>
                        </>
                        :
                        <LoaderBlueFullView />
                }
            </Lesson>
        </MainWrap>
    )
}
export default LearnPrev