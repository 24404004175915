import { useEffect, useState } from 'react';

export const useGetGeo = () => {
    const [geo, setGeo] = useState('')
    useEffect(() => {
        function success(geo) {
            setGeo(geo)
        }
        function error(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
        }
        navigator.geolocation.getCurrentPosition(success, error);
    }, [])

    return geo;
};