
import React from 'react';
import cl from './MySwitch.module.css'
import cn from 'classnames';

const MySwitch = ({ onClick, switchDependence, SwitchOnLabel, SwitchOffLabel, className, index }) => {
    return (
        <div className={cn(cl.MySwitch, className)}>
            <input checked={!switchDependence} onChange={() => onClick()} type="checkbox" role="switch" id={"switch_" + index} />
            <label htmlFor={"switch_" + index}>
                {switchDependence ? SwitchOnLabel : SwitchOffLabel}
            </label>
        </div>
    );
};
export default MySwitch;