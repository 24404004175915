import { observer } from 'mobx-react-lite'
import userStore from 'store/userStore';
import { LoaderBlueFullView } from 'components/UI/Loaders';
import { Navigate, Outlet } from 'react-router-dom';

const HocPrivateRoute = observer(({ condition = true }) => {

    if (userStore.authInProgress) {
        return (
            <div style={{ width: '100vw', height: '100vh' }}>
                <LoaderBlueFullView />
            </div>
        );
    }
    if (condition && userStore.auth) {
        return <Outlet />
    } else {
        return <Navigate to={'/about'} />;
    }
});

export default HocPrivateRoute;