import React, { useEffect, useState } from 'react';
import cl from './TeacherQuestionsList.module.css'
import { GreenButton, SimpleWhiteButton } from 'components/UI/Buttons';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { AddQuestionForm } from './QuestionsCards';
import { MainPopup } from 'components/UI/Popups';
import QuestionCard from './QuestionCard';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { json } from 'react-router-dom';
import { DarkGreenButton } from 'routes/UserComponents/MyLearn/courseShow/MyLearnCourseLesson/styled/MyLearnCourseLessonStyled';
import { FaPlus } from "react-icons/fa6";
import { ChangerWrap, SpacedApartWrap } from 'routes/UserComponents/ProfilePage/pages/styled/ProfileStyles';
import { TextAndBackHead } from 'components/UI/Other';

const TeacherQuestionsList = ({ questionsList, testLabel, addQuestion, deleteQuestion, updateQuestionsList }) => {
    const [LocalList, setLocalList] = useState()
    const [NewQuestionForm, setNewQuestionForm] = useState(false)

    //обновление списка после изменения
    useEffect(() => {
        setLocalList(questionsList)
    }, [questionsList])

    //удление вопроса по индексу
    const DeleteQuestion = (index) => {
        deleteQuestion(index)
    }

    //добавление нового вопроса
    const AddNewQuestion = (NewQdata) => {
        addQuestion(NewQdata, LocalList)
        setNewQuestionForm(false)
    }

    //добавление ответа или обновление данных существующего ответа с вопросом
    const AddNewAnswer = (QuestionId, AnswerData, QuestionData, type, index) => {
        var LocalCopy = JSON.parse(JSON.stringify([...LocalList]))
        if (type == 1) {
            LocalCopy[index].question = QuestionData
            LocalCopy[index].answerOptions[0] = AnswerData
        }
        if (type == 2) {
            LocalCopy[index].question = QuestionData
            LocalCopy[index].answerOptions = AnswerData
        }
        if (type == 3) {
            LocalCopy[index].question = QuestionData
            LocalCopy[index].answerOptions = AnswerData
        }
        setLocalList(LocalCopy)
    }

    return (
        <ListVertical className={cl.TeacherQuestions}>
            <TextAndBackHead text={''} />
            <SpacedApartWrap>
                <h2>Список вопросов</h2>

                <DarkGreenButton onClick={e => setNewQuestionForm(true)}>
                    <FaPlus /> {' ' + '  Добавить вопрос'}
                </DarkGreenButton>

            </SpacedApartWrap>
            {/* //Списоок карточек ыопросов */}
            {LocalList?.map((question, index) =>
                <QuestionCard
                    key={index}
                    question={question}
                    index={index}
                    deleteQuestion={DeleteQuestion}
                    AddNewAnswer={(id, Data, Question, type) => { AddNewAnswer(id, Data, Question, type, index) }}
                />
            )}

            {LocalList?.length >= 3 ?
       
                <DarkGreenButton onClick={e => setNewQuestionForm(true)}>
                      <FaPlus /> {' ' + '  Добавить вопрос'}
                </DarkGreenButton>
                : null
            }
            {/* Попап добавления вопроса*/}
            <MainPopup className={cl.AddTestForm}
                isOpened={NewQuestionForm}
                onClose={e => setNewQuestionForm(false)}
                title={'Форма добавления вопроса'}>
                <AddQuestionForm
                    addAction={AddNewQuestion}
                    cancel={e => setNewQuestionForm(false)}
                />
            </MainPopup>
        </ListVertical >
    );
};

export default TeacherQuestionsList;