const defaultLessonText =  
    {
        time: 1635603431943,
        blocks: [
            {
                id: "sheNwCUP5A",
                type: "header",
                data: {
                    text: "Приветсвуем вас, здесь вы можете создать свой урок",
                    level: 2
                }
            },
            {
                id: "12iM3lqzcm",
                type: "paragraph",
                data: {
                    text:
                        "нажав на + слева вы можете добавлять блоки и непосредственно работать с ними внутри статьи"
                }
            },         
            {
                id: "12iM3lqzcm",
                type: "paragraph",
                data: {
                    text:
                        "нажав на многоточие слева вы можете удалять , переносить и редактировать блоки"
                }
            },         
        ]
    }


    export default defaultLessonText