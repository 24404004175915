import React, { useState } from 'react';
import cl from './HeaderMain.module.css'
import { NavLink } from 'react-router-dom';
import cn from 'classnames'
import { observer } from 'mobx-react-lite';
import userStore from 'store/userStore';
import { CustomLink } from 'components/UI/Links';
import { SlArrowDown } from "react-icons/sl";
import { FaChalkboardTeacher, FaThList } from "react-icons/fa";
import { ButtonDropDown } from 'components/UI/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccountAvatar } from 'components/UI/Avatars';
import { ModalSmall } from 'components/UI/Modals';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IoChatbubbleEllipsesSharp } from 'react-icons/io5';
import { IoMdExit } from 'react-icons/io';

const HeaderMain = observer((props) => {

    const [visibleModal, setVisibleModal] = useState(false)

    return (
        <header className={cl.HeaderMain}>
            <div className={cl.HeaderWrap}>
                <div className={cl.logoSection}>
                    <NavLink className={cl.logo} to="/">
                        <img src={window.location.origin + '/images/logo.svg'} alt='logo' />
                    </NavLink>
                </div>
                {
                    userStore.auth ?
                        <>
                            <CustomLink to={'/courses/allcourses'} className={cl.navLink} active={cl.active}>Список курсов</CustomLink>
                            <CustomLink to={'/about'} className={cl.navLink} active={cl.active}>О нас</CustomLink>
                        </>
                        : null
                }
                {
                    userStore.data.role_id === 2 ?
                        <ButtonDropDown
                            className={cl.DropDownElement}
                            BtnClass={cl.DropDownBtn}
                            DropDownClass={cl.DropDownList}
                            title={'Кабинет автора'}
                            BtnText= {<><FaChalkboardTeacher /> <p>Кабинет автора</p> <SlArrowDown /></>}
                        >
                            <CustomLink className={cl.DropNavLink} to={'/teachercabinete/course/list'} ><FaThList />Управление курсами</CustomLink>
                        </ButtonDropDown>
                        : null
                }
                {
                    userStore.auth ?
                        <section style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                            <ButtonDropDown
                                className={cl.DropDownElement}
                                BtnClass={cl.DropDownBtn}
                                DropDownClass={cl.DropDownList}
                                title={'Моё обучение'}
                                BtnText={<><p>Моё обучение</p> <SlArrowDown /></>}
                            >
                                <CustomLink className={cl.DropNavLink} to={'/mylearn/mycourses'}><FaThList />Мои курсы </CustomLink>
                            </ButtonDropDown>
                            <div className={cl.accontMenu} onClick={() => setVisibleModal(!visibleModal)}>
                                <div className={cl.accountAvatar}>
                                    <AccountAvatar size={'46px'} />
                                    <FontAwesomeIcon icon={icon({ name: 'sort-down' })} />
                                </div>
                                <ModalSmall className={cn(cl.DropDownList, cl.Profile)}
                                    visible={visibleModal}
                                    setVisible={() => setVisibleModal(false)}
                                >
                                    <CustomLink className={cl.DropNavLink} to={'/profile/main'}><FaThList />Профиль </CustomLink>
                                    {
                                        userStore.data.role_id !== 2 &&
                                        <CustomLink
                                            className={cl.DropNavLink}
                                            to='/regteacher'

                                        >
                                            <FontAwesomeIcon icon={icon({ name: 'user' })} />
                                            Стать автором
                                        </CustomLink>
                                    }
                                    {/* <CustomLink className={cl.DropNavLink} to={'/personal/chat'}><IoChatbubbleEllipsesSharp />
                                        Чаты
                                    </CustomLink> */}
                                    <CustomLink onClick={() => userStore.logOut()} className={cl.DropNavLink} to={'/teachercabinete/requests'}>
                                        <IoMdExit />
                                        Выйти
                                    </CustomLink>
                                </ModalSmall>
                            </div>
                        </section>
                        :
                        <>
                            <CustomLink to={'/login'} state={{ showForm: 'auth' }} className={cl.navLink} active={cl.active}>Войти</CustomLink>
                            <CustomLink to={'/login'} state={{ showForm: 'reg' }} className={cl.navLink} active={cl.active}>Регистрация</CustomLink>
                        </>
                }
            </div>
        </header>
    );
});

export default HeaderMain;