import { AcceptButton, SimpleWhiteButton } from 'components/UI/Buttons';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import ListVertical from 'components/UI/Layouts/ListVertical';
import React, { useEffect, useState } from 'react';
import { ColRowWrap } from '../QuestionRender/styled/ColRowWrap.styled';
import { TestTimer } from '../TestTimer';
import { CourseDescription, DefaultWrapper2, DefaultWrapperFull, DefaultWrapperFullNP, DefaultWrapperNoPadding, EscapeLesson, LessonsNavigate, LessonsNavigateWrap, LessonsSidebar, StudyingWrap } from 'routes/UserComponents/MyLearn/LessonShow/LearnPrev/LearmPrev.styled';
import { DefaultWrapper, NavigateSection } from 'components/UI/Wrappers';
import { observer } from 'mobx-react-lite';
import MySurveysStore from 'store/MySurveysStore';

const QuestionStepper = observer(({ List, CurrentId, setCurrent, EndAction, children, testParams, LastAction, AnswersList, ...props }) => {
  const [LocalList , setLocallist ] = useState([])

  useEffect(()=> { 
    setLocallist(List)
  }, [List])
  const TestLength = LocalList?.length
  const FindIndex = (id) => { return (LocalList?.indexOf(LocalList.find(el => el.question.id == id))) }

  const PrevOrNext = (direction) => {
    const CurrentIndex = LocalList.indexOf(LocalList.find(q => q.question.id == CurrentId))
    if (direction == 'next') {
      setCurrent(LocalList[CurrentIndex + 1].question?.id)
    } else {
      setCurrent(LocalList[CurrentIndex - 1].question?.id)
    }
  }
  return (
    <>

      <LessonsSidebar top='0'>
        <DefaultWrapper2 padding={'20px'} gap={'10px'}>
          <TestTimer TestDuration={testParams?.duration} EndAction={EndAction} />
          <ColRowWrap gap={'10px'} repeat='repeat(5, 1fr)'>
            {//Перебор по списку вопросов
              LocalList?.map((Elem, index,) => (
                <SimpleWhiteButton
                  style={CurrentId == Elem.question.id ?
                    {//Если это вопрос сейчас открыт то он имеет !синеватый! цвет
                      backgroundColor: '#163C85',
                      color: '#FFF'
                    }
                    :
                    //Если  вопрос не открыт, но он в списке отвеченных
                    AnswersList?.find(el => el == Elem.question.id) != null
                      ?
                      {
                        backgroundColor: '#FFC977', // желтоватый
                        color: '#B17923'
                      }
                      : {
                        backgroundColor: '#E8F1FF', // желтоватый
                        color: '#163C85'
                      } //иначе без цвета
                  }
                  key={Elem?.question.id}
                  onClick={e => { setCurrent(Elem?.question.id) }}>
                  {index + 1}
                </SimpleWhiteButton>
              ))
            }
          </ColRowWrap >
        </DefaultWrapper2>
      </LessonsSidebar>

      <DefaultWrapper2 padding='40px max(8%, 10px)' ht = '550px'>
        <ListVertical style={{ width: '100%' , height: '100%', justifyContent: 'space-between'}}>
          {children}
          <ContentHorizontal style={{ width: '100%', justifyContent: 'space-between' }}>
            <ContentHorizontal>
              <AcceptButton title='Ответить' onClick={() => {
              MySurveysStore.setAnswerAction(true)
              }} />
                <AcceptButton title='Завершить' onClick={e => LastAction()} />
            </ContentHorizontal>
            <ContentHorizontal>
              <AcceptButton disabled={FindIndex(CurrentId) == 0} title='Назад' onClick={e => PrevOrNext('prev')} />
              <AcceptButton disabled={FindIndex(CurrentId) == (TestLength - 1)} title='Далее' onClick={e => PrevOrNext('next')} />
            </ContentHorizontal>
          </ContentHorizontal>
        </ListVertical>
      </DefaultWrapper2>
    </ >

  );
});

export default QuestionStepper;