import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Output from 'editorjs-react-renderer';
import { ShowClasses } from './../ShowConfigs/DefaultConfig';
import renderers from './../CustomRerenders/CustomRerenders'
import EmptyLesson from './../EmptyLesson';
import _ from 'lodash';
import { api } from 'api';
import { LoaderBlueFullView } from 'components/UI/Loaders';
import { MainWrap } from 'components/UI/Layouts';
import NavigateBtns from '../NavigateBtns/NavigateBtns';
import cl from './../LessonShow.module.css'
import { CourseDescription, EscapeLesson, Lesson, LessonArea, LessonHeader, LessonsSidebar, NavBtns, StudyingWrap } from '../LearnPrev/LearmPrev.styled';
import { CustomLink } from 'components/UI/Links';

const DemoPrev = () => {
    const { id_course, id_lesson } = useParams()
    const [lessonData, setLessonData] = useState(null)
    const [LessonContent, setLessonContent] = useState({})
    const [Loader, setLoader] = useState(false)
    const [NavigateArrary, setNavigateArrary] = useState({})
    const [courseInfo, setCourseInfo] = useState({})
    const [modulesAndLessons, setModulesAndLessons] = useState()

    const SortSubArrays = (array, param) => {
        return ([...array.map(subarray =>
            subarray = { ...subarray, [param]: _.sortBy(subarray[param], 'order') })])
    }

    useEffect(() => {
        setLoader(true)
      
        const getLessonContent = async () => {
            var lessonsList = (await api.get('/Module/Course/' + id_course))
            const OrderedLessonsList = SortSubArrays([...lessonsList.data.results], 'lesson')
            var response = await api.get("/Lesson/" + id_lesson)
            var result = { ...response.data[0], content: JSON.parse(response.data[0].content) }
            setLessonData(await result)
            setLessonContent(await result?.content || {})
            setNavigateArrary(await getNeighbours(OrderedLessonsList, id_lesson))
            setModulesAndLessons(OrderedLessonsList)
            setCourseInfo(lessonsList.data.cource)
        }

        getLessonContent()
      
        setLoader(false)
    }, [id_lesson])

    const getNeighbours = async (list, current) => {
        var idList = []
        list.map(module => {
            var moduleLessons = []
            module.lesson.map(lesson => moduleLessons.push(lesson.id))
            idList = [...idList, ...moduleLessons]
        })
        const cuurentIndex = idList.indexOf(Number(current))
        return {
            prev: idList[cuurentIndex - 1] || 'back',
            currentIndex: cuurentIndex,
            next: idList[cuurentIndex + 1] || 'end'
        }
    }
    return (
        <MainWrap >
            <Lesson>

                {
                    !Loader
                        ?
                        <>
                            <LessonsSidebar>
                                <CourseDescription>                                
                                    <h2>Курс: {courseInfo.label}</h2>
                                    <p>предпросмотр урока</p>
                                </CourseDescription>
                              
                                <CustomLink to={`/courses/coursedetail/${id_course}`}>
                                    <EscapeLesson>
                                        Выйти с демоурока
                                    </EscapeLesson>
                                </CustomLink>


                            </LessonsSidebar>
                            <StudyingWrap >
                                <LessonHeader>Урок: {lessonData?.name} </LessonHeader>
                                <LessonArea >
                                    <Output
                                        classNames={ShowClasses}
                                        data={(JSON.stringify(LessonContent) === '{}')
                                            ?
                                            EmptyLesson
                                            :
                                            lessonData?.content
                                        }
                                        renderers={renderers}
                                    />
                                </LessonArea>
                            </StudyingWrap>
                        </>
                        :
                        <LoaderBlueFullView />
                }

            </Lesson>
        </MainWrap>
    )
}
export default DemoPrev