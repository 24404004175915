import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import cl from './PublicCoursStyle.module.css'
import cn from 'classnames';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import PublickCoursesMenagment from 'store/PublicCoursesMenagment';
import CoursDescription from './CoursDelailInformationBlocks/CoursDescription';
import CreatorInformation from './CoursDelailInformationBlocks/CreatorInformation';
import { MainWrap } from 'components/UI/Layouts';
import { api } from 'api';
import PuplicMoadulesAndLessons from './CoursDelailInformationBlocks/ModulesAndLessons/PuplicMoadulesAndLessons';
import Reviews from './CoursDelailInformationBlocks/Reviews/components/Reviews';
import { BackButton } from 'components/UI/Buttons';
import { DefaultWrapper } from 'components/UI/Wrappers';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { CustomLink } from 'components/UI/Links';

const CourseDetail = observer(() => {
    const [AllCourseData, setAllCourseData] = useState({})
    const [dataCourse, setData] = useState({})
    const [Modules, setModules] = useState([])
    const { id } = useParams()
    const [Directions, setDirections] = useState()
    const [CurrentTab, setCurrentTab] = useState()
    const [CreatorPhoto, setCreatorPhoto] = useState()
    const setTab = (tabName) => {
        PublickCoursesMenagment.addOpendCourses(id, tabName)
        setCurrentTab(tabName)
    }

    useEffect(() => {       
        api.get('/Course/Subjects')
            .then((result) => {
                setDirections({ learning_subjects: result.data })
            }
            )  
        api.get(`/Module/Course/${id}`)
            .then((result) => {
                setCreatorPhoto({
                    mimeType: result.data.mimeType,
                    fileData: result.data.fileData
                })
                setAllCourseData({
                    course: { ...result.data.cource },
                    results: _.sortBy(result.data.results, 'order')
                })
            }
            )

        const getCourseData = async () => {
            if (PublickCoursesMenagment.OpendCourses[id] == null) {
                setTab('Descriptopn')
            } else {
                setCurrentTab(PublickCoursesMenagment.OpendCourses[id])
            }
        }
        getCourseData()
    }, [id])

    const buyCourse = (group_id) => {       
        api.post('/Course/buy', { GroupId: group_id, UserId: Number(localStorage.getItem('userId')) }
        )
    }
    return (
        <MainWrap namePage={dataCourse.label}>
            <ContentHorizontal className={cl.mainContent}>
                <DefaultWrapper className={cl.orange}>
                    <CustomLink  className={cl.Back} to = '/courses/allcourses'>Назад</CustomLink>
                    {/* <BackButton ></BackButton> */}
                </DefaultWrapper>
                <div className={cl.CoursDescriptionWrap}>
                    <CoursDescription
                        CourseData={AllCourseData.course}
                        directories_data={Directions}
                        buyCourse={buyCourse}
                        creatorInfo={{ mimeType: '', fileData: '' }}
                    />
                    <div className={cl.InformationBlock}>
                        <h2>Содержание курса</h2>
                        {
                            AllCourseData?.results?.map((module, index) => (
                                <PuplicMoadulesAndLessons
                                    key={module.id}
                                    Modul={module}
                                    index={index}
                                />
                            ))
                        }

                    </div>
                    <div className={cl.InformationBlock}>
                        <CreatorInformation
                            Creator_id={AllCourseData?.course?.c_reator}
                            CurrentCoursId={AllCourseData?.course?.id}
                            CreatorInfo={{
                                ...AllCourseData?.course?.c_reator,
                                mimeType: CreatorPhoto?.mimeType,
                                fileData: CreatorPhoto?.fileData
                            }}
                        />
                    </div>
                </div>
            </ContentHorizontal>

        </MainWrap>
    )
}

)


export default CourseDetail
