import React, { useEffect, useState } from 'react';
import cl from './QuestionCheckBox.module.css'
import ListVertical from 'components/UI/Layouts/ListVertical';
import { host } from 'routes/globals';
import _ from 'lodash'
import SimpleImg from 'components/UI/Images/SimpleImg/SimpleImg';
import OptionAnswer from '../OptionAnswer';
import { BlueButton, GreenButton, RedButton } from 'components/UI/Buttons';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { api } from 'api';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import { SpacedApartWrap } from 'routes/UserComponents/ProfilePage/pages/styled/ProfileStyles';
import { AnswerOptionList, BlueQuestionButton, DarkGreenQuestionButton, QuestionImgStyled, QuestionTextStyled, RedQuestionBtn } from '../../styled/QuestionTextStyled';
import { DarkGreenButton } from 'routes/UserComponents/MyLearn/courseShow/MyLearnCourseLesson/styled/MyLearnCourseLessonStyled';
import { ErrorField } from 'components/UI/Errors';
import { ChevronRotate } from 'components/UI/Other';
import { FaPlus } from 'react-icons/fa6';

const QuestionSelectable = ({ question, isEdit, toggleEdit, DeleteAction, type, AddNewAnswer, updateQuestion, QuestionIndex, CancelEUpdate }) => {
    const [LocalQuestion, setLoaclQuestion] = useState({})
    const [QuestionImg, setQuestionImg] = useState(null)
    const [EditErrors, setEditErrors] = useState(false)
    const [NoRightError, setNoRightError] = useState(false)
    const [ShowFull, setShowFull] = useState(false)
    const { id } = useParams()

    const { register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onSubmit' });

    useEffect(() => {
        if (question.imgQuest == null) {
            setQuestionImg(null)
        } else {
            fetch(`${host}/api/GetFile/${question.imgQuest
                }`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
                .then(response => response.status === 200 && response.blob(response))
                .then(blob => {
                    if (blob) {
                        const fileURL = URL.createObjectURL(blob)
                        setQuestionImg(fileURL)
                    }
                    else {
                        setQuestionImg(null)
                    }
                })
        }
        //setLoaclQuestion({ ...JSON.parse(JSON.stringify(question)) })

        const qData = JSON.parse(JSON.stringify(question))
        const ValidateData = { textQuest: qData?.textQuest, questionAnswer: qData?.answerOptions[0]?.textAnswer || '' }
        reset(ValidateData)
        setLoaclQuestion(qData)
    }, [question])

    //Финальное сохранение
    const saveData = () => {
        var old = JSON.parse(JSON.stringify(question))

        let { answerOptions, ...mainData } = { ...LocalQuestion }
        //Если обновлены данные вопроса
        mainData.textQuest = watch('textQuest')
        if (mainData.imgQuest !== question.imgQuest || mainData.textQuest !== question.textQuest) {
            api.put(`Question/${LocalQuestion.id}`, { ...mainData, SurveyId: id, })
                .then((result) => {
                    if (!result.status == 200) {
                        var { answerOptions, ...other } = question
                        mainData = other
                    }
                })
        }

        //TODO надо бы слить несколько if в один
        if (mainData.type == 2) { // если чекбоксы
            if (!_.isEqual(answerOptions, question.answerOptions)) {
                LocalQuestion.answerOptions.map(
                    (answer, index) => {
                        let InQuestion = old.answerOptions[index]
                        let InLocal = answerOptions[index]
                        //Если такого ответа не существует в изначальном question
                        if (InQuestion === null || InQuestion === undefined) { //Если в старом не существует, то создать
                            // создать
                            api.post(`AnswerOption`, { ...answer, QuestionId: LocalQuestion.id, Survey: id })
                                .then((result) => {
                                    answerOptions[index] = { ...answerOptions[index], id: result.data.id }
                                })
                        } else if (!_.isEqual(InQuestion, InLocal)) //если существует проверитиь совпадение
                        {    //обновить ответ                  
                            if (InQuestion !== InLocal) {
                                api.put(`AnswerOption/${answer.id}`, { ...answer, QuestionId: LocalQuestion?.id ? LocalQuestion?.question?.id : LocalQuestion.id, Survey: id })
                                    .then((result) => {
                                        answerOptions[index] = { ...answerOptions[index], id: result.data.id }
                                    })
                            }
                        }
                    }
                )
            }
            AddNewAnswer(LocalQuestion.id, LocalQuestion.answerOptions, mainData, question?.question?.type ? question?.question?.type : question?.type)
            toggleEdit(false)
        } else if (mainData.type == 3) {  //если радиокнопки
            LocalQuestion.answerOptions.map((answer, index) => {
                let InQuestion = old.answerOptions[index]
                let InLocal = answerOptions[index]
                if (InQuestion === null || InQuestion === undefined) { //Если в старом списке ответов нее было ответа с этим индексом, то создаём его 
                    // создать
                    api.post(`AnswerOption`, { ...answer, QuestionId: LocalQuestion?.id, Survey: id })
                        .then((result) => {
                            answerOptions[index] = { ...answerOptions[index], id: result.data.id }
                        })
                } else if (InQuestion.right !== InLocal.right) { // Если сменилась правильность
                    if (InQuestion !== InLocal) {
                        api.put(`AnswerOption/${answer.id}`, { ...answer, QuestionId: LocalQuestion.id, Survey: id })
                        .then((result) => {
                            answerOptions[index] = { ...answerOptions[index], id: result.data.id }
                        })
                    }
                }
            })
            AddNewAnswer(LocalQuestion.id, LocalQuestion.answerOptions, mainData, question?.question?.type ? question?.question?.type : question?.type)
            toggleEdit(false)
        }
        // Если обновлены данные ответов, то нужно проверять каждый ответ, если его не было в старом списке  создать, если был то проверить сходство и обновить по надобности 
    }

    const CancelUpdate = () => {
        setEditErrors(false)
    }

    const uploadPhoto = async (event) => {
        var file = event.target.files;
        const formData = new FormData();
        formData.append('file', file[0]);
        const NewImg = await api.post('/uploadfile', formData)
        setLoaclQuestion({ ...LocalQuestion, imgQuest: await NewImg.data.id })
        setQuestionImg(URL.createObjectURL(file[0]))
    }

    const EditRightAnswer = (status, AnswerIndex) => {
        var QuestionData = { ...LocalQuestion }
        let AnswersList = [...LocalQuestion.answerOptions]
        if (type == 'checkbox') {
            AnswersList[AnswerIndex].Right = status
            setLoaclQuestion({ ...LocalQuestion, answerOptions: AnswersList })
        }
        if (type == 'radio') {
            QuestionData.rightAnswer = AnswerIndex
            AnswersList.answers.map((answer, index) => {
                if (index == AnswerIndex) { answer.right = true }
                else { answer.right = false }
            })
            setLoaclQuestion({ ...LocalQuestion, answerOptions: AnswersList })
            //удаление ответа для радиокнопока
        }
    }

    //удаление варианта  ответа по id (ElId) или по индексу (LocalQuestion)
    const DeleteAnswer = (ElId, elIndex) => {

        var QuestionData = LocalQuestion
        var tempAnswersList = QuestionData.answerOptions
        tempAnswersList.splice(elIndex, 1)
        ElId == null
            ?
            setLoaclQuestion({ ...QuestionData, answerOptions: tempAnswersList })
            :
            api.delete(`AnswerOption/${ElId}`)
                .then(result => {
                    if (result.status == 204 || result.status == 200) {
                        setLoaclQuestion({ ...QuestionData, answerOptions: QuestionData.answerOptions.filter(elem => elem.id != ElId) })
                    }
                })
    }

    const AddAnswer = (currenSData) => {
        let plusAnswer = JSON.parse(JSON.stringify(LocalQuestion))
        plusAnswer.answerOptions.push({
            QuestionId: id,
            ImgId: null,
            TextAnswer: null,
            right: false,
            Survey: null
        })
        setLoaclQuestion(plusAnswer)
    }

    const UpdateLocalAnswer = (data, index) => {
        var Answers = JSON.parse(JSON.stringify(LocalQuestion.answerOptions))
        //Если приходящие данные не имют right
        data.right && LocalQuestion.type == 3 && Answers.map(a => a.right = false) // Если тип 3  - радиокнопки, то сначала у всех ставим right = false
        Answers[index] = { ...JSON.parse(JSON.stringify(data)) } //у ответа с нужным индексом сохраняем значение right 

        setLoaclQuestion({ ...LocalQuestion, answerOptions: [...Answers] })
    }

    const CheckHasRight = () => {

        const checkResult = LocalQuestion.answerOptions.findIndex(e => e.right == true) > -1 ? false : true
        setNoRightError(checkResult)
        return (!checkResult)
    }

    return (
        <>
            {isEdit
                ?
                <>
                    {/*Вывод редактируемой карточки */}


                    <InputValidateUseForm
                        title={`Вопрос ${QuestionIndex + 1}`}
                        type={'text'}
                        error={errors?.textQuest}
                        validateSet={register("textQuest", {
                            required: "Пожалуйста, введите текст вопроса",
                            minLength: {
                                value: 2,
                                message: 'Минимум 2 символа'
                            }
                        })}
                    />

                    {QuestionImg !== null
                        ?

                        <SimpleImg
                            src={QuestionImg}
                            maxW={'400px'}
                        />

                        :
                        'Место под картинку'
                    }
                    <QuestionTextStyled style={{ justifyContent: 'center' }}>
                        <input
                            className={cl.InputImg}
                            type={'file'}
                            onChange={(e) => uploadPhoto(e)}
                            accept='image/jpeg,image/png'
                        />

                    </QuestionTextStyled>

                    <SpacedApartWrap>
                        <p>Ответы:</p>
                        <BlueQuestionButton style={{ width: 'fit-content', gap: '20px' }} type={'button'} onClick={() => AddAnswer(LocalQuestion)}>
                            <FaPlus /> {' ' + '  Добавить ответ'}
                        </BlueQuestionButton>
                    </SpacedApartWrap>
                    {LocalQuestion?.answerOptions?.map((element, index) =>
                        <OptionAnswer
                            key={index}
                            isEdit={isEdit}
                            index={index}
                            imgAnswers={LocalQuestion?.imgAnswers}
                            element={element}
                            type={type}
                            questionIndex={LocalQuestion.id}
                            editAction={(data, index) => UpdateLocalAnswer(data, index)}
                            EditRightAnswer={EditRightAnswer}
                            DeleteAnswer={DeleteAnswer}
                            setEditErrors={(data) => setEditErrors(data)}
                        />
                    )
                    }
                    {NoRightError ? <ErrorField error={'Ответы отсутствуют, либо не выбран правильный'} /> : null}
                    <QuestionTextStyled style={{ padding: '0' }} />
                    <SpacedApartWrap>
                        <RedQuestionBtn type={'submit'} onClick={() => { CancelEUpdate(); setNoRightError(false) }}> Отменить</RedQuestionBtn>
                        <DarkGreenQuestionButton type={'submit'} onClick={() => { CheckHasRight() && !EditErrors && saveData() }}> Сохранить</DarkGreenQuestionButton>
                    </SpacedApartWrap>
                </>
                :

                <>

                    <ListVertical>
                        <QuestionTextStyled style={{ cursor: 'pointer' }} ShowUnderLine={!ShowFull}
                            onClick={() => setShowFull(!ShowFull)}>
                            №{QuestionIndex + 1}  {' ' + LocalQuestion?.textQuest}
                            <ChevronRotate triggerParam={ShowFull} />
                        </QuestionTextStyled>




                        {ShowFull
                            ?
                            <>
                                {
                                    QuestionImg == null
                                        ? null
                                        :
                                        <QuestionTextStyled style={{ justifyContent: 'center' }}>
                                            <SimpleImg
                                                src={QuestionImg}
                                                maxW={'400px'}
                                            />
                                        </QuestionTextStyled>
                                }
                                {LocalQuestion?.answerOptions?.length == 0 ? <p> ответов пока нет  </p> : null}


                                <ListVertical style={!isEdit ? { gap: '0' } : null} >
                                    {LocalQuestion?.answerOptions?.map((element, index) =>
                                        <OptionAnswer
                                            isEdit={isEdit}
                                            key={index}
                                            index={index}
                                            imgAnswers={LocalQuestion?.imgAnswers}
                                            element={element}
                                            type={type}
                                            setEditErrors={(data) => setEditErrors(data)}
                                            editAction={(data, index) => UpdateLocalAnswer(data, index)}
                                        />
                                    )
                                    }
                                </ListVertical>

                                <QuestionTextStyled style={{ padding: '0' }} />

                                <SpacedApartWrap>
                                    <RedQuestionBtn type={'button'} onClick={() => DeleteAction(true)}> Удалить</RedQuestionBtn>
                                    <BlueQuestionButton type={'button'} onClick={() => toggleEdit(true)}> Изменить</BlueQuestionButton>
                                </SpacedApartWrap>

                            </>
                            : null}

                    </ListVertical>

                </>


            }
        </>
    );
};

export default QuestionSelectable;