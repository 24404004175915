import { MainWrap } from 'components/UI/Layouts';
import ListVertical from 'components/UI/Layouts/ListVertical';
import React from 'react';
import cl from './AboutProduct.module.css'
import cn from 'classnames'
import EducationLayout from 'routes/UserComponents/MyLearn/share/EducationLayout/EducationLayout';
import { BsFileEarmarkText } from "react-icons/bs";
import { motion } from 'framer-motion'
import { link_project_docs } from 'routes/globals/server';
import HeaderMain from 'modules/HeaderMain';

const About = () => {

    //Анамации кнопок скачивания документов
    const scaleAnim = {
        scale: { scale: 1.05 }
    }

    const moveIcon = {
        scale: { y: -24, scale: 1.05 }
    }

    return (
        <>
            <HeaderMain />
            <MainWrap className={cl.main}>
                <ListVertical>
                    <EducationLayout>
                        <ListVertical>
                            <div className={cl.blockCards}>
                                <div className={cl.logo}>
                                    <img src={window.location.origin + '/images/logo.svg'} alt='logo' />
                                </div>
                                <div className={cl.horizontalBlockWrap}>
                                    <div className={cn(cl.card)}>
                                        <h2>Назначение</h2>
                                        <p>Программное обеспечение “ТОТЭРА” предназначено для автоматизации взаимодействия между авторами учебных курсов и их потребителями в формате онлайн-сервиса”</p>
                                    </div>
                                    <div className={cn(cl.card)}>
                                        <h2>На что направлен проект</h2>
                                        <p>
                                            Программное обеспечение предназначено для функционирования в виде веб-приложения и распространяется по модели SaaS</p>
                                    </div>
                                </div>
                            </div>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical>
                <ListVertical className={cl._dullBlue}>
                    <EducationLayout>
                        <ListVertical>
                            <div className={cn(cl.blockCards)}>
                                <div className={cn(cl.titleBlock)}>
                                    <h2>ПО «ТОТЭРА» реализует следующие основные функции</h2>
                                </div>
                                <div className={cl.horizontalBlockWrap}>
                                    <div className={cn(cl.card)}>
                                        <h2>ФУНКЦИИ ДЛЯ АВТОРОВ КУРСОВ</h2>
                                        <div className={cl.list}>
                                            <div className={cl.elementList}>
                                                <h1>1</h1>
                                                <p>Создание курса;</p>
                                            </div>
                                            <div className={cl.elementList}>
                                                <h1>2</h1>
                                                <p>Публикация курса;</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cn(cl.card)}>
                                        <h2>ФУНКЦИИ ДЛЯ ЧИТАТЕЛЕЙ КУРСОВ</h2>
                                        <div className={cl.list}>
                                            <div className={cl.elementList}>
                                                <h1>1</h1>
                                                <p>Просмотр доступных курсов;</p>
                                            </div>
                                            <div className={cl.elementList}>
                                                <h1>2</h1>
                                                <p>Приобретение выбранного курса;</p>
                                            </div>
                                            <div className={cl.elementList}>
                                                <h1>3</h1>
                                                <p>Прохождение курса;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical>
                <ListVertical>
                    <EducationLayout>
                        <ListVertical>
                            <div className={cl.blockCards}>
                                <div className={cn(cl.titleBlock)}>
                                    <h2>Документация</h2>
                                </div>
                                <div className={cl.horizontalBlockWrap}>
                                    <motion.a
                                        whileHover='scale'
                                        variants={scaleAnim}
                                        href={link_project_docs.descript_function}
                                        target={'_blank'}
                                        className={cn(cl.card, cl._link, cl._spaceBetween)}>
                                        <p>Описание функциональных характеристик</p>
                                        <motion.i
                                            className={cl._icon}
                                            variants={moveIcon}>
                                            <BsFileEarmarkText size={'233px'} />
                                        </motion.i>
                                        <span>Скачать</span>
                                    </motion.a>
                                    <motion.a
                                        whileHover='scale'
                                        variants={scaleAnim}
                                        href={link_project_docs.install_instruction}
                                        target={'_blank'}
                                        className={cn(cl.card, cl._link, cl._spaceBetween)}>
                                        <p>Инструкция по установке</p>
                                        <motion.i
                                            className={cl._icon}
                                            variants={moveIcon}>
                                            <BsFileEarmarkText size={'233px'} />
                                        </motion.i>
                                        <span>Скачать</span>
                                    </motion.a>
                                    <motion.a
                                        whileHover='scale'
                                        variants={scaleAnim}
                                        href={link_project_docs.user_guide}
                                        target={'_blank'}
                                        className={cn(cl.card, cl._link, cl._spaceBetween)}>
                                        <p>Руководство пользователя, установки и эксплуатации</p>
                                        <motion.i
                                            className={cl._icon}
                                            variants={moveIcon}>
                                            <BsFileEarmarkText size={'233px'} />
                                        </motion.i>
                                        <span>Скачать</span>
                                    </motion.a>
                                    {/* <motion.a
                                        whileHover='scale'
                                        variants={scaleAnim}
                                        href={link_project_docs.policy_pdn}
                                        target={'_blank'}
                                        className={cn(cl.card, cl._link, cl._spaceBetween)}
                                        download={true}
                                        >
                                        <p>Политика обработки персональных данных</p>
                                        <motion.i
                                            className={cl._icon}
                                            variants={moveIcon}>
                                            <BsFileEarmarkText size={'233px'} />
                                        </motion.i>
                                        <span>Скачать</span>
                                    </motion.a> */}
                                </div>
                                <div className={cn(cl.card)}>
                                    <h2>СТОИМОСТЬ И ПОРЯДОК ПРИОБРЕТЕНИЯ</h2>
                                    <div className={cl.horizontalBlockWrap}>
                                        <div className={cn(cl.elem, cl.left)}>
                                            <p>Телефон:
                                                <span className={cl.bold}>
                                                    +7 (3952) 48-77-37
                                                </span>
                                            </p>
                                            <p>Email:<span className={cl.bold}>info@totera.ru</span></p>
                                        </div>
                                        <div className={cn(cl.elem, cl.right)}>
                                            <p>Условия и стоимость права пользования программным обеспечением «ТОТЭРА» определяются индивидуальными контрактами.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical >
                <ListVertical className={cl._dullBlue}>
                    <EducationLayout>
                        <ListVertical>
                            <div className={cl.blockCards}>
                                <div className={cn(cl.titleBlock)}>
                                    <h2>Техническая поддержка</h2>
                                </div>
                                <div className={cn(cl.card, cl.support)}>
                                    <h2>Контакты службы технической поддержки:</h2>
                                    <div className={cl.horizontalBlockWrap}>
                                        <div className={cl.elem}>
                                            <p>Телефон:  <span className={cl.bold}>+7 (3952) 48-77-37 </span> </p>
                                            <p>Email:
                                                <span className={cl.bold}>help@totera.ru</span></p>
                                        </div>
                                        <div className={cl.elem}>
                                            <p>Адрес:
                                                <span className={cl.bold}>
                                                    665830, Иркутская область, г. Ангарск, 78-й квартал, стр. 3, офис 6.
                                                </span>
                                            </p>
                                        </div>
                                        <div className={cl.elem}>
                                            <p>
                                                <span className={cl.Label}>
                                                    Служба технической поддержки:
                                                </span>
                                                <span className={cl.bold}>
                                                    по будням с 09:00 до 18:00 (по мск с 04:00 до 13:00, GMT +8).
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <span>Техническая поддержка и сопровождение выполняются организацией-разработчиком ‒ ООО «ТОТЭРА».</span>
                                </div>
                            </div>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical>
                <ListVertical className={cl._dullBlue}>
                    <EducationLayout>
                        <ListVertical>
                            <div className={cl.blockCards}>
                                <div className={cn(cl.titleBlock)}>
                                    <h2>РЕЕСТР РОССИЙСКОГО ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ</h2>
                                </div>
                                <div className={cn(cl.card, cl.support)}>
                                    {/* <h2>РЕЕСТР РОССИЙСКОГО ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ</h2> */}
                                    <div className={cl.horizontalBlockWrap}>
                                        <div className={cl.elem} >
                                            <div className={cl.logo}>
                                                <img src={window.location.origin + '/images/logo_dump/orel_logo_1.png'} />
                                            </div>
                                        </div>
                                        <div className={cl.elem} style={{ justifyContent: 'center' }}>
                                            {/* <p> */}
                                            <span className={cl.bold}>
                                                Программное обеспечение “ТОТЭРА” включено в реестр Российского Программного обеспечения. Реестровая запись №22219 от 17.04.2024
                                            </span>
                                            {/* </p> */}
                                        </div>
                                        {/* <div className={cl.elem}>
                                            <p>
                                                <span className={cl.Label}>
                                                    Служба технической поддержки:
                                                </span>
                                                <span className={cl.bold}>
                                                    по будням с 09:00 до 18:00 (по мск с 04:00 до 13:00, GMT +8).
                                                </span>
                                            </p>
                                        </div> */}
                                    </div>
                                    {/* <span>Техническая поддержка и сопровождение выполняются организацией-разработчиком ‒ ООО «ТОТЭРА».</span> */}
                                </div>
                            </div>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical>
                <ListVertical>
                    <EducationLayout>
                        <ListVertical>
                            <div className={cl.blockCards}>
                                <div className={cn(cl.titleBlock)}>
                                    <h2>Реквизиты компании</h2>
                                </div>
                                <div className={cn(cl.card)}>
                                    <div className={cl.elem}>
                                        <p >Наименование компании:
                                            <spаn className={cn(cl.uppercase, cl.bold)}>
                                                Общество с ограниченной ответсвенностью "ТОТЭРА"
                                            </spаn>
                                        </p>
                                    </div>
                                    <div className={cn(cl.elem, cl.horizontalBlockWrap)}>
                                        <div className={cl.elem}>
                                            <p>Контактный телефон:
                                                <span className={cl.bold}>
                                                    +7 (3952) 48-77-37
                                                </span>
                                            </p>
                                        </div>
                                        <div className={cn(cl.elem, cl.rightAlign)}>
                                            <p>e-mail:  <span className={cl.bold}>
                                                info@totera.ru
                                            </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className={cn(cl.elem, cl.horizontalBlockWrap, cl.requisites)}>
                                        <div className={cl.elem}>
                                            <p>ИНН:
                                                <span className={cl.bold}>
                                                    3801158644
                                                </span>
                                            </p>
                                        </div>
                                        <div className={cl.elem}>
                                            <p>ОГРН:
                                                <span className={cl.bold}>
                                                    1233800005834
                                                </span>
                                            </p>
                                        </div>
                                        <div className={cl.elem}>
                                            <p>КПП:
                                                <span className={cl.bold}>
                                                    380101001
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical >
            </MainWrap >
        </>
    );
};

export default About;