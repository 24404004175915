import ContentHorizontal from "components/UI/Layouts/ContentHorizontal";
import { DefaultWrapper } from "components/UI/Wrappers";
import styled from "styled-components";

export const HystoryElem = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
gap: 10px;
width: 100%;

&> {

}
/* &>label {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    align-items: center;
} */
`
export const FullWrap = styled(DefaultWrapper)`
width: 100%;
padding: 30px;
`