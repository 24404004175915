import React from 'react';
import classes from './MyInput.module.css'
import cn from 'classnames';

const MyInput = ({ refInput, type, value, onClick, className, disabled, onChange, validateSet, ...props }) => {
    return (
        <input type={type}  disabled = {disabled} value={value} className={cn(classes.myinput, className)} ref={refInput} onClick={onClick} onChange={onChange} {...validateSet} {...props} />
    );
};

export default MyInput;