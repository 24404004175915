import React, { useEffect, useState } from 'react';
const MySelect = (props) => {
    const [defaultOption, setDefaultOption] = useState(props.Value)
    useEffect(() => {
        setDefaultOption(props.defaultvalue)
    }, [props]);
    return (
        <select data-element={props.Value} {...props} defaultValue={defaultOption} >
            {props?.selectlist?.map((element) => (
                <option
                    key={element.id}
                    value={element?.id}
                >
                    {element[props.selectedvalue]}
                </option>
            ))}
        </select>

    );
};

export default MySelect;