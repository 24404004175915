import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import cl from './PageLogin.module.css'
import { AcceptButton } from 'components/UI/Buttons';
import { ErrorField } from 'components/UI/Errors';
import { SwitchButton } from './styles/SwitchButton.styled';
import useDeviceInfomation from './hooks';
import { observer } from 'mobx-react-lite';
import userStore from 'store/userStore';
import { api } from 'api';
import { usePassEncrypt } from 'components/hooks';
import { Loader, LoaderBlueFullView } from 'components/UI/Loaders';
import { DefaultWrapper } from 'components/UI/Wrappers';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import OneSwitchCheckBoxValidate from 'components/UI/Inputs/HookForm/OneSwitchCheckBoxValidate';
import { link_project_docs } from 'routes/globals/server';
import { FaArrowLeftLong } from "react-icons/fa6";
import cn from 'classnames'

const PageLogin = observer((props) => {

    const [showForm, setShowForm] = useState('auth')
    const [errorResult, setErrorResult] = useState()
    const [isAuth, setIsAuth] = useState(false)
    const [seconds, setSeconds] = useState(30)
    const [isSendConfirm, setIsSendConfirm] = useState(false)
    const [hasDepart, setHasDepart] = useState(false)
    const [rememberPassword, setRememberPassword] = useState(false)
    const [recoveryMail, setRecoveryMail] = useState('')

    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'onChange' });
    const navigate = useNavigate()
    const goHome = () => navigate('/')
    const device = useDeviceInfomation()
    const passEncrypt = usePassEncrypt()
    const location = useLocation()

    useEffect(() => {
        if (location.state?.error) {
            return setErrorResult({ ...location.state.error })
        }
        if (location.state?.showForm) {
            return setShowForm(location.state.showForm)
        }
        if (location.state?.change === 'success') {
            setErrorResult({
                code: 0 ,
                message: location.state.message
            })
        }
    }, [])

    useEffect(() => {
        if (seconds > 0 && isSendConfirm) {
            const timer = setInterval(() => setSeconds(seconds - 1), 1000)
            return () => clearInterval(timer)
        } else if (seconds === 0 && isSendConfirm) {
            setIsSendConfirm(false)
            setSeconds(30)
        }
    }, [seconds, isSendConfirm])

    function SendData(data) {
        setErrorResult()
        if (showForm === 'auth') {
            setIsAuth(true)
            userStore.logIn({
                mail: data.mail,
                password: passEncrypt(data.password),
                device_type_id: device.type,
                platform: '123',
                geo: device.geo == {} ? "" : JSON.stringify(device.geo),
                system: device.system
            })
                .then(() => {
                    goHome()
                })
                .catch(error => {
                    if (error.response.status === 403 || error.response.status === 429) {
                        setShowForm('sendMail')
                        if (error.response.status === 429) {
                            const time = error.response.data
                            const second = Math.round(Number(time.split(':')[2]))
                            setSeconds(seconds - second)
                            setIsSendConfirm(true)
                        }
                        return setIsAuth(false)
                    }
                    if (error.response.status === 401) {
                        setError("password", { message: "Логин или пароль введён неправильно" })
                    }
                    else {
                        setErrorResult({ ...errorResult, message: error.response.data })
                    }
                    setIsAuth(false)

                })

        } else if (showForm === 'reg') {
            setIsAuth(true)
            api.post(`user/registration`, {
                id_employment_type: "1",
                mail: data.mail,
                user_name: data.user_name,
                password: passEncrypt(data.password),
                user_confirm_policy: JSON.parse(data.user_confirm_policy)
            })
                .then(result => {
                    setIsAuth(false)
                    setErrorResult({ ...errorResult, message: 'Успешно. Вам на почту выслано письмо для подтверждения', code: 0 })
                })
                .catch(error => {
                    setIsAuth(false)
                    setErrorResult({ ...errorResult, message: error.response.data })
                })
        } else if (showForm === 'sendMail') {
            setHasDepart(true)
            api.post('user/ResetConfirmation', { mail: data })
                .then(() => {
                    setHasDepart(false)
                    setIsSendConfirm(true)
                })
        }
    }

    const SendResetQuerry = (mail) => {
        api.post(`/user/PasswordRecovery/${watch('recoveryEmail')}`)

    }

    return (
        <div className={cl.pageLogin}>
            <DefaultWrapper className={cl.formWrapper}>
                {rememberPassword == false ?
                    <form className={cl.form} onSubmit={handleSubmit(SendData)} >
                        {
                            !isAuth && showForm !== 'sendMail'
                                ?
                                <>
                                    <ErrorField
                                        confirm={errorResult?.code}
                                        error={errorResult?.message}
                                    />
                                    <div className={cl.switchAuthorize}>
                                        <SwitchButton
                                            id={'auth'}
                                            active={showForm === 'auth'}
                                            type={'button'}
                                            onClick={(e) => { setShowForm(e.currentTarget.id); setErrorResult() }}>
                                            <p>Вход</p>
                                        </SwitchButton>
                                        <SwitchButton
                                            id={'reg'}
                                            active={showForm === 'reg'}
                                            type={'button'}
                                            onClick={(e) => { setShowForm(e.currentTarget.id); setErrorResult() }}>
                                            <p>Регистрация</p>
                                        </SwitchButton>
                                    </div>
                                    {
                                        showForm === 'reg'
                                            ?
                                            <InputValidateUseForm
                                                title={'Имя'}
                                                type={'text'}
                                                error={errors?.user_name}
                                                validateSet={register("user_name", {
                                                    required: "Пожалуйста введите Имя.",
                                                    minLength: {
                                                        value: 2,
                                                        message: 'минимум 2 символа'
                                                    },
                                                    pattern: {
                                                        value: /[А-Я][а-яё]/,
                                                        message: 'Ведите имя с большой буквы',
                                                    }
                                                })}
                                            />
                                            : null
                                    }

                                    <InputValidateUseForm
                                        title={'Электронная почта'}
                                        type={'email'}
                                        error={errors?.mail}
                                        validateSet={register("mail", {
                                            required: "Пожалуйста, введите e-mail.",
                                            minLength: {
                                                value: 1,
                                                message: 'минимум 1 символа',
                                            },
                                            pattern: {
                                                value: /^(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#%&*\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,15}$/,
                                                message: 'неверный формат email',
                                            }
                                        })}
                                    />
                                    <InputValidateUseForm
                                        title={'Пароль'}
                                        type={'password'}
                                        error={errors?.password}
                                        validateSet={register("password", {
                                            required: "Пожалуйста введите пароль.",
                                        })}
                                    />
                                    <p onClick={() => setRememberPassword(true)}>Восстановить пароль</p>
                                    {
                                        showForm === 'reg'
                                            ?
                                            <OneSwitchCheckBoxValidate
                                                RadioClass={cl.policy}
                                                onChange={(e) => setValue('user_confirm_policy', !watch('user_confirm_policy'))}
                                                RadioList={
                                                    {
                                                        id: 'policy',
                                                        value: watch('user_confirm_policy'),
                                                        name: 'user_confirm_policy',
                                                        title:
                                                            <p>Ознакомлен с
                                                                <a href={link_project_docs.policy_pdn} download> политикой обработки персональных данных</a>
                                                            </p>
                                                    }
                                                }
                                                error={errors?.user_confirm_policy}
                                                validateSet={{ ...register("user_confirm_policy", { required: "Не заполнено" }) }}
                                            />
                                            : null
                                    }
                                    <div className={cl.entryArea}>
                                        <>
                                            {/* <p onClick={() => setRememberPassword(true)}>Восстановить пароль</p> */}
                                            <AcceptButton
                                                type={'submit'}
                                                title={showForm === 'auth' ? 'Войти' : 'Зарегистрироваться'}
                                            />
                                        </>
                                        {
                                            showForm === 'reg'
                                                ? <Link to={'/regteacher'} className={cl.teacherReg}>Стать автором</Link>
                                                : null
                                        }
                                    </div>
                                </>
                                : showForm === 'sendMail'
                                    ?
                                    <div className={cl.vertical}>
                                        <div className={cl.horizontal}>
                                            <button type='button' onClick={() => { setShowForm('auth'); setSeconds(30); setHasDepart(false) }}>
                                                <FaArrowLeftLong style={{ color: 'inherit' }} />
                                            </button>
                                            <NavLink className={cl.logo} to="/">
                                                <img src={window.location.origin + '/images/logo.svg'} alt='logo' />
                                            </NavLink>
                                        </div>
                                        <p>Ваша почта не подтверждена. Отправить ссылку для подтверждения на почту?</p>
                                        <div className={cn(cl.horizontal, cl._center)}>
                                            <button
                                                className={cl.sendButton}
                                                type='button'
                                                disabled={isSendConfirm}
                                                onClick={() => SendData(watch('mail'))}
                                            >
                                                {
                                                    !hasDepart
                                                        ?
                                                        isSendConfirm
                                                            ? 'Отправлено'
                                                            : 'Отправить'
                                                        : <Loader />
                                                }
                                            </button>
                                            <p style={isSendConfirm ? { color: 'red' } : null}>
                                                {isSendConfirm ? 'Ожидайте' : null} {seconds} сек.
                                            </p>
                                        </div>
                                    </div>
                                    : <LoaderBlueFullView />
                        }
                    </form>
                    :
                    <div className={cl.form}>
                        <p>Укажите электронную почту для восстановления пароля</p>
                        <InputValidateUseForm
                            title={'Электронная почта'}
                            type={'recoveryEmail'}
                            error={errors?.recoveryEmail}
                            validateSet={register("recoveryEmail", {
                                required: "Пожалуйста, введите e-mail.",
                                minLength: {
                                    value: 1,
                                    message: 'минимум 1 символа',
                                },
                                pattern: {
                                    value: /^(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#%&*\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,15}$/,
                                    message: 'неверный формат email',
                                }
                            })}
                        />
                        <div className={cl.entryArea}>
                            <AcceptButton
                                type='button'
                                title='Отправить письмо'
                                onClick={() => { SendResetQuerry() }}
                            />
                        </div>
                        <p onClick={() => setRememberPassword(false)} >назад</p>
                    </div>
                }
            </DefaultWrapper>
        </div >
    );
});

export default PageLogin;