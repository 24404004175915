import { AcceptButton } from 'components/UI/Buttons';
import { MainPopup } from 'components/UI/Popups';
import React, { useEffect, useState } from 'react';
import { FullWrap } from '../Payments/Components/PayHistoryElement/styled/HistoryElem.styled';
import ListVertical from 'components/UI/Layouts/ListVertical';
import userStore from 'store/userStore';
import { observer } from 'mobx-react-lite';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import { useForm } from 'react-hook-form';
import { api } from 'api';
import { usePassEncrypt } from 'components/hooks';
import { ErrorField } from 'components/UI/Errors';
import { useLocation } from 'react-router-dom';
import { EditProfileForm, ListVerticalStart, ProfileBackButton, ProfileHeader, ProfileText } from '../styled/ProfileStyles';
import { BlueAcceptButton, SplitLine } from 'components/UI/Inputs/HookForm/InputValidateUseForm/styled/BlueValidateInput.styled';
import { CustomLink } from 'components/UI/Links';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';

const EmailChangeQuerry = observer(() => {
    const [changeNotice, setChangeNotice] = useState(false)
    const passEncrypt = usePassEncrypt()
    const location = useLocation()

    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }

    } = useForm({ mode: 'OnInput' });

    const changeRequest = (data) => {
        // setChangeNotice(true)
        api.post(`user/EmailChange`, {
            mail: data.mail,
            password: passEncrypt(data.password)
        })
            .catch(error => {
                if (error.response.status === 401) {
                    setError('password', { type: 'custom', message: 'Неверный пароль' });
                }
            })
    }
    return (
        <>
            <FullWrap width={'100%'}>
                <EditProfileForm onSubmit={handleSubmit(changeRequest)} >
                    <ListVertical>
                        <ContentHorizontal style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                            <CustomLink style={{ width: 'fit-content' }} to='/profile/security'>
                                <ProfileBackButton>Назад</ProfileBackButton>
                            </CustomLink>
                        </ContentHorizontal>
                        <ErrorField
                            confirm={location?.state?.change === 'success' ? 0 : 1}
                            error={location?.state?.message}
                        />
                        <ProfileHeader>Смена почты</ProfileHeader>
                        <ProfileText>Для смены почты введите новую почту и пароль от вашего аккаунта. </ProfileText>
                        <ProfileText>Инструкция по смене почты будет выслана на ваш текущий почтовый ящик. </ProfileText>
                        <InputValidateUseForm
                            title={'Текущая почта'}
                            type={'CurrentMail'}
                            value={userStore?.data?.mail}
                            placeholder={userStore?.data?.mail}
                            disabled={true}
                            active={false}
                        // placeholder={userStore?.data?.mail}                       
                        />
                        <InputValidateUseForm
                            title={'Новая почта'}
                            type={'mail'}
                            placeholder={''}
                            error={errors?.password}
                            autocomplete="off"
                            validateSet={register("mail", {
                                required: "Пожалуйста, введите новую почту.",
                                pattern: {
                                    value: /^(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#%&*\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,15}$/,
                                    message: 'неверный формат email',
                                }
                            })}
                        />
                        <InputValidateUseForm
                            title={'Старый пароль'}
                            type={'password'}
                            placeholder={''}
                            error={errors?.password}
                            validateSet={register("password", {
                                required: "Пожалуйста, введите пароль.",
                                validate: "Неверный пароль"
                            })}
                        />
                        <SplitLine />
                        <BlueAcceptButton title={'Запросить смену почты'} />
                    </ListVertical>
                </EditProfileForm>
            </FullWrap >
            <MainPopup
                isOpened={changeNotice}
                onClose={e => setChangeNotice(false)}
                title={'Внимание'}
                showHeader={true}>
                Инструкция по смене почты выслана на ваш текущий почтовый ящик.
                <AcceptButton title={'Отлично'} onClick={() => { setChangeNotice(false) }}></AcceptButton>
            </MainPopup>
        </>
    );
});
export default EmailChangeQuerry;