import React from 'react';
import UserPhoto from './Components/UserPhoto/UserPhoto';
import UserForm from './Components/UserForm/UserForm';

const Profile = () => {
    return (
        <div className='accountSection'>
            <div className='name_Section_account'>
                <p>Персональные данные</p>
            </div>
            <div className='accountInformation'>
                <UserPhoto />
                <UserForm />
            </div>
        </div>
    );
};

export default Profile;