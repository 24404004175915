import React, { useEffect, useState } from 'react';
import cl from './CourseProgress.module.css'
import { EmptyFile } from 'components/UI/Fillers';
import { host } from 'routes/globals';
import { DefaultWrapper } from 'components/UI/Wrappers';
import ImgDefault from 'components/UI/Images/ImgDefault/ImgDefault';

const CourseProgress = ({ CourseData, Directions, directories_data, lessonsCount }) => {
    const [PreviewImg, setPreviewImg] = useState(null)
    useEffect(() => {
        fetch(`${host}/api/GetFile/${CourseData?.previewImg}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
            .then(response => response.status == 200 && response.blob(response)
            )
            .then(blob => {
                if (blob) {
                    setPreviewImg(URL.createObjectURL(blob))
                } else {
                    setPreviewImg(EmptyFile)
                }
            })
    }, [CourseData])

    return (
        <DefaultWrapper className={cl.CourseProggress}>
            <ImgDefault
                $maxWidth = '240px'
                $maxHeight = '151x'
                src={CourseData?.previewImg ? `${host}/api/GetFile/${CourseData?.previewImg}` : PreviewImg}
            />
            <div className={cl.MyCourseInfo}>
                <h2 className={cl.CourseTitle}>{CourseData?.label}</h2>
                <h4>Кол-во уроков {lessonsCount}</h4>
                <p>Время прохождения {CourseData?.duration} ч</p>
                <p>Предмет {Directions?.map(subj => subj?.id == CourseData?.courseSubjectID ? subj?.subject : null)} </p>
                <p>{CourseData?.description}</p>              
            </div>
        </DefaultWrapper>
    );
};

export default CourseProgress;