import React from 'react';
import cl from './ContentHorizontal.module.css'
import cn from 'classnames'

const ContentHorizontal = ({ children, className, style, ...props }) => {
    return (
        <section className={cn(cl.contentHorizontal, className)} style={style} {...props}>
            {children}
        </section>
    );
};

export default ContentHorizontal;