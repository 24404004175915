import React from 'react';
import cl from './CustomTable.module.css'

const TableOutput = ({ data }) => {

    const columnNames = data.content[0];
    return (
        <table  >
            {
                data.withHeadings
                    ?
                    <thead>
                        <tr >
                            {columnNames?.map((columnName, index) => <th
                                className={cl.tableHead}
                                key={index} >{columnName.replace('&nbsp;', " ")}</th>)}
                        </tr>
                    </thead>
                    :
                    null
            }
            <tbody>
                {
                    data?.content.map((row, index) => (
                        index !== 0 ?
                            <tr style={{ backgroundColor: 'white' }}>
                                {
                                    Array.isArray(row) && row.length > 1 &&
                                    row.map((columnValue, i) => <td className={cl.tableRow}
                                    >{columnValue.replace('&nbsp;', " ")}</td>)
                                }
                            </tr>
                            :
                            null
                    ))
                }
            </tbody>
        </table>



    );
};

export default TableOutput;

