import React, { useEffect, useState } from 'react';
import { FaCheck } from "react-icons/fa6";
import { CheckBoxZone } from './styled/CheckBoxZone.styled';
import { CheckBoxInput } from './styled/CheckBoxInput.styled';

const CheckBox = ({ size, radius, id, value, validate, title, onChange }) => {

    const [onHover, setOnHover] = useState(false)

    return (
        <label htmlFor={id}>
            {title}
            <CheckBoxInput
                id={id}
                type="checkbox"
                key={id}
                value={value}
                {...validate}
                onChange={() => { onChange(!value) }}
            />
            <CheckBoxZone
                onMouseEnter={() => setOnHover(!onHover)}
                onMouseLeave={() => setOnHover(!onHover)}
                size={size}
                radius={radius}
                value={value}
            >
                {onHover || value ? <FaCheck /> : null}
            </CheckBoxZone>
        </label>
    );
};

export default CheckBox;