import React, { useEffect, useState } from 'react';
import cl from './LessonDetail.module.css'
import cn from 'classnames';
import _ from 'lodash';
import { api } from "api"
import LessonShowData from './components/LessonDataForm/LessonShowData';
import LessonEditData from './components/LessonEditForm/LessonEditData';
import ConstructorComponent from 'routes/TeacherComponents/CourseConstructor/Components/ConstructorComponent';


function LessonDetail({ lesson, cours_id, deleteLesson, AllModules, rebaseLesson, CurrentModulId, index, filterLessons }) {
    const [DefaultLessonData, setDefaultLessonData] = useState({})
    const [LessonData, setLessonData] = useState()
    const [LessonModulePlace, setLessonModulePlace] = useState(null)
    const [Edit, setEdit] = useState(false)

    useEffect(() => {
        setLessonData(lesson)
        setDefaultLessonData(lesson)
    }, [lesson])


    //Добавление теста в данные урока
    const createTest = (testData) => {
        setLessonData({ ...LessonData, surveys: [testData] })
    }
    const RemoveTest = async (idTest) => {
        api.delete(`survey/${idTest}`)
            .then(() => {
                setLessonData(prev => { return { ...prev, surveys: [] } })
            })
    }

    const updateDefaultLesson = () => {
        api.put(`/Lesson/sovok/${lesson.id}`, { ...LessonData, ModuleId: CurrentModulId })
            .then(result => {
                setDefaultLessonData(LessonData)
            })
    }

    //настройка демоурока
    const ToggleDemo = () => {
        LessonData.isDemo === false
            ? setLessonData({ ...LessonData, isDemo: true })
            : setLessonData({ ...LessonData, isDemo: false })
    }

    //удаляет урок из текущего модуля, скрывает popup
    const ChangeLessonModule = (moduleId, lessonId, oldModuleID, lessonData) => {
        filterLessons(moduleId, lessonId, oldModuleID, lessonData)
    }
    return (

        <>
            <div key={lesson.id} className={cn(cl.LessonCard)}>
                {!Edit
                    ?
                    //Данные урока отображение
                    <LessonShowData
                        lesson={LessonData}
                        LessonData={LessonData}
                        setEdit={setEdit}
                        cours_id={cours_id}
                        index={index}
                        createTest={createTest}
                        RemoveTest={RemoveTest}
                        deleteLesson={deleteLesson}
                    />
                    :
                    // Редактирование данных урока   
                    <>
                        <LessonEditData
                            LessonData={LessonData}
                            lesson={LessonData}
                            updateDefaultLesson={updateDefaultLesson}
                            setLessonData={setLessonData}
                            setLessonModulePlace={setLessonModulePlace}
                            LessonModulePlace={LessonModulePlace}
                            ChangeLessonModule={ChangeLessonModule}
                            setEdit={setEdit}
                            cours_id={cours_id}
                            AllModules={AllModules}
                            CurrentModulId={CurrentModulId}
                            DefaultLessonData={DefaultLessonData}
                            ToggleDemo={ToggleDemo}
                            index={index}
                        />
                    </>

                }
            </div>

            {(cours_id != null &&
                lesson.id != null) ?
                <ConstructorComponent
                    IdParams={{ course_id: cours_id, lesson_id: lesson.id }} />
                : null
            }

        </>
    )
}
export default LessonDetail;