import React from 'react';
import cl from './RedButton.module.css'
import cn from 'classnames';

const RedButton = ({ text, className, onClick, children, ...props }) => {
    return (
        <button onClick={onClick} className={cn(cl.RedButton, className)} {...props}  >
            {text || children}
        </button>
    );
};

export default RedButton;