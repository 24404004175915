import ListVertical from "components/UI/Layouts/ListVertical";
import { DefaultWrapper } from "components/UI/Wrappers";
import { IoChevronForwardOutline } from "react-icons/io5";
import styled from "styled-components";

export const CourseContentDetaileddWrap = styled(DefaultWrapper)`      
    padding: 30px;   
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 10px;
    justify-content: start;
    gap: 20px;
    box-shadow: 1px 4px 30px #00000010;

`



export const ModuleDetailedHeader = styled.div`   
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
`
export const ModuleCardDetailed = styled(DefaultWrapper)`
      width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;

    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    border-radius: 20px;
    background: #fff;
`


export const AboutModule = styled.div`   
    display: flex;
    flex-direction: column;
    gap: 15px;
    color : var(--base-blue-color);

    & > span { 
        padding-left: 25px;
    }

`

export const ModuleName = styled.p`   
    font-size: 24px;
    font-weight: 600;

`
export const ModuleDetaileddWrap = styled.div`   
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;

    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    border-radius: 20px;
    background: #F4F4F4;

    &>p {
        display: flex;
    gap: 20px;
    }
`
export const LessonsListDetailed = styled(ListVertical)`   
     flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`

export const RotateShevron = styled(IoChevronForwardOutline)`   
    font-size : 15px;
    transform:  ${props => props.$rotate ? `rotate(${props.$rotate}deg)` : 'unset'}; 
    transition: all 0.3s linear;
    cursor: pointer;
`


