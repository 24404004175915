import styled from "styled-components";

export const CardControlWrap = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
gap: 10px;
width: 100%;

&>label {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    align-items: center;
}
`