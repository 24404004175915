import { makeAutoObservable } from "mobx"

class allCoursesState {
    CoursesList = []
    CoursesGrid = 'tiles'
    FetchFiltersList = []
    FiltersList = []
    SearchValue = '%%'
    TotalCountOfElements = 0
    ElementsOnPage = 10
    CountOfPage = 0
    CurrentPage = 0
    OrderParams = { parametr: 'label', direction: 'asc' }
    constructor() {
        makeAutoObservable(this)
    }
    setCoursesList(result) {
        this.CoursesList = result
    }
    filterCoursesList(parametr, direction, listToFilter) {
        direction === 'up'
            ?
            listToFilter.CoursesList.sort((a, b) => a[parametr] > b[parametr] ? 1 : -1)
            :
            listToFilter.CoursesList.sort((a, b) => a[parametr] > b[parametr] ? -1 : 1)
    }
    setCoursesGrid(result) {
        this.CoursesGrid = result
    }
    setFiltersList(result) {
        this.FiltersList = result
        this.FetchFiltersList = result
    }
    setFiltersListForChange(result) {
        this.FetchFiltersList = result
    }
    setTotalCountOfElements(result) {
        this.TotalCountOfElements = result;
        this.CountOfPage = (Math.ceil(result / this.ElementsOnPage))
    }
    setElementsOnPage(result) {
        this.ElementsOnPage = result;
        this.CountOfPage = (Math.ceil(this.TotalCountOfElements / result))
    }
    setCountOfPage(result) {
        this.CountOfPage = result
    }
    setCurrentPage(result) {
        this.CurrentPage = result
    }
    setOrderParams(parametr, direction) {
        this.OrderParams = { parametr: parametr, direction: direction }
    }
    setSearchValue(result) {
        this.SearchValue = result
    }
}
export default new allCoursesState();