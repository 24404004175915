import styled from "styled-components";
import { InputValidateUseForm } from "../..";
import { MyInput } from "components/UI/Inputs";
import ContentHorizontal from "components/UI/Layouts/ContentHorizontal";
import { CustomLink } from "components/UI/Links";
import ListVertical from "components/UI/Layouts/ListVertical";

export const ProfileHeader = styled.h3`   
    font-size  : 20px; 
    font-weight: 600;
    color: #163C85;
`
export const ProfileText = styled.p`   
    font-size  : 14px; 
    font-weight: 400;
    color: #163C85;
`
export const ProfileTextBold = styled.p`   
    font-size  : 14px; 
    font-weight: 500;
    color: #163C85;
`

export const EditProfileForm = styled.form`   
    max-width: 765px;
    width: 80%;
    margin: 0 auto;
`

export const ChangerWrap = styled(ContentHorizontal)`   
  background-color: #E8F1FF;
  border-radius: 16px;
  justify-content: space-between;
  width: 100%;
  padding: 18px 20px;
  &>a{width: fit-content}
  `
export const SpacedApartWrap = styled(ContentHorizontal)`  
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const ProfileBackButton = styled(ContentHorizontal)`  
  background-color: #E8F1FF;
  border-radius: 13px;
  padding: 12px 20px;
  color: #163C85;
  font-size: 16px;  
  font-weight: 500;
  
 `

export const ContentHeader = styled(ContentHorizontal)`
    font-size: 22px;
    width: 100%;
    font-weight: 500;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    color: var(--base-blue-color);
`

export const CustomLinkFit = styled(CustomLink)`  
  width: fit-content;
 `

export const ListVerticalStart = styled(ListVertical)` 
justify-content: flex-start;
    align-items: flex-start;
` 
export const ListVerticalNoGap = styled(ListVertical)` 
gap: 0;
` 