import React from 'react';
import cl from './AcceptButtons.module.css'
import cn from 'classnames';
const AcceptButton = ({title, onClick, disabled, className, props}) => {
    return (
        <button title={title} className={cn(cl.accept, className)} onClick={onClick} 
         {...props} disabled={disabled}>{title}</button>
    );
};

export default AcceptButton;    