import React, { useEffect, useState } from 'react';
import cl from './PageRegTeacher.module.css'
import InputArea from 'components/UI/Inputs/InputArea';
import { AcceptButton, CancelButton } from 'components/UI/Buttons';
import { usePassEncrypt } from 'components/hooks';
import { api } from 'api';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { DefaultWrapper } from 'components/UI/Wrappers';
import { observer } from 'mobx-react-lite';
import userStore from 'store/userStore';
import { LoaderBlueFullView } from 'components/UI/Loaders';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorField } from 'components/UI/Errors';
import { MyTextarea } from 'components/UI/Inputs';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import { useForm } from 'react-hook-form';
import { InputValidateRadio } from 'components/UI/Inputs/HookForm/InputValidateRadio';
import { BlueWrap } from './StyledComponents/BlueWrap';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import OneSwitchCheckBoxValidate from 'components/UI/Inputs/HookForm/OneSwitchCheckBoxValidate';
import { link_project_docs } from 'routes/globals/server';
import { SwitchButton } from 'modules/PageLogin/styles/SwitchButton.styled';
import ProfileImgUploader from './ProfileImgUploader/ProfileImgUploader';

const PageRegTeacher = observer((props) => {
    const [dataTeacher, setDataTeacher] = useState({})
    const [inLoad, setInLoad] = useState(false)
    const [IsSucces, setIsSucces] = useState(null)
    const [error, setError] = useState({ error: '', messages: '' })
    const [Step, setStep] = useState(1)
    const LastStep = 3

    const { register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onSubmit' });

    const navigate = useNavigate()
    const goAuth = () => navigate('/login')
    const goHome = () => navigate('/')

    useEffect(() => {
        const GetUserData = async () => {
            if (userStore.auth) {
                try {
                    const { data } = await api.get('/user/0')
                    return reset(data)
                } catch {
                    return setError({ error: 1, message: 'Ошибка' })
                }
            }
        }
        GetUserData()
    }, [])

    const passEncrypt = usePassEncrypt()

    const regTeacher = (sendData) => {
        setInLoad(true)
        if (userStore.auth) {
            api.post('teacher', {
                QualificationLevel: sendData.qualification_level,
                ExperienceYears: sendData.experience_years,
                Description: sendData.description,
            })
                .then((result) => {
                    const data = {
                        user_name: sendData.user_name,
                        user_surname: sendData.user_surname,
                        user_patronymic: sendData.user_patronymic,
                        user_gmt_time: sendData.user_gmt_time,
                        user_birthday: sendData.user_birthday + 'T00:00:00',
                        user_sex: sendData.user_sex
                    }
                    api.put('user', data)
                        .then(() => {
                            userStore.changeUser(data)
                            userStore.changeUser({ role_id: 2 })
                            setInLoad(false)
                            goHome()
                        })
                        .catch(error => {
                            setError({ ...error, error: error.response.data })
                            setInLoad(false)
                        })
                })
                .catch(error => {
                    setError({ ...error, error: error.response.data })
                    setInLoad(false)
                })
        } else {
            api.post('user/RegistrationTeacher', {
                user: {
                    user_name: sendData.user_name,
                    user_surname: sendData.user_surname,
                    user_patronymic: sendData.user_patronymic,
                    mail: sendData.mail,
                    password: passEncrypt(sendData.password),
                    user_gmt_time: sendData.user_gmt_time,
                    user_birthday: sendData.user_birthday
                },
                teacher: {
                    QualificationLevel: sendData.qualification_level,
                    ExperienceYears: sendData.experience_years,
                    Description: sendData.description,
                }
            })
                .then((result) => {
                    setInLoad(false)
                    setIsSucces(true)
                })
                .catch(error => {
                    setError({ ...error, error: error.data.messages })
                    setInLoad(false)
                    setIsSucces(false)
                })
        }
    }

    const goNext = () => {
        if (Step >= 1 && Step <= LastStep) {
            setStep(Step + 1)
        }
    }
    const goBack = () => {
        if (Step >= 1 && Step <= LastStep) {
            setStep(Step - 1)
        }
    }

    return (
        <div className={cl.pageRegTeacher}>
            {/* шаги */}
            <DefaultWrapper className={cl.formWrapper}>
                {
                    !inLoad
                        ?
                        {
                            null:
                                <form onSubmit={handleSubmit(regTeacher)} >
                                    <ListVertical>
                                        <BlueWrap active={true} as='div'><p>Регистрация</p></BlueWrap>
                                        {Step === 1 &&
                                            <ListVertical>
                                                <InputValidateUseForm
                                                    title={'Электронная почта'}
                                                    isEdit={{ isAuth: userStore?.auth, mail: watch('mail') }}
                                                    type={'email'}
                                                    error={errors?.mail}
                                                    validateSet={register("mail", {
                                                        required: "Пожалуйста, введите mail",
                                                        minLength: {
                                                            value: 3,
                                                            message: 'Минимум 3 символа',
                                                        },
                                                        pattern: {
                                                            value: /^(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#%&*\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,15}$/,
                                                            message: 'Неверный формат email',
                                                        }
                                                    })}
                                                />
                                                {
                                                    !userStore.auth
                                                        ? <InputValidateUseForm
                                                            title={'Пароль'}
                                                            type={'password'}
                                                            error={errors?.password}
                                                            validateSet={register("password", {
                                                                required: "Пожалуйста, введите пароль",
                                                            })}
                                                        />
                                                        : null
                                                }

                                                <OneSwitchCheckBoxValidate
                                                    RadioClass={cl.policy}
                                                    RadioList={
                                                        {
                                                            id: 'policy',
                                                            value: watch('user_confirm_policy'),
                                                            name: 'user_confirm_policy',
                                                            title: <p>Ознакомлен с <a href={link_project_docs.policy_pdn} target={'_blank'}>политикой обработки персональных данных</a></p>
                                                        }
                                                    }
                                                    error={errors?.user_confirm_policy}
                                                    validateSet={{ ...register("user_confirm_policy", { required: "Не заполнено" }) }}
                                                    onChange={e => setValue('user_confirm_policy', e)}
                                                />
                                            </ListVertical>
                                        }
                                        {Step === 2 &&
                                            <ListVertical>
                                                <InputValidateUseForm
                                                    title={'Имя'}
                                                    type={'text'}
                                                    error={errors?.user_name}
                                                    validateSet={register("user_name", {
                                                        required: "Пожалуйста, введите имя",
                                                        minLength: {
                                                            value: 2,
                                                            message: 'Минимум 2 символа'
                                                        },
                                                        pattern: {
                                                            value: /[А-Я][а-яё]/,
                                                            message: 'Введите имя с большой буквы',
                                                        }
                                                    })}
                                                />
                                                <InputValidateUseForm
                                                    title={'Фамилия'}
                                                    type={'text'}
                                                    error={errors?.user_surname}
                                                    validateSet={register("user_surname", {
                                                        required: "Пожалуйста, введите имя",
                                                        minLength: {
                                                            value: 2,
                                                            message: 'Минимум 2 символа'
                                                        },
                                                        pattern: {
                                                            value: /[А-Я][а-яё]/,
                                                            message: 'Введите фамилию с большой буквы.',
                                                        }
                                                    })}
                                                />
                                                <InputValidateUseForm
                                                    title={'Отчество'}
                                                    type={'text'}
                                                    error={errors?.user_patronymic}
                                                    validateSet={register("user_patronymic", {
                                                        minLength: {
                                                            value: 2,
                                                            message: 'Минимум 2 символа'
                                                        },
                                                        pattern: {
                                                            value: /[А-Я][а-яё]/,
                                                            message: 'Введите отчество с большой буквы',
                                                        }
                                                    })}
                                                />
                                                <InputValidateUseForm
                                                    title={'Ваш пол'}
                                                    error={errors?.user_sex}
                                                >
                                                    <InputValidateRadio
                                                        RadioList={[
                                                            { id: 1, value: "М" },
                                                            { id: 2, value: "Ж" }
                                                        ]}
                                                        checked={watch('user_sex')}
                                                        InputClass={cl.sexInput}
                                                        RadioClass={cl.radio}
                                                        validateSet={{ ...register("user_sex", { required: "Выберите пол" }) }}
                                                    />
                                                </InputValidateUseForm>
                                            </ListVertical>
                                        }
                                        {/* {Step == 3 &&
                                            <ListVertical>
                                                <ProfileImgUploader currentImgId = {watch('user_photo')} returnImgId={val => (setValue('user_photo', val))} />                                                
                                            </ListVertical>
                                        } */}
                                        {Step === LastStep &&
                                            <ListVertical>
                                                <InputValidateUseForm
                                                    title={'День рождения'}
                                                    type={'date'}
                                                    id={'birthday'}
                                                    error={errors?.birthday}
                                                    validateSet={register("user_birthday", {
                                                        required: 'Не указана дата',
                                                    })}

                                                />
                                                <InputValidateUseForm
                                                    title={'Время по МСК'}
                                                    type={'number'}
                                                    error={errors?.user_gmt_time}
                                                    validateSet={register("user_gmt_time", {
                                                        required: 'Пожалуйста, введите значение',
                                                        min: {
                                                            value: -1,
                                                            message: 'Неверное значение'
                                                        },
                                                        max: {
                                                            value: 9,
                                                            message: 'Неверное значение'
                                                        }

                                                    })}
                                                />
                                                <InputValidateUseForm
                                                    title={'Уровень подготовки'}
                                                    type={'text'}
                                                    error={errors?.qualification_level}
                                                    validateSet={register("qualification_level", {
                                                        required: 'Пожалуйста, укажите степень',
                                                    })}
                                                />
                                                <InputValidateUseForm
                                                    title={'Стаж (год)'}
                                                    type={'number'}
                                                    error={errors?.experience_years}
                                                    validateSet={register("experience_years", {
                                                        required: 'Значения от 0 до 80 лет ',
                                                        min: {
                                                            value: 0,
                                                            message: 'Неверное значение'
                                                        },
                                                        max: {
                                                            value: 80,
                                                            message: 'Неверное значение'
                                                        }
                                                    })}
                                                />
                                                <InputArea
                                                    title={'О себе'}
                                                    type={'text'}
                                                    id={'description'}
                                                    value={dataTeacher.description}
                                                    onChange={(e) => setDataTeacher({ ...dataTeacher, description: e.target.value })}
                                                >
                                                    <MyTextarea
                                                        minRows={3}
                                                        maxRows={5}
                                                        validateSet={{ ...register("description", {}) }}
                                                    />
                                                </InputArea>
                                            </ListVertical>}
                                        <ListVertical>
                                            <ErrorField
                                                error={error.error}
                                                confirm={error.confirm}
                                            />
                                            {Step === LastStep
                                                ?
                                                <ListVertical className={cl.regAction}>
                                                    <AcceptButton
                                                        className={cl.accept}
                                                        title={'Зарегистрироваться'}
                                                        type={'submit'}
                                                    />
                                                    <BlueWrap type='button' onClick={e => goBack()}
                                                    >  Назад
                                                    </BlueWrap>
                                                </ListVertical>
                                                :
                                                <ContentHorizontal className={cl.buttonSection}>
                                                    <>
                                                        {Step == 1
                                                            ?
                                                            <SwitchButton textalign={'center'} as={Link} to={'/login'}>Назад</SwitchButton>
                                                            :
                                                            <SwitchButton type='button' onClick={e => goBack()}
                                                            >  Назад
                                                            </SwitchButton>
                                                        }
                                                        <SwitchButton onClick={handleSubmit(goNext)}
                                                        > Вперёд
                                                        </SwitchButton>
                                                    </>
                                                </ContentHorizontal>}
                                        </ListVertical>
                                    </ListVertical>
                                </form>,
                            true:
                                !userStore.auth
                                    ?
                                    <ListVertical>
                                        <p>Успешно! Вам на почту выслано письмо для подтверждения</p>
                                        <AcceptButton
                                            title={'Вход'}
                                            onClick={e => goAuth()}
                                        />
                                    </ListVertical>
                                    :
                                    <ListVertical>
                                        <p>Данные успешно подтверждены</p>
                                        <AcceptButton
                                            title={'На главную'}
                                            onClick={e => goHome()}
                                        />
                                    </ListVertical>,
                            false:
                                <ListVertical>
                                    <p>Что-то пошло не так!</p>
                                    <CancelButton
                                        title={'Попробовать ещё раз?'}
                                        onClick={e => setIsSucces(null)}
                                    />
                                </ListVertical>,
                        }[IsSucces]
                        : <LoaderBlueFullView />
                }
            </DefaultWrapper>
        </div >
    );
});

export default PageRegTeacher;