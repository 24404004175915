import React, { useEffect, useState } from 'react';
import { SliderWrap } from './styled/SliderWrap.styled';
import { HiArrowNarrowRight } from "react-icons/hi";
import { Icon } from './styled/Icon.style'
import { motion } from 'framer-motion'

const Slider = ({
    items,
    map,
    innerRef,
    sizeCard,
    widthSlider,
    sort,
    editional
}) => {

    const [scrollPoint, setScrollPoint] = useState(0)
    const [whCard, setWhCard] = useState()
    const [count, setCount] = useState(0)

    useEffect(() => {
        if (scrollPoint < 0) {
            setScrollPoint(-(count * (10 + whCard)))
        }
        setWhCard(sizeCard?.offsetWidth)
    }, [sizeCard?.offsetWidth])

    const getMathScrollLeft = () => {
        setCount(count => count + 1)
        return setScrollPoint((scrollPoint) => scrollPoint - whCard - 10)
    }

    const getMathScrollRight = () => {
        if (scrollPoint !== 0) {
            if (scrollPoint > -sizeCard?.offsetWidth) {
                setCount(count => count - 1)
                return setScrollPoint((scrollPoint) => scrollPoint + (0 - scrollPoint))
            }
            setCount(count => count - 1)
            return setScrollPoint((scrollPoint) => scrollPoint + whCard + 0)
        }
    }

    const getVisibleButtons = () => {
        return ((items?.length - count) * sizeCard?.offsetWidth) >= widthSlider
    }

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex' }}>
            {
                editional ?
                    editional
                    : null
            }
            <SliderWrap
                ref={innerRef}
                height={sizeCard?.offsetHeight}
            >
                <motion.div
                    animate={{
                        x: scrollPoint
                    }}
                >

                    {
                        sort ?
                            items?.sort(sort).map(map)
                            : items?.map(map)
                    }
                </motion.div>
            </SliderWrap>
            {
                getVisibleButtons() ?
                    <Icon right={'true'} onClick={getMathScrollLeft}>
                        <HiArrowNarrowRight />
                    </Icon>
                    : null
            }
            {
                scrollPoint < 0 ?
                    <Icon onClick={getMathScrollRight}>
                        <HiArrowNarrowRight />
                    </Icon>
                    : null
            }
        </div>
    );
};

export default Slider;