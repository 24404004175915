import React, { useRef } from 'react';
import useOnClickOutside from 'components/hooks/useOnClickOutside';
import cl from './modalSmall.module.css'
import cn from 'classnames'

const ModalSmall = ({ children, visible, setVisible, style, className }) => {
    const ref = useRef();

    useOnClickOutside(ref, setVisible);

    return (
        (visible)
            ?
            <div className={cn(cl.modalSmall, className)} ref={ref} onClick={e => e.stopPropagation()} style={style}>
                {children}
            </div>
            :
            null
    );
};

export default ModalSmall;