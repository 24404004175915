import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import MyLearnCourseLesson from '../MyLearnCourseLesson/MyLearnCourseLesson';
import { AboutModule, LessonsListDetailed, ModuleCardDetailed, ModuleDetailedHeader, ModuleDetaileddWrap, ModuleName, RotateShevron } from './styled/CourseContentDetailedStyled';
import { CustomLinkFit, ProfileBackButton } from 'routes/UserComponents/ProfilePage/pages/styled/ProfileStyles';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { IoChevronDownOutline, IoChevronForwardOutline } from 'react-icons/io5';

const CourseContentDetailed = ({ module, index, cid, mode }) => {
    // const [ModulesAndLessons, setModulesAndLessons] = useState({})

    const TogleFullView = (e) => {
        e.stopPropagation()
        setShowAll(!ShowAll)
    }

    const [ShowAll, setShowAll] = useState(false)
    return (
        // <ModuleDetaileddWrap >
        < >
            {/* {ModulesAndLessons?.map((module, index) => ( */}
            <ModuleCardDetailed key={index} id={'module_' + index} >
                <ModuleDetailedHeader onClick={(e) => TogleFullView(e)}>
                    <AboutModule >
                        <ModuleName >
                            <RotateShevron $rotate={ShowAll ? 90 : 0} /> Модуль {index + 1}: {module.name}
                        </ModuleName>
                        <span>Количество уроков: {module.lesson.length}</span>
                    </AboutModule>
                    {mode == 'edit'
                        ?
                        <CustomLinkFit to={`/teachercabinete/course/${cid}/edit/module/${module.id}`}>
                            <ProfileBackButton>
                                Изменить
                            </ProfileBackButton>
                        </CustomLinkFit>
                        :
                        null
                    }

                </ModuleDetailedHeader>
                {
                    ShowAll ?
                        _.sortBy(module.lesson, 'id').map((less, LessonIndex) => (
                            <MyLearnCourseLesson
                                cid={cid}
                                less={{ ...less, mid: module.id }}
                                LessonIndex={LessonIndex}
                                key={LessonIndex}
                                mode={mode == 'edit' ? mode : mode}
                                moduleIndex={index}
                            />
                        ))
                        : null
                }
                {/* </LessonsListDetailed> */}
            </ModuleCardDetailed>
            {/* ))} */}
        </>
    );
};

export default CourseContentDetailed;