import React, { useEffect, useState } from 'react';
import cl from './TestSettings.module.css'
import cn from 'classnames';
import { MyInput } from 'components/UI/Inputs';
import { Controller, useForm } from 'react-hook-form';
import { InputValidateUseForm, InputValidateCheckBox, InputValidateRadio } from 'components/UI/Inputs/HookForm';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { DefaultWrapper } from 'components/UI/Wrappers';

const TestSettings = ({ settings, saveSettings }) => {

    const [LocalSettings, setLocalSettings] = useState(null)

    const { register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, isTouched, isDirty, isValid }
    } = useForm(
        {
            mode: 'onChange',
            defaultValues: settings
        }
    );

    useEffect(() => {
        reset(settings);
    }, [settings]);

    const saveTest = (TestData) => {
        for (let key in TestData) {
            if (TestData[key] == "true" || TestData[key] == "false")
                TestData[key] = JSON.parse(TestData[key])
        }
        saveSettings(TestData)
    }

    return (
        <DefaultWrapper className={cl.settingsListWrap}>
            {settings != null ?
                <form onSubmit={handleSubmit(saveTest)} >
                    <ListVertical className={cl.settingsList}>
                        <h3>Настройки теста</h3>
                        <InputValidateUseForm
                            title={'Название теста'}
                            type={'text'}
                            className={cl.validateInput}
                            error={errors?.label}
                            validateSet={register("label", {
                                required: 'Пожалуйста, назовите тест ',
                                minLength: {
                                    value: 3,
                                    message: 'Минимум 3 буквы в название'
                                },
                            })}
                        />
                        <InputValidateUseForm
                            title={'Длительность'}
                            type={'number'}
                            InputClass={cl.validateInput}
                            error={errors?.duration}
                            validateSet={register("duration", {
                                required: 'Пожалуйста, укажите длительность теста',
                                min: {
                                    value: 1,
                                    message: 'Попыток не может быть меньше 0'
                                },
                            })}
                        />
                        <InputValidateUseForm
                            title={'Количество попыток'}
                            type={'number'}
                            InputClass={cl.validateInput}
                            error={errors?.tryCount}
                            validateSet={register("tryCount", {
                                required: 'Пожалуйста, укажите количество поппыток',
                                min: {
                                    value: 1,
                                    message: 'Попыток не может быть меньше 0'
                                },
                            })}
                        />

                        <InputValidateRadio
                            RadioClass={cl.RadioField}
                            title='Ограничить вывод вопросов'
                            className={cl.validateInput}
                            checked={settings?.showAny}
                            RadioList={
                                [
                                    { id: 'showAny', value: true, name: 'showAny', title: 'да' },
                                    { id: 'ShowAll', value: false, name: 'showAny', title: 'нет' }
                                ]
                            }
                            InputClass={cl.Radios}
                            error={errors?.showAny}
                            validateSet={{ ...register("showAny", { required: "Пожалуйста выберите" }) }}
                        />
                        <InputValidateUseForm
                            disabled={JSON.parse(watch("showAny")) == false}
                            title={'Количество выводимых вопросов'}
                            type={'number'}
                            // checked={settings?.showAnyCount}
                            className={cl.validateInput}
                            error={errors?.showAnyCount}
                            validateSet={register("showAnyCount", {
                                required: 'Пожалуйста укажите длительность ',
                                min: {
                                    value: 1,
                                    message: 'В тесте дожен быть мимимум 1 вопрос '
                                },
                            })}
                        />
                        <InputValidateRadio
                            RadioClass={cl.RadioField}
                            title='Порядок вопросов для ученика'
                            defaultValue={settings?.random}
                            className={cl.validateInput}
                            checked={settings?.random}
                            RadioList={
                                [
                                    { id: 'random1', value: true, name: 'random', title: 'случайно' },
                                    { id: 'random2', value: false, name: 'random', title: 'упорядочно' }
                                ]
                            }
                            InputClass={cl.Radios}
                            error={errors?.random}
                            validateSet={{ ...register("random", { required: "Выберите тип группы" }) }}
                        />
                        <InputValidateRadio
                            RadioClass={cl.RadioField}
                            title={'Разрешить перепрыгивание по вопросам'}
                            defaultValue={settings?.questJump}
                            className={cl.validateInput}
                            checked={settings?.questJump}
                            RadioList={
                                [
                                    { id: 'questJump1', value: true, name: 'questJump', title: 'да' },
                                    { id: 'questJump2', value: false, name: 'questJump', title: 'нет' }
                                ]
                            }
                            InputClass={cl.Radios}
                            error={errors?.questJump}
                            validateSet={{
                                ...register("questJump", {
                                    value: watch("questJump"),
                                    required: "Пожалуста выберите да или нет"
                                })
                            }}
                        />
                        {JSON.parse((watch("questJump"))) === true ?
                            <>
                                <InputValidateRadio
                                    RadioClass={cl.RadioField}
                                    title={'Разрешить переход к уже решённым'}
                                    className={cl.validateInput}
                                    defaultValue={settings?.solvedMove}
                                    checked={settings?.solvedMove}
                                    RadioList={
                                        [
                                            { id: 'solvedMove1', value: true, name: 'solvedMove', title: 'да' },
                                            { id: 'solvedMove2', value: false, name: 'solvedMove', title: 'нет' }
                                        ]
                                    }
                                    InputClass={cl.Radios}
                                    error={errors?.solvedMove}
                                    value={watch("solvedMove")}
                                    validateSet={{ ...register("solvedMove", { required: "Пожалуста, выберите да или нет" }) }}
                                />

                                <InputValidateRadio
                                    RadioClass={cl.RadioField}
                                    defaultValue={settings?.solvedEdit}
                                    className={cl.validateInput}
                                    checked={settings?.solvedEdit}
                                    title={'Разрешить изменять решённые'}
                                    RadioList={
                                        [
                                            { id: 'solvedEdit1', value: true, name: 'solvedEdit', title: 'да' },
                                            { id: 'solvedEdit2', value: false, name: 'solvedEdit', title: 'нет' }
                                        ]
                                    }
                                    InputClass={cl.Radios}
                                    error={errors?.solvedEdit}
                                    validateSet={{ ...register("solvedEdit", { required: "Пожалуста выберите да или нет" }) }}
                                />
                            </>
                            : null
                        }

                        {watch("pointSystem") != null &&
                            <InputValidateRadio
                                RadioClass={cl.RadioField}
                                title={`Система оценки ${watch("pointSystem") == 'true' ? '(1 вопрос - 1 балл)' : ''}`}
                                className={cl.validateInput}
                                defaultValue={settings?.pointSystem}
                                checked={settings?.pointSystem}
                                RadioList={
                                    [
                                        { id: 'limit', value: false, name: 'pointSystem', title: 'зачёт' },
                                        { id: 'point', value: true, name: 'pointSystem', title: 'баллы ' }
                                    ]
                                }
                                InputClass={cl.Radios}
                                error={errors?.pointSystem}
                                validateSet={{ ...register("pointSystem", { required: "Выберите систему" }) }}
                            />}
                        {/* минимальный проходной балл */}
                        <InputValidateUseForm
                            RadioClass={cl.RadioField}
                            title={(watch("pointSystem") === true || watch("pointSystem") == 'true') ? '3 начинается с ' : 'баллов на зачёт'}
                            type={'number'}
                            className={cl.validateInput}
                            error={errors?.failure_3}
                            validateSet={register("failure_3", {
                                required: 'Пожалуйста, укажите минимальный проходной бал',
                                min: {
                                    value: 0,
                                    message: 'Значение не может быть меньше или равно 0'
                                },
                            })}
                        />
                        {/* <InputValidateUseForm
                            RadioClass={cl.RadioField}
                            title={(watch("pointSystem") === true || watch("pointSystem") == 'true') ? '3 начинается с ' : 'баллов на зачёт'}
                            className={cl.validateInput}
                            type={'number'}
                            checked={settings?.pointSystem}
                            error={errors?.failure_3}
                            validateSet={register("failure_3", {
                                min: {
                                    value: 0,
                                    message: 'Значение не может быть меньше или равно 0'
                                },
                            })}
                        /> */}
                        {/* В зависимости от системы оценок выводятся доп поля */}
                        {(watch("pointSystem") === true || watch("pointSystem") == 'true') ?
                            <>
                                <InputValidateUseForm
                                    RadioClass={cl.RadioField}
                                    title={'4 начинается с '}
                                    type={'number'}
                                    className={cl.validateInput}
                                    error={errors?.grade_4}
                                    validateSet={register("grade_4", {
                                        required: 'Пожалуйста, укажите количество попыток',
                                        min: {
                                            value: 1 + Number(watch("failure_3")),
                                            message: `не может быть меньше или равно ${watch("failure_3")}`
                                        },
                                    })}
                                />
                                <InputValidateUseForm
                                    title={'5 начинается с '}
                                    type={'number'}
                                    RadioClass={cl.RadioField}
                                    className={cl.validateInput}
                                    error={errors?.grade_5}
                                    validateSet={register("grade_5", {
                                        required: 'Пожалуйста, укажите количество попыток',
                                        min: {
                                            value: Number(watch("grade_4")) + 1,
                                            message: `Не может быть меньше или равно ${watch("grade_4")}`
                                        },
                                    })}
                                />
                            </>
                            : null
                        }
                        <button type='submit'> Сохранить </button>
                    </ListVertical>

                </form>
                : null


            }
        </DefaultWrapper>
    );
};

export default TestSettings;