import React from 'react';
import { SurveyLobbyLabel, SurveyLobbyTestparam, SurveyLobbyValue } from '../../SurveyLobbyStyled';

const SurveylobbyTestParametr = ({ label, value }) => {
    return (
        <SurveyLobbyTestparam>
            <SurveyLobbyLabel>
               {label}
            </SurveyLobbyLabel>
            <SurveyLobbyValue>
              {value}
            </SurveyLobbyValue>
        </SurveyLobbyTestparam>
    );
};

export default SurveylobbyTestParametr;