import MyRadioBtns from 'components/UI/Inputs/MyRadioBtns/MyRadioBtns';
import React, { useEffect, useState } from 'react';
import cl from './ModuleChanger.module.css'

const ModuleChanger = ({ AllModules, onSelect, currentModul }) => {
    const [LocalRadioArray, setLocalRadioArray] = useState([])

    useEffect(()=>{
        var RadioArray = []
        if (AllModules != null) {
            AllModules.map(module => (
                RadioArray.push({id:module.id, value: module.id, name: ('feed_quality'), title:module.name,  })
            ))
            setLocalRadioArray(RadioArray)
        }
    },[AllModules])    
    return (
        <div>
            <MyRadioBtns
                defaultCheckedElement={currentModul}
                selectEvent={e => onSelect(e)}
                radioArray = { LocalRadioArray}            
                RadioBtnsAreaClassName={cl.ModuleSelector}         
            />           
        </div>
    );
};
export default ModuleChanger;