import styled from 'styled-components';

export const ImgDefaultStyle = styled.div`
width: ${props => props.width != null ? props.width : '100%'};
height:  ${props => props.height != null ? props.height : '100%'};
max-width: ${props => props.$maxWidth != null ? props.$maxWidth : 'auto'};
max-height:  ${props => props.$maxHeight != null ? props.$maxHeight : 'auto'};
border-radius:  ${props => props.borderRadius != null ? props.borderRadius : '15px'};
overflow: hidden;
object-position: center;
object-fit: cover;
display: flex;
align-items: center;
justify-content: center;

& img {    
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
`
