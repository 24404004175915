import { CustomLink } from 'components/UI/Links';
import React, { useEffect } from 'react';
import { FullWrap } from '../Payments/Components/PayHistoryElement/styled/HistoryElem.styled';
import { ChangerWrap, EditProfileForm, ProfileHeader, ProfileText, ProfileTextBold } from '../styled/ProfileStyles';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { observer } from 'mobx-react-lite';
import userStore from 'store/userStore';
import { BlueWrap } from 'routes/PublicComponents/Pages/PageRegTeacher/StyledComponents/BlueWrap';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { FaArrowRight, FaPen } from 'react-icons/fa6';
import { Outlet } from 'react-router-dom';

const Security = observer(() => {
    useEffect(() => {

    }, [])

    const HideMail = () => {
        const pad = (userStore.data.mail).split('@')
        return (pad[0].slice(0, 2) + '***@' + pad[1])
    }

    const SecurityParam = [
        { param: 'Пароль', value: '******', button: <FaPen />, to: '/profile/password' },
        { param: 'Электронная почта', value: HideMail(), button: <FaPen />, to: '/profile/mail' }
    ]


    return (
        <FullWrap width={'100%'}>
            <EditProfileForm  >
                <ListVertical>
                    <ProfileHeader>Безопасность</ProfileHeader>
                    {SecurityParam.map(elem => (
                        <ChangerWrap>
                            <ProfileTextBold>{elem.param}</ProfileTextBold>
                            <ProfileTextBold>{elem.value}</ProfileTextBold>
                            <CustomLink to={elem.to}><ProfileTextBold>{elem.button}</ProfileTextBold></CustomLink>
                        </ChangerWrap>
                    ))}
                </ListVertical>
            </EditProfileForm >
        </FullWrap >
    );
});

export default Security;