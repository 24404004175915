import { BackButton } from 'components/UI/Buttons';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import React from 'react';
import { ContentHeader } from 'routes/UserComponents/ProfilePage/pages/styled/ProfileStyles';
import { Back } from './TextAndBackHead.styled';
import { IoIosArrowRoundBack } from 'react-icons/io';

const TABHead = ({ text }) => {
    return (
        <ContentHeader 
        //style={{ marginBottom: '15px'}}
        >
            <ContentHorizontal style={{ gap: '0'}} ><Back additionalText={<IoIosArrowRoundBack />} /></ContentHorizontal>
            <h3>{text}</h3>
        </ContentHeader >
    );
};

export default TABHead;