import { makeAutoObservable } from "mobx"

class MySurveysStore {

    ActiveTry = false
    CurrentTry = null
    SendAnswerAction = null

    constructor() {
        makeAutoObservable(this)       
    }

    async setActiveTry(result) {
        this.ActiveTry = result
    }
    async setCurrentTry(result) {
        this.CurrentTry = result
    }
    async setAnswerAction(result) {
        this.SendAnswerAction = result
    }
}

export default new MySurveysStore();