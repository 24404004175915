import ListVertical from "components/UI/Layouts/ListVertical";
import { BlueButton2 } from "routes/UserComponents/MyLearn/courseShow/MyLearnCourseLesson/styled/MyLearnCourseLessonStyled";
import styled from "styled-components";

export const AddQuestionSidebar = styled(ListVertical)`
    width: 225px;
    padding-right: 20px;
    border-right: 1px solid #F2F2F2;
    gap: 10px;

`
export const SelectQParamBtn = styled(BlueButton2)`
   width: 100%;
   align-items: center;
   justify-content: center;
   background-color: ${props => props.active && '#A7C5FD' };
   color: ${props => props.active && 'var(--base-blue-color)' };
`

