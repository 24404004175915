import React, { useEffect, useState } from 'react';
import cl from './IntervalFilter.module.css'
import { observer } from 'mobx-react-lite';
import mobxState from 'routes/PublicComponents/Market/AllTeachers/store/mobxState';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'
import { MyInput } from 'components/UI/Inputs';
import useGetFitredData from 'components/UI/Filters/ListFilter/API/useGetElemsByFilters';

const IntervalFilterWritable = observer((props) => {
    const [MinMax, setMinMax] = useState({ min: 0, max: 1 }) 
    const [LocalInputValue, setLocalInputValue] = useState({ min: 0, max: 1 }) 
    const GetFitredData = useGetFitredData(props.urlFilterByParams)

    useEffect(() => {
        var mobxFilterParam = mobxState.DefaultFilters[props.FilterParametr]
        if (mobxFilterParam != null) {
            const ObjMinMax = { min: mobxFilterParam.min, max: mobxFilterParam.max }
            setMinMax(ObjMinMax)
            setLocalInputValue(ObjMinMax)
        }
    }, [mobxState.DefaultFilters, props])

    useEffect(() => {
        var mobxFetchParam = mobxState.FiltersToFetch[props.FilterParametr]
        if (mobxFetchParam != null) {
            const ObjMinMax = { min: mobxFetchParam.min, max: mobxFetchParam.max }
            setLocalInputValue(ObjMinMax)
        }
    }, [mobxState.FiltersToFetch, props])

    const ChangeComleate = async (value) => {
        var result = await GetFitredData({ page: 0,  ...mobxState.FiltersToFetch, [props.FilterParametr]: value })
        mobxState.setTotalCountOfElements(result.headers['x-total-count'])
        mobxState.SetData({
            ElementsList: result.data,
            FiltersToFetch: { ...mobxState.FiltersToFetch, [props.FilterParametr]: value }
        })
    }

    const ChangeByInputWeite = (param, value) => {
        setLocalInputValue({ ...LocalInputValue, [param]: value })
        ChangeComleate({ ...LocalInputValue, [param]: value })
    }

    return (
        <div className={cl.durationFilerLabelRange}>
            <p>{props.Titile}</p>
            <div className={cl.InputRangeNumbersinputs}>
                <MyInput value={LocalInputValue.min} onChange={e => ChangeByInputWeite('min', e.target.value)} type='number' min={MinMax.min} max={LocalInputValue.max}></MyInput>
                -
                <MyInput value={LocalInputValue.max} onChange={e => ChangeByInputWeite('max', e.target.value)} type='number' min={LocalInputValue.min} max={MinMax.max}></MyInput>
            </div>
            <div className={cl.InputField}>
                <InputRange                  
                    maxValue={MinMax.max === 0 ? 1 : MinMax.max}
                    minValue={MinMax.min}
                    onChange={value => setLocalInputValue(value)}
                    onChangeComplete={value => ChangeComleate(value)}
                    value={LocalInputValue === null ? { min: 0, max: 1 } : LocalInputValue}
                />
            </div>

        </div>
    );
});
export default IntervalFilterWritable;