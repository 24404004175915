import { useEffect, useState } from 'react';

export const useGetSystem = () => {    
    const [system, setSystem] = useState('')
    useEffect(() => {
        const navigate = navigator.userAgent;
        setSystem(navigate.slice(navigate.indexOf('Linux', 0) > 0 ? navigate.indexOf('Linux;', 0) + 'Linux;'.length : navigate.indexOf('(', 0) + 1, navigate.indexOf(')', 0)));
    }, [])

    return system;
};