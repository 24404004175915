import ImgDefault from 'components/UI/Images/ImgDefault/ImgDefault';
import ListVertical from 'components/UI/Layouts/ListVertical';
import React, { useEffect, useState } from 'react';
import { host } from 'routes/globals';
import { ColRowWrap } from '../styled/ColRowWrap.styled';
import { AnswerWrap } from '../styled/AnswerWrap.styled';
import { observer } from 'mobx-react-lite';
import MySurveysStore from 'store/MySurveysStore';

const RenderSelect = observer(({ Question, setAnswer }) => {
    const [QAnswer, setQAnswer] = useState([])
    const [updateType, setUpdateType] = useState(false)

    useEffect(() => {
        MySurveysStore.SendAnswerAction &&
            setAnswer(Question?.type == 2 ? { id: Question.id, answer: QAnswer } : { id: QAnswer[0].id, answer: QAnswer }, Question.id, updateType)
        MySurveysStore.setAnswerAction(false)
    }, [MySurveysStore.SendAnswerAction])

    useEffect(() => {
        var FirstList = []
        var updateStetus = false
        Question.answerOptions.map((option) => {
            option.answered && FirstList.push({ id: option.id, answered: option.answered });
            if (option.answered) { updateStetus = true }
        }
        )
        updateStetus && setUpdateType(updateStetus)
        setQAnswer(FirstList)

    }, [Question])

    const EditLoacalAnswer = (value, check) => {
        if (Question?.type == 2) { // чекбоксы
            if (!updateType) { //Если происходит обновление ответа чекбоксов
                if ((QAnswer.find(elem => elem.id == value.id))) {
                    setQAnswer([...QAnswer.filter(item => item.id !== value.id)])
                } else {
                    setQAnswer([...QAnswer.filter(item => item.id !== value.id), { id: value.id, answered: true }])
                }
            } else {
                setQAnswer([...QAnswer.filter(item => item.id !== value.id), { id: value.id, answered: check }])
            }
        }
        if (Question?.type == 3) { //радиокнопки            
            QAnswer.map(sel => sel.answered = false)
            setQAnswer([...QAnswer.filter(item => item.id !== value.id), { id: value.id, answered: true }])
        }
    }

    return (
        <ColRowWrap gap={'30px'} repeat={Question.imgAnswers ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'} >
            {Question?.answerOptions.map((answer, index) => (
                <AnswerWrap htmlFor={answer.id} key={answer.id}>
                    <input name={Question?.id} style={(answer.imgId && Question.imgAnswers) ? { top: '45px' } : { top: '50%' }}
                        checked={(QAnswer.find(elem => elem.id == answer.id)?.answered) ? true : false}
                        id={answer.id}
                        value={answer.id}
                        type={Question?.type == 2 ? "checkbox" : "radio"}
                        onChange={e => EditLoacalAnswer({ id: answer.id, answered: answer.answered }, e.target.checked)}
                    />
                    <ListVertical>
                        {(answer.imgId && Question.imgAnswers) ?
                            <ImgDefault width={'160px'} height={'90px'} src={`${host}/api/GetFile/${answer.imgId}`} />
                            : null}
                        <p>{answer.textAnswer}</p>
                    </ListVertical>
                </AnswerWrap>
            ))}
        </ColRowWrap>
    );
});
export default RenderSelect;