import React from 'react';
import cl from './../LessonShow.module.css'
import { CustomLink } from 'components/UI/Links';
import { AiOutlineRollback } from 'react-icons/ai';
import { IoCaretBackOutline, IoCaretForwardOutline } from 'react-icons/io5';
import { FaEdit } from "react-icons/fa";
import { LuListStart } from "react-icons/lu";
import { cloneElement } from 'react';


const NavigateBtns = ({ id_course, id_lesson, NavigateArrary, from, buttons,
    wrap = <div className={cl.LessonManageBtns} /> }) => {
    return (
        cloneElement(wrap, {
            children:
                <>{from !== 'edit' && from !== 'learn' && from !== 'constructor' &&
                    <>
                        {
                            buttons?.back ?
                                cloneElement(buttons.back, {
                                    to: from == 'demo'
                                        ? `/courses/coursedetail/${id_course}`
                                        : `/mylearn/courseshow/${id_course}`
                                })
                                : <CustomLink className={cl.LessonManageBtn}
                                    to={
                                        from == 'demo'
                                            ? `/courses/coursedetail/${id_course}`
                                            : `/mylearn/courseshow/${id_course}`
                                    }
                                    type="button" title={'Вернуться'}
                                >
                                    <AiOutlineRollback />
                                </CustomLink>
                        }

                    </>

                }

                   
                    {
                        from !== 'edit' && from !== 'demo' && from !== 'constructor'
                        && <>

                            {
                                buttons?.back ?
                                    cloneElement(buttons.back, {
                                        to: NavigateArrary?.prev == 'back'
                                            ? `/mylearn/courseshow/${id_course}`
                                            : `/mylearn/courseshow/${id_course}/lessonshow/${NavigateArrary.prev}`
                                    })
                                    :
                                    <CustomLink className={cl.LessonManageBtn}
                                        to={NavigateArrary?.prev == 'back'
                                            ? `/mylearn/courseshow/${id_course}`
                                            : `/mylearn/courseshow/${id_course}/lessonshow/${NavigateArrary.prev}`
                                        }
                                        type="button" title={'Назад'}
                                    >
                                        <IoCaretBackOutline />
                                    </CustomLink>
                            }


                            {buttons?.back ?
                                cloneElement(buttons.next, {
                                    to:
                                        NavigateArrary?.next == 'end'
                                            ? `/mylearn/courseshow/${id_course}`
                                            : `/mylearn/courseshow/${id_course}/lessonshow/${NavigateArrary.next}`
                                })
                                :
                                <CustomLink className={cl.LessonManageBtn}
                                    to={NavigateArrary?.next == 'end'
                                        ? `/mylearn/courseshow/${id_course}`
                                        : `/mylearn/courseshow/${id_course}/lessonshow/${NavigateArrary.next}`}
                                    type="button" title={'Вперёд'}
                                >
                                    <IoCaretForwardOutline />
                                </CustomLink>
                            }
                        </>
                    }

                    {from === 'constructor' &&
                        <>
                        {buttons?.save ? buttons?.save : null  }
                        {buttons?.clear ? buttons?.clear : null}
                        {buttons?.preview ? buttons?.preview : null}                           
                        </>
                    }
                </>
        })




    );
};

export default NavigateBtns;