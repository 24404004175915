import { makeAutoObservable } from "mobx"

class PageState {
    title = null

    setPageTitle(title) {
        this.title = title
    }

    constructor() {
        makeAutoObservable(this)
    }
}
export default new PageState();