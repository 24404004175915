import ContentHorizontal from "components/UI/Layouts/ContentHorizontal";
import { CustomLink } from "components/UI/Links";
import { DefaultWrapper } from "components/UI/Wrappers";
import styled from "styled-components";

export const Lesson = styled(ContentHorizontal)`
width: 100%;
padding-bottom: 20px;
max-width: unset;
`

export const LessonsSidebar = styled.div`
max-width: 280px;
width: 100%;
display: flex;
flex-direction: column;
gap:  20px;    
position: sticky;
top: 95px;
top: ${props => props.top ? props.top : '95px'};

`

export const CourseDescription = styled(DefaultWrapper)`
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
&>h2 {
    text-align: left;
    width: 100%;
    line-height: 100%;        
    font-size: 24px;
    font-weight: 500;
    color: var(--base-darkblue-color);
    position: relative;
    margin-bottom: 10px;
}
&>h2:after {
    content: '' ;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 2px;   
    background-color: var(--base-blue-color);
}
`
//---------------------кнопки вперёд назад------------------
export const NavBtns = styled(ContentHorizontal)`
flex: 1 1 auto;
gap: 10px;
max-height: 50px;
&>a{
    width: 100%;
}

`
export const MoveBtn = styled(DefaultWrapper)`
width: 100%;
border-radius: 14px;
padding: 16px 20px;
cursor: pointer;
font-size: 16px;
font-weight: 500;
max-height: 56px;
display: flex;
justify-content:center;
gap: 6px;
align-items: center;
transition: background 0.2s linear;
color: var(--base-blue-color);
background-color:${(props) => props.disabled ? '  var(--base-disable-bgc)' : '#fff'}  ;
&>svg{
    font-size: 24px;
}
 

&:hover{
    background-color:${(props) => props.disabled ? ' rgb(235, 235, 235);' : 'var(--color-green-default)'}  ;
    color: ${(props) => props.disabled ? 'var(--base-blue-color)' : '#fff'};
}   

`

// --------------------Навигация по урокам------------------
export const LessonsNavigateWrap = styled(DefaultWrapper)`
overflow: hidden;
max-height: 50vh;
border-radius: 20px;
padding: 0;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
`

export const LessonsNavigate = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0;
overflow-y: auto;
max-height: 100%;
width: 100%;

justify-content: flex-start;

&::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;  
    background-color: #D9D9D9;
}

&::-webkit-scrollbar-thumb {
    border-radius: 10px;   
    background-color:var(--base-blue-color);   
}
    &>section{
        align-items: flex-start;
        gap: 0;
    }
    &>p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
`

export const NavigateModule = styled(CustomLink)`
width: 100%;
padding: 13px 24px;
font-size: 18px;
color: var(--base-blue-color) ;
background-color:   ${props => props.focus ? 'var(--base-blue-color)' : 'unset'} ; 
color: ${props => props.focus ? '#fff' : 'var(--base-blue-color)'} ;
transition:  background 0.2s linear;
cursor: pointer;
&:hover {
    background-color: var(--base-hover-blue-color);
}
&:active , &:focus  {
    background-color: var(--base-blue-color) ;
    color: var(--base-blue-color)
}
/* &:focus {
    background-color: 
    /* ${props => props.focus ? 'var(--base-blue-color)' : 'unset'} ; 
 
} */
&>p{
    width: 100%;
}
`
export const NavigateLesson = styled(CustomLink)`
cursor: pointer;
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
transition: background 0.2s linear;
padding: 13px  35px 13px 40px  ;
background-color:  ${props => props.active ? 'var(--base-blue-color)' : 'unset'} ;
color:  ${props => props.active ? '#fff' : 'var(--base-blue-color)'} ;
font-size: 14px;
position: relative;
&:hover{
    background-color: var(--base-hover-blue-color);
    color: var(--base-blue-color);
}
&:active , &:focus {
    background-color: var(--base-blue-color);
    color: #fff; 
}
&>p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
&>svg{
    position: absolute;
    right: 8px;
    top: 50%;
    transform:translateY(-50%);
    font-size: 24px;
}
`
// -------------------------Выход с урока--------------------------
export const EscapeLesson = styled(DefaultWrapper)`
    border-radius: 14px;    
    color: var(--base-darkblue-color);
    font-size: 16px;
    font-weight: 600;
    padding: 16px 20px;
    cursor: pointer;
    width: 100%;  
    transition: background 0.2s linear;

    &:hover { 
        background-color: var(--base-hover-blue-color);
    color: var(--base-blue-color);
    }
`
export const SimpleWhiteBtn = styled(DefaultWrapper)`
  
    color: var(--base-blue-color);
    font-size: 16px;
    font-weight: 600;
    background: #FFF;
    box-shadow: -5px 4px 25px -7px rgba(0, 0, 0, 0.15);

    max-width: 250px;
    padding: 16px 20px;
    width:  ${props => props.w ? props.w : 'auto'} ;
    border-radius: 15px;

    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
    transition: background 0.2s linear;

    &:hover { 
        background-color: var(--base-hover-blue-color);
    color: var(--base-blue-color);
    }
`



// ------------------------Область урока---------------------------
export const StudyingWrap = styled(DefaultWrapper)`
display: flex;
flex-direction: column;
padding: 0;        
border-radius: 20px;
width: 100%;
    `
export const DefaultWrapperFull = styled(DefaultWrapper)`
display: flex;
flex-direction: column;
border-radius: 0px;
width: 100%;
border: none;
box-shadow: none;
    `

export const DefaultWrapperFullNP = styled(DefaultWrapperFull)`
padding: 0
    `


export const DefaultWrapperNoPadding = styled(DefaultWrapper)`
display: flex;
flex-direction: column;
border-radius: 20px;
padding: 0;
width: 100%;
    `
export const DefaultWrapper2 = styled(DefaultWrapper)`
display: flex;
flex-direction: column;
border-radius: 20px;
padding: ${props => props.padding ? props.padding : '0'} ;
gap: ${props => props.gap ? props.gap : '0'};
height: ${props => props.ht ? props.ht : 'auto'};
width: 100%;
    `
export const LessonArea = styled.div`
z-index: 0;
display: flex;
flex-direction: column;
width: 80%;
    margin: 0 auto;
`


export const LessonHeader = styled.div`
background-color: var(--base-blue-color);
width: 100%;
height: 53px;
display: flex;
justify-content: space-between;
padding: 10px 42px;
color: #fff;
font-size: 22px;
font-weight: 400;
line-height: 100%;
align-items: center;
border-radius:  20px 20px 0 0; 
`
export const EditorArea = styled.div`
    margin-right: -50px;
    margin-left: -50px;
    padding-left: 50px;
`

export const DefaultLine = styled.div`
width: 100%;
height: ${props => props.height ? props.height : '1px'};
background-color: ${props => props.bgc ? props.bgc : '#DADADA'};;

`
