import React from 'react';
import cl from './MainPopup.module.css'
import cn from 'classnames'
import OverlayPopup from '../OverlayPopup';
import { IoClose } from 'react-icons/io5'

const MainPopup = ({
    isOpened,
    onClose,
    className,
    title,
    children,
    showHeader = true,
}) => {

    return (
        <OverlayPopup isOpened={isOpened} onClose={onClose}>
            <div className={cn(cl.mainPopup, className)}>
                {
                    showHeader &&
                    <div className={cl.header}>
                        <span>{title}</span>
                        <button type='button' onClick={onClose}>
                            <IoClose size={'1.6em'} />
                        </button>
                    </div>
                }
                {children}
            </div>
        </OverlayPopup>

    );
};

export default MainPopup;