import { AcceptButton, SimpleWhiteButton } from "components/UI/Buttons";
import { InputValidateUseForm } from "components/UI/Inputs/HookForm";
import ListVertical from "components/UI/Layouts/ListVertical";
import { MainSelect } from "components/UI/Selects";
import React from "react";
import { Controller, useForm } from "react-hook-form";

const NewCardForm = ({ addCard, inputs, setInputs }) => {

    const {
        register,
        handleSubmit,
        reset,
        watch,
        control,
        formState: { errors }
    } = useForm({ mode: 'onChange' });

    const Add = (data) => {
        addCard({ number: data.number, bank: data.bank.label })
    }
    return (
        <>
            <ListVertical>
                <InputValidateUseForm
                    title={'Последние 4 цифры карты'}
                    type={'text'}
                    error={errors?.number}
                    validateSet={register("number", {
                        required: "укажите последние 4 цифры карты",
                        maxLength: {
                            value: 4,
                            message: 'максимум 4 цифры',
                        }
                    })}
                />
                <Controller
                    name="bank"
                    control={control}
                    rules={{ required: 'Пожалуйста, выберите банк' }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <MainSelect
                            title={'Банк'}
                            placeholder={'Выбор...'}
                            map={[
                                { id: 1, bank: 'тинькофф' },
                                { id: 2, bank: 'сбербанк' },
                                { id: 3, bank: 'альфабанк' },
                            ]}
                            value={['id']}
                            label={['bank']}
                            onChange={onChange}
                            error={errors?.bank}
                        />
                    )}
                />
            </ListVertical>
            <AcceptButton onClick={handleSubmit(Add)} title='Добавить карту' />
        </>

    )
};

export default NewCardForm;