import React, { Children } from 'react';
import cl from './GreenButton.module.css'
import cn from 'classnames';

const GreenButton = ({ text, className, onClick, disabled, children , ...props}) => {
    return (
        <button className={cn(cl.GreenButton, className)} disabled={disabled} {...props} onClick={onClick}>
            {text || children}
        </button>
    );
};

export default GreenButton;