import SimpleWhiteButton from 'components/UI/Buttons/SimpleWhiteButton/SimpleWhiteButton';
import React from 'react';
import cl from './BackNextBtns.module.css'
import { AcceptButton } from 'components/UI/Buttons';

const BackNextBtns = ({ Step, goNextStep, goBackStep, final }) => {
    return (
        <div className={cl.newCoursBtns}>
            {(Step === 2)
                ?
                <>
                    <SimpleWhiteButton title={'Назад'} className={cl.newCoursBtnsLeft} onClick={() => goBackStep()} />
                    <SimpleWhiteButton title={'Создать'} className={cl.newCoursBtnsRight} onClick={e => goNextStep()} />
                </>
                : null
                // ?

                // : null
            }
            {(Step === 1)
                ? <SimpleWhiteButton title={'Далее'} className={cl.newCoursBtnsRight} onClick={() => goNextStep()} />
                : null
            }
            {(Step === 3) &&
                <AcceptButton
                    className={cl.fullWidth}
                    title={'Отлично'}
                    onClick={() => final()
                    }
                />
                // <SimpleWhiteButton title={'Отлично'} className={cl.newCoursBtnsRight} onClick={() => final()} />
            }
        </div>
    );
};

export default BackNextBtns;