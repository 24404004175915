import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import cl from './CustomLink.module.css';
import cn from 'classnames';

const CustomLink = ({ children, className, active, defaultTo, to, ...props }) => {

    const resolved = useResolvedPath(to)

    const match = useMatch({
        path: resolved.pathname,
        end: to?.lenght === 1,
    })
    const coincidence = window.location.href.indexOf(to) > -1

    return (
        <Link className={cn(cl.MyLinkWrap, className, { [active]: coincidence })}
            to={to}
            {...props}
        >
            {children}
        </Link>
    );
};

export default CustomLink;