import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoLockOpen, IoLockClosed, IoLockOpenOutline } from 'react-icons/io5';
import cl from './PublicLessons.module.css'
import cn from 'classnames';

const PublicLessons = ({ Lesson, cours_id, index}) => {
    const [LocalLesson, setLocalLesson] = useState({})
    useEffect(() => {
        Lesson != null && setLocalLesson(Lesson)
    },
        [Lesson])

    return (
        < >
            {LocalLesson?.isDemo == true ?
                <Link
                    className={cn(cl.LessonPublic, cl.unlock )}
                    to={"/lesson/edit/demo/" + cours_id + "/" + LocalLesson.id}
                >                    
                    <p>Урок № {index + 1}: {LocalLesson?.name}</p>
                    <span className={cl.goDemoLesson}>демоурок 
                    </span>
                </Link>
                :
                <div className={cn(cl.LessonPublic, cl.lock)}>
                    <p>Урок № {index + 1}: {LocalLesson?.name}</p>
                    <IoLockClosed/>
                </div>
              
            }
        </>
    );
};

export default PublicLessons;