import React, { useEffect, useState } from 'react';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { api } from 'api';
import { CardCourseStudent } from 'components/UI/Cards/CardCourseStudent';
import pageState from 'store/pageState';

const MyCourses = ({ pageTitle }) => {
    const [StudentCoursesList, setStudentCoursesList] = useState([])
    const [VisibleSubMenu, setVisibleSubMenu] = useState(null)
    const [Subjects, setSubjects] = useState(null)

    useEffect(() => {
        const SetData = async () => {
            const coursesList = await api.get("/Course/User/cupil")
            const subjects = await api.get("/Course/Subjects")
            setStudentCoursesList(coursesList.data)
            setSubjects(subjects.data)
        }
        pageState.setPageTitle(pageTitle.data)
        SetData()
    }, [])
    return (
        <ListVertical viewMode={'list'}>
            {StudentCoursesList?.length !== 0 ?
                StudentCoursesList?.map(cours => (
                    <CardCourseStudent
                        key={cours.id}
                        PersonalCoursesList={StudentCoursesList}
                        cours={cours.course}
                        VisibleSubMenu={VisibleSubMenu}
                        Subjects = {Subjects}
                        setVisibleSubMenu={e => setVisibleSubMenu(e)} />
                ))
                : <p>У вас пока что нет купленных курсов</p>
            }
        </ListVertical>

    )
};

export default MyCourses;