import React, { useEffect, useState } from 'react';
import '../style.css'
import cl from './TeacherСourses.module.css'
import cn from 'classnames';
import CoursesSortTeacher from 'routes/TeacherComponents/TeacherCabinete/Components/CoursesSort/CoursesSort';
import CourseCreationSteps from '../Components/CourseCreationSteps/CourseCreationSteps';
import { api } from 'api';
import CardCourseTeacher from 'components/UI/Cards/CardCourseTeacher/CardCourseTeacher';
import { MainPopup } from 'components/UI/Popups';
import { observer } from 'mobx-react-lite';
import pageState from 'store/pageState';
import usePageTitle from 'components/hooks/usePageTitle';
import { IoAdd } from 'react-icons/io5';
import ModulesAndLessons from 'routes/TeacherComponents/CourseEdit/ModulesAndLessons/ModulesAndLessons';
import ListVertical from 'components/UI/Layouts/ListVertical';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { ContentHeader } from 'routes/UserComponents/ProfilePage/pages/styled/ProfileStyles';
import { EscapeLesson, SimpleWhiteBtn } from 'routes/UserComponents/MyLearn/LessonShow/LearnPrev/LearmPrev.styled';
import { TextAndBackHead } from 'components/UI/Other';
import { Layout1200 } from 'routes/UserComponents/MyLearn/share/EducationLayout/styled/EducationLayoutStyled';

const TeacherСourses = ({ pageTitle }) => {
    const [Courses, setCourses] = useState([])
    const [NewCoursPopupVisibility, setNewCoursPopupVisibility] = useState(false)
    const [Subjects, setSubjects] = useState([])

    useEffect(() => {
        pageState.setPageTitle(pageTitle)
        api.get(`/Course/User/${Number(localStorage.getItem('userId'))}`)
            .then((result) => { setCourses(result.data) },
                (error) => {

                }
            )
        api.get("/Course/Subjects",)
            .then((result) => { setSubjects(result.data) }
            )

    }, [])

    const ChangePublick = (index, id) => {
        const data = {
            cours_id: id,
            fields: (Courses[index].isPublic === true) ? { isPublic: false } : { isPublic: true },
        }
        api.put(`/course/${id}`, { ...data.fields })
            .then(() => {
                var newCourses = [...Courses];
                newCourses[index].isPublic = data.fields.isPublic
                setCourses(newCourses)
            })
    }

    const filterCoursesList = (parametr, direction) => {
        direction === 'up'
            ? setCourses([...Courses].sort((a, b) => a[parametr] > b[parametr] ? 1 : -1))
            : setCourses([...Courses].sort((a, b) => a[parametr] > b[parametr] ? -1 : 1))
    }

    return (
        <Layout1200>

            <section className="content">
                <div className={cl.CoursesMainWrap}>
                    <TextAndBackHead />
                    <ContentHeader>
                        <h2>Курсы</h2>
                        <SimpleWhiteBtn onClick={() => setNewCoursPopupVisibility(true)}>
                            <IoAdd />
                            <p> Создать курс </p>
                        </SimpleWhiteBtn>
                    </ContentHeader>
                    {/* <div className={cn(cl.ControllPanel)}>
                               
                </div> */}

                    <div className={cl.coursesList}>
                        {
                            Courses.map((cours, index) => (
                                <CardCourseTeacher
                                    key={cours.id}
                                    Courses={Courses}
                                    setCourses={setCourses}
                                    cours={cours}
                                    ChangePublic={ChangePublick}
                                    index={index}
                                    Subjects={Subjects}
                                />
                            ))}
                    </div>
                </div>
                <MainPopup
                    isOpened={NewCoursPopupVisibility}
                    onClose={e => setNewCoursPopupVisibility(false)}
                    className={cl.popup}
                    title={'Создание курса'}>
                    <CourseCreationSteps
                        Courses={Courses}
                        subjects={Subjects}
                        setCourses={setCourses}
                        setNewCoursPopupVisibility={setNewCoursPopupVisibility}
                    />
                </MainPopup>
            </section>
        </Layout1200>

    );
};

export default TeacherСourses;