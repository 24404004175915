import ListVertical from 'components/UI/Layouts/ListVertical';
import React, { useEffect, useState } from 'react';
import { RenderText } from './RenderText';
import { RenderSelect } from './RenderSelect';
import ImgDefault from 'components/UI/Images/ImgDefault/ImgDefault';
import { host } from 'routes/globals';
import { EmptyFile } from 'components/UI/Fillers';
import _ from 'lodash'
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { api } from 'api';
import { DefaultLine, DefaultWrapper2, DefaultWrapperNoPadding } from 'routes/UserComponents/MyLearn/LessonShow/LearnPrev/LearmPrev.styled';

const QuestionRender = ({ Question, ListLength, CurrentIndex, setAnswersToCheck, setCurrent, updateQuestion, ...props }) => {
  const [TestAnswers, setTestAnswers] = useState([])
  const [localQuestion, setlocalQuestion] = useState(null)

  useEffect(() => {
    setlocalQuestion(Question)
  }, [Question])

  const updateAnswersList = (answer) => {
    setAnswersToCheck(answer, Question.id, Question.type)
  }

  const SendAnswerToDB = async (text, id) => {
    const result = await api.put(`AnswerOption/AddUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, { userAnswerText: text, questionId: id })
    return id
  }


  // Если ответ отмечен как isNotSelect, то отправить запрос на удаление
  // Если ответ отмечен как siSelect, то отправить запрос на добавление
  const SetAnswer = (answer, updateActionStatus) => {
    if (updateActionStatus) {
      if (Question.type == 3 || Question.type == 2) {
        var CopyList = JSON.parse(JSON.stringify(Question))
        answer?.answer?.map(a => {
          a.answered
            ?
            api.put(`AnswerOption/AddUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, { userAnswerText: String(a.id), questionId: Question.id })
            :
            api.post(`AnswerOption/DeleteUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, { userAnswerText: String(a.id), questionId: Question.id })

          const elIndex = CopyList.answerOptions.findIndex(e => e.id == a.id)
          CopyList.answerOptions[elIndex] = { ...CopyList.answerOptions[elIndex], answered: a.answered }
          updateAnswersList(answer)
          updateQuestion(CopyList)
          // это выажный и нужный запрос в цыкле (не удалять)
        }
        )
      }
    } else {
      //строка текста
      if (Question.type == 1) {
       
        api.put(`AnswerOption/AddUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, { userAnswerText: String(answer.answer), questionId: Question.id })
          //Нужно обновить локальные Question.answerOptions.answerd
          .then((result) => updateAnswersList(result.data))

      }

      //чекбоксы
      if (Question.type == 2) {
        var CopyList = JSON.parse(JSON.stringify(Question))
        answer?.answer?.map(a => {
          // это выажный и нужный запрос в цыкле (не удалять)
          api.put(`AnswerOption/AddUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, { userAnswerText: String(a.id), questionId: Question.id })
          const elIndex = CopyList.answerOptions.findIndex(e => e.id == a.id)
          CopyList.answerOptions[elIndex] = { ...CopyList.answerOptions[elIndex], answered: true }
        }
        )
        updateAnswersList(answer)
        updateQuestion(CopyList)
      }
      //радиокнопки
      if (Question.type == 3) {
        var CopyList = JSON.parse(JSON.stringify(Question)) 
        api.put(`AnswerOption/AddUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, { userAnswerText: String(answer.answer[0].id), questionId: Question.id })
          //Нужно обновить локальные Question.answerOptions.answerd
          .then(() => {
            const elIndex = CopyList.answerOptions.findIndex(e => e.id == answer.id)
            CopyList.answerOptions[elIndex] = { ...CopyList.answerOptions[elIndex], answered: true }
            updateAnswersList(answer);
            updateQuestion(CopyList)
          })
      }
    }
  }

  //данные для заполнения в случаее перехода к этому вопросу
  const FindAnswer = (questonId) => TestAnswers.find(Q => Q.id == questonId)
  return (
    <ListVertical>
      <h3>
        {Question?.textQuest}
      </h3>
      {Question?.imgQuest != null
        ?
        <>
          <DefaultLine />
          <ImgDefault width={'320px'} height={'180px'}
            src={Question.imgQuest
              ? `${host}/api/GetFile/${Question.imgQuest}`
              : EmptyFile
            } />
        </>
        : null
      }
      <DefaultLine />
      {/* Список ответов */}
      {(() => {
        switch (Question.type) {
          case "1":
            return (<RenderText
              Question={Question}
              setAnswer={(answer, curId) => { SetAnswer(answer); setCurrent(curId) }} postEdit={true} />);
          case "2":
            return (<RenderSelect Question={Question}
              setAnswer={(answer, curId, updateActionStatus, ElIndex) => { SetAnswer(answer, updateActionStatus); setCurrent(curId) }} />);
          case "3":
            return (<RenderSelect
              Question={Question}
              setAnswer={(answer, curId, updateActionStatus, ElIndex) => { SetAnswer(answer, updateActionStatus); setCurrent(curId) }} />);
          default: return null;
        }
      })()}
    </ListVertical >

  );
};

export default QuestionRender;