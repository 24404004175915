import React from 'react';
import cl from './YellowButton.module.css'
import cn from 'classnames';

const YellowButton = ({ text, className, disabled  , onClick, props, children }) => {
    return (
        <button className={cn(cl.YellowButton, className)} disabled={disabled} {...props} onClick={onClick}>
            {text || children}
        </button>
    );
};

export default YellowButton;