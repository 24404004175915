import React, { useEffect, useState } from 'react';
import cl from './CardCours.module.css'
import { Link } from 'react-router-dom';
import { ModalSmall } from 'components/UI/Modals';
import WrapperNoShadow from 'components/UI/Wrappers/WrapperNoShadow';
import { api } from 'api';
import { EmptyFile } from 'components/UI/Fillers';
import { ReviewsCounter } from 'components/UI/Other';
import { BlueButton } from 'components/UI/Buttons';
import { MainPopup } from 'components/UI/Popups';
import BuyConfirmCard from '../BuyConfirmCard/BuyConfirmCard';

const CardCours = ({ item, cardStyle }) => {
    const [Photo, setPhoto] = useState(null)
    const [ShortMenu, setShortMenu] = useState(false)
    const [AlreadyBuy, setAlreadyBuy] = useState(false)
    const [ShowBuyPopup, setShowBuyPopup] = useState(false)
    const [Favorite, setFavorite] = useState(false)

    useEffect(() => {
        const BuyStatus = async () => {
            const Status = await api.get('/Course/cupil/' + item.id)
            !(Status.data) && setAlreadyBuy(!Status.data)
        }
        BuyStatus()
    }, [])

    useEffect(() => {
        item?.favorite &&
            setFavorite(item?.favorite)
        if (item) {
            const img = document.createElement('img');
            if (item.mimeType == null && item.fileData == null) {
                img.src = EmptyFile
            } else {
                img.src = 'data:' + item.mimeType + ';base64,' + item.fileData;
            }
            setPhoto(img.src)

        }

    }, [item])

    const CheckBuy = (id) => {
        setShortMenu(false)
        api.get('/Course/cupil/' + id)
            .then(result => {
                !result.data
                    ? setShowBuyPopup(true)
                    : setAlreadyBuy(true)
            })
    }
 
   

    return (
        <div className={cl.courseCard}>
            <div className={cl.main}>
                <div className={cl.Image} >
                    <img src={Photo} alt='preview' />
                </div>
                <div className={cl.Content} >
                    <h2 className={cl.CourseLabel}>
                        {item.label}
                    </h2>
                    <ReviewsCounter />
                    <div className={cl.EducationArea}>
                        <h4>Предмет: {item.courseSubject.subject}</h4>
                        <p className={cl.Duration} >Продолжительность  {item.duration} часов</p>
                    </div>
                        {/* <h4>Предмет: {item.courseSubject.subject}</h4> */}
                    <span className={cl.fullDescription}>
                        {item.description}
                    </span>
                </div>
                <div className={cl.Buttons} >
                    <div className={cl.BuyAndFavorite}>
                        <div className={cl.price}>
                            <p>{item?.price > 0 ? item?.price + "Р" : 'бесплатно'}</p>
                        </div>
                    </div>
                    <WrapperNoShadow>
                        {AlreadyBuy !== true
                            ?
                            <button disabled={true} className={cl.byBtn}>Куплено</button>
                            :
                            <button onClick={e => CheckBuy(item.id)} className={cl.byBtn}>{item?.price > 0 ? "Купить" : 'Добавить'}</button>
                        }
                    </WrapperNoShadow>
                    <Link to={'/courses/coursedetail/' + item?.id}>
                        <BlueButton className={cl.detailBtn}> Подробнее </BlueButton>
                    </Link>
                </div>
            </div>
            <ModalSmall
                className={cl.CardSubModal}
                visible={ShortMenu}
                setVisible={e => setShortMenu(false)}>
                <WrapperNoShadow>
                    {AlreadyBuy
                        ?
                        <button disabled={'true'} className={cl.fullModeButton}>Куплено</button>
                        :
                        <button onClick={e => CheckBuy(item.id)} className={cl.fullModeButton}>Купить</button>
                    }
                </WrapperNoShadow>
                <WrapperNoShadow>
                    <Link className={cl.GoCurseLink} to={'/courses/coursedetail/' + item?.id}>Подробнее</Link>
                </WrapperNoShadow>
            </ModalSmall>
            <MainPopup
                isOpened={ShowBuyPopup}
                onClose={e => setShowBuyPopup(false)}
                title='Подтверждение покупки'
                className={cl.buyPopup}
            >
                <BuyConfirmCard
                    item={item}    
                    buyAction = {e => setAlreadyBuy(false)}
                    Photo={Photo}
                />
            </MainPopup>           
        </div>
    );
};

export default CardCours;