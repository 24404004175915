import React from 'react';
import cl from './SimpleImg.module.css';
import cn from 'classnames';

const SimpleImg = ({ src, maxW, maxH, className }) => {
    return (
        <div className={cn(cl.SimpleImg, className)} style={{ maxWidth: maxW, maxHeight: maxH }} >
            <img src={src} />
        </div>
    );
};

export default SimpleImg;