import { Route, Routes, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

import TeacherCabinete from './routes/TeacherComponents/TeacherCabinete/TeacherCabinete';
import TeacherСourses from 'routes/TeacherComponents/TeacherCabinete/TeacherСourses/TeacherСourses';
import { Documents } from 'routes/UserComponents/MyAccount';
import MyLearn from './routes/UserComponents/MyLearn/MyLearn';
import Constructor from './routes/TeacherComponents/CourseConstructor/Constructor';
import CourseEdit from './routes/TeacherComponents/CourseEdit/CoursEdit';
import CourseDetail from './routes/PublicComponents/Market/CourseDetail/CourseDetail';
import CourseShow from './routes/UserComponents/MyLearn/courseShow/CourseShow';
import HocPrivateRoute from 'hoc/HocPrivateRoute';
import PageRegTeacher from 'routes/PublicComponents/Pages/PageRegTeacher';
import PageLogin from 'modules/PageLogin';
import Layout from 'components/UI/Layouts/Layout';
import { observer } from 'mobx-react-lite';
import userStore from 'store/userStore';
import MyCourses from 'routes/UserComponents/MyLearn/MyCourses';
import SearchCourses from 'routes/PublicComponents/Pages/SearchCourses';
import CoursInformation from 'routes/TeacherComponents/CourseEdit/CoursInformation/CoursInformation';
import ModulesAndLessons from 'routes/TeacherComponents/CourseEdit/ModulesAndLessons/ModulesAndLessons';
import ProfilePage from 'routes/UserComponents/ProfilePage';
import Profile from 'routes/UserComponents/ProfilePage/pages/Profile/Profile';
import Payments from 'routes/UserComponents/ProfilePage/pages/Payments';
import AboutProduct from 'routes/PublicComponents/Pages/AboutProduct';
import { NotFound } from 'components/system';
import HomePage from 'routes/PublicComponents/Pages/HomePage';
import PageConfirmation from 'modules/PageConfirmation';
import Connection from 'routes/TeacherComponents/CourseEdit/Connection/Connection';
import LearnPrev from 'routes/UserComponents/MyLearn/LessonShow/LearnPrev/LearnPrev';
import EditPrev from 'routes/UserComponents/MyLearn/LessonShow/EditPrev/EditPrev';
import DemoPrev from 'routes/UserComponents/MyLearn/LessonShow/DemoPrev/DemoPrev';
import { PasswordChanger } from 'routes/UserComponents/ProfilePage/pages/PasswordChange';
import { PasswordConfirm } from 'routes/UserComponents/ProfilePage/pages/PasswordConfirm';
import { PasswordRecovery } from 'routes/UserComponents/ProfilePage/pages/PasswordRecovery';
import { HocConfirmRoute, HocDirectRoute } from 'hoc';
import EmailChangeQuerry from 'routes/UserComponents/ProfilePage/pages/EmailChange/EmailChangeQuerry';
import SetNewEmail from 'routes/UserComponents/ProfilePage/pages/EmailChange/SetNewEmail';
import { Security } from 'routes/UserComponents/ProfilePage/pages/Security';
import CourseContentEditor from 'routes/TeacherComponents/CourseEdit/ModulesAndLessons/CourseContentEditor';
import AddTestPage from 'routes/TeacherComponents/TestConstructor';
import { SurveyLobby } from 'routes/UserComponents/MyLearn/courseShow/SurveyLobby';
import { TestPassing } from 'routes/UserComponents/MyLearn/courseShow/TestPassing';

const App = observer(() => {
  useEffect(() => {
    userStore.checkAuth()
  }, [])

  return (
    <>
      <Routes>
        <Route path="/regteacher" element={<PageRegTeacher />} />
        <Route path="/login" element={<PageLogin />} />
        <Route path="/SetNewEmail" element={<SetNewEmail />} />
        <Route path="/About" element={<AboutProduct headerTitle={'О нас'} />} />
        <Route path="/" element={<HocDirectRoute />}>
          <Route path='/confirmation/*' element={<HocConfirmRoute />} />
          <Route path='ChangePassword/:link' element={<PasswordConfirm />} />
          {/* <Route path='RecoveryPassword/:link' element={<PasswordRecovery />} /> */}
          <Route path="/" element={<HocPrivateRoute />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Navigate to='/home' replace />} />
              <Route path="home" element={<HomePage />} />
              <Route element={<HocPrivateRoute condition={userStore.data.role_id == 2} />}>
                {/* Кабинет учителя, управление курсами */}
                <Route path="teachercabinete/*" element={<TeacherCabinete headerTitle={'Кабинет автора'} />} >
                  <Route path="course/:cid/edit/module/:mid/lesson/:lid/survey/:id" element={<AddTestPage headerTitle={'Создание теста'} />} />
                  <Route path="course/*">
                    <Route index path="list" element={<TeacherСourses />} />
                    <Route path=":cid/*" element={<CourseEdit />}>
                      <Route path="info" element={< CoursInformation />} />
                      <Route path="edit/*" element={<CourseContentEditor />} >
                        <Route path="module/:mid/*" element={<CourseContentEditor />} >
                          <Route path="lesson/:lid/*" element={<CourseContentEditor />} >
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>


              </Route>
              <Route path='profile/*' element={<ProfilePage headerTitle={'Профиль'} />}>
                <Route path='main' element={<Profile />} />
                <Route path='payments' element={<Payments />} />
                <Route path='security' element={<Security />} />
                <Route path='password' element={<PasswordChanger />} />
                <Route path='mail' element={<EmailChangeQuerry />} />
                {/* </Route> */}
              </Route>


              <Route path="/courses/allcourses" element={<SearchCourses headerTitle={'Поиск курсов'} />} />
              <Route path="/courses/coursedetail/:id" element={<CourseDetail />} />
              {/* <Route path="lessons" element={<ModulesAndLessons />} /> */}
              {/* <Route path="connection" element={<Connection />} /> */}
              <Route path="*" element={<NotFound />} />


              {/* ------------------------------------------------- */}
              <Route path="/courses/constructor" element={<Constructor />} />
              <Route path="/lesson/edit/:id" element={<Constructor />} />

              <Route path="/lesson/edit/:course_id/:lesson_id" element={<Constructor />} />
              <Route path="/lesson/edit/preview/:id_course/:id_lesson" element={<EditPrev />} />
              <Route path="/lesson/edit/demo/:id_course/:id_lesson" element={<DemoPrev />} />
              <Route path="/mylearn/*" element={<MyLearn headerTitle={'Моё обучение'} />} >
                <Route index element={<MyCourses pageTitle={'Мои курсы'} />} />
                <Route path="mycourses" element={<MyCourses pageTitle={'Мои курсы'} />} />
              </Route>
              <Route path="/mylearn/courseshow/:id" element={<CourseShow />} />
              <Route path="/mylearn/courseshow/:id_course/lessonshow/:id_lesson" element={<LearnPrev />} />
              <Route path="/mylearn/courseshow/:id_course/lesson/:id_lesson/surveyLobby/:id_test" element={<SurveyLobby />} />
              {/* <Route path="/mylearn/courseshow/:id_course/lesson/:id_lesson/survey/" element={<TestPassing />} /> */}
              <Route path="/mylearn/courseshow/:id_course/lesson/:id_lesson/survey/:id_test" element={<TestPassing />} />
            </Route>

          </Route>
        </Route>
        <Route path="*" element={<h2 style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>404 not found </h2>} />
      </Routes >
    </>
  );
})

export default App;
