

import { ModalSmall } from 'components/UI/Modals';
import React, { useState } from 'react';
import ModuleChanger from '../../../ModuleChanger/ModuleChanger';
import { ButtonNo, ButtonYes, GreenButton, YellowButton } from 'components/UI/Buttons';
import { MyInput, MyTextarea } from 'components/UI/Inputs';
import { MySwitch } from 'components/UI/Switches';
import { IoMdSwap } from 'react-icons/io';
import cl from '../../LessonDetail.module.css'
import cn from 'classnames';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const LessonEditData = ({ lesson, LessonData, updateDefaultLesson, setLessonData, setLessonModulePlace, ChangeLessonModule, setEdit, cours_id, AllModules, CurrentModulId, DefaultLessonData, LessonModulePlace, ToggleDemo, index }) => {

    const [PopupModulesVisibitlity, setPopupModulesVisibitlity] = useState(false)
    // const [LessonData, setLessonData] = useState()

    return (
        <>
            <div className='topSectionLesson'>
                <h4>{`Урок ${index}.${lesson.order}:`}</h4>
                <div className={cl.LessonManageArea}>
                    <MySwitch
                        onClick={(e) => ToggleDemo()}
                        ld={LessonData?.isDemo}
                        switchDependence={LessonData?.isDemo === false}
                        SwitchOnLabel={'пробный'}
                        SwitchOffLabel={'платный'}
                        index={lesson.id} />
                    <button className={cn(cl.editLessonBtn, cl.rebaseLesson)} data-id={lesson.id}
                        onClick={(e) => setPopupModulesVisibitlity(true)}
                    >
                        <IoMdSwap />
                    </button>
                    <GreenButton
                        className={cl.ChangeBtn}
                        data-id={lesson.id}
                        text='сохранить'
                        disabled={_.isEqual(DefaultLessonData, LessonData)}
                        onClick={() => { updateDefaultLesson(LessonData); setEdit(false); }}>
                    </GreenButton>
                    <YellowButton
                        className={cl.ChangeBtn}
                        data-id={lesson.id}
                        text='Отменить'
                        onClick={() => { setEdit(false); setLessonData(DefaultLessonData); }}>
                    </YellowButton>
                </div>
            </div>
            <div className='nameLesson'>
                <MyInput className={cn(cl.LessonInput)} type='text' defaultValue={lesson.name}
                    onInput={(e) => { setLessonData({ ...LessonData, name: e.target.value }); }} />
            </div>
            <p>Описание</p>
            <div className='descriptionLesson'>
                <MyTextarea
                    minRows={3}
                    name='description'
                    type="text"
                    defaultValue={lesson.description}
                    onInput={(e) => { setLessonData({ ...LessonData, description: e.target.value }); }} />
            </div>
            <p>Длительность</p>
            <div className={cl.durationlesson}>
                <MyInput
                    type="number"
                    min={5}
                    value={LessonData?.duration ? String(Number(LessonData?.duration || 0).toFixed(0)) : ''}
                    onChange={(e) => { setLessonData({ ...LessonData, duration: String(e.target.value) }); }} name='duration' />
                <p><span>мин. или</span></p>
                <MyInput
                    type="number"
                    step='0.1'
                    value={LessonData?.duration ? (Number(LessonData?.duration || 0) / 60).toFixed(2) : ''}
                    min={0.08}
                    onChange={(e) => { setLessonData({ ...LessonData, duration: Number(e.target.value) * 60 }); }}
                    name='duration' />
                <p>час.</p>
                <div>
                    <p>Итог: {LessonData?.duration ?
                        LessonData?.duration < 60 ?
                            LessonData?.duration + ' мин.'
                            :
                            Math.floor(LessonData?.duration / 60) + ' ч. ' + ((LessonData?.duration) % 60).toFixed(0) + ' мин.'
                        : '0 ч. 0 мин.'}</p>
                </div>
            </div>
            <div className={cl.LessonLinks}>
                <Link to={"/lesson/edit/preview/" + cours_id + "/" + lesson.id}>
                    <YellowButton
                        data-id={lesson.id}
                        className={cl.ChangeBtn}>
                        <p>Предпросмотр</p>
                    </YellowButton>
                </Link>
                {/* <Link to={'/lesson/edit/' + cours_id + '/' + lesson.id}>
                    <GreenButton className={cl.ChangeBtn}
                        data-id={lesson.id}
                    >
                        <p>В конструктор</p>
                    </GreenButton>
                </Link> */}
            </div>

            <ModalSmall
                visible={PopupModulesVisibitlity}
                setVisible={() => setPopupModulesVisibitlity(false)}
                className={cl.LessonDeleteAlert}>
                <p>Выберите модуль</p>
                <ModuleChanger
                    AllModules={AllModules}
                    onSelect={newModule => setLessonModulePlace(newModule)}
                    currentModul={CurrentModulId} />
                {AllModules.map(module => (
                    <p key={module.id}>
                        {module.module_name}
                    </p>
                ))}
                <div
                    className={cl.LessonDeleteAlertBtns}>
                    <ButtonYes onClick={newModule => {setPopupModulesVisibitlity(false);
                        ChangeLessonModule(LessonModulePlace, lesson.id, CurrentModulId, DefaultLessonData);

                    }
                        }></ButtonYes>
                    <ButtonNo onClick={(e) => setPopupModulesVisibitlity(false)}></ButtonNo>
                </div>
            </ModalSmall>
        </>
    );
};

export default LessonEditData;