import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import cl from './LessonsListManager.module.css'
import cn from 'classnames';
import { api } from 'api'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { ModuleCardHeaderFull, RejectButton } from './components/styled/ModulesLessons.styled';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import { MainWrap } from 'components/UI/Layouts';
import EducationLayout from 'routes/UserComponents/MyLearn/share/EducationLayout/EducationLayout';
import ModuleWindow from './components/ModuleWindow/ModuleWindow';
import { DefaultWrapperNoPadding, EscapeLesson, Lesson, LessonsNavigate, LessonsNavigateWrap, LessonsSidebar, MoveBtn, NavBtns, NavigateLesson, NavigateModule, StudyingWrap } from 'routes/UserComponents/MyLearn/LessonShow/LearnPrev/LearmPrev.styled';
import { AcceptButton } from 'components/UI/Buttons';
import { CustomLink } from 'components/UI/Links';
import { MainPopup } from 'components/UI/Popups';
import { AiOutlineClear, AiOutlineSave } from 'react-icons/ai';
import { RiSlideshow3Fill } from 'react-icons/ri';
import NavigateBtns from 'routes/UserComponents/MyLearn/LessonShow/NavigateBtns/NavigateBtns';
import { observer } from 'mobx-react-lite';
import EditorStore from 'store/EditorStore';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { FaArrowLeft, FaArrowTurnUp } from "react-icons/fa6";
import { TextAndBackHead } from 'components/UI/Other';
import { Layout1200 } from 'routes/UserComponents/MyLearn/share/EducationLayout/styled/EducationLayoutStyled';

const CourseContentEditor = observer(() => {

    const { cid, mid, lid } = useParams()

    const [Modules, setModules] = useState([])
    const [currentModule, setCurrentModule] = useState(null)
    const [currentLesson, setCurrentLesson] = useState(null)
    const [showNewModuleForm, setShowNewModuleForm] = useState(null)
    const [course, setCourse] = useState({})

    const location = useLocation()
    const navigate = useNavigate()

    const [ActionError, setActionError] = useState(null)

    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'onChange' });

    const resetModuleAdd = () => {
        setShowNewModuleForm(false)
        setValue('newModuleName', null)
    }

    useEffect(() => {
        api.get(`/Module/Course/${cid}`)
            .then((result) => {
                setCourse(result.data.cource)
                let modules = result.data.results
                let max = modules.reduce((acc, curr) => acc.order > curr.order ? acc : curr);
                setModules(_.sortBy(modules, 'order'))
                if (location?.state?.lesson !== null) {
                    setCurrentModule(location?.state?.module)
                    setCurrentLesson(location?.state?.lesson)
                } else if (location?.state?.module !== null) {
                    setCurrentModule(location?.state?.module)
                    setCurrentLesson(null)
                }
                if (location?.state == null) {
                    setCurrentModule(null)
                    setCurrentLesson(null)
                }
                if (mid) {
                    setCurrentModule(mid)
                }
                if (lid) {
                    setCurrentLesson(lid)
                }
            }
            )
    }, [])


    const scrollNavigation = useRef(null);

    useEffect(() => {
        const scrollInRef = (ref, number) => {
            ref.current.scroll({
                top: [0, 1, 2, 3].indexOf(number) != -1 ? 0 :
                    (number - 5) * 40,
                behavior: 'smooth'
            });
        };
        var counter = 0
        Modules.map(module => {
            counter += 1
            if (currentLesson == null && module.id == currentModule) {
                scrollInRef(scrollNavigation, counter)
            } else {
                _.sortBy(module.lesson, 'order').map(lesson => {
                    if (lesson.id != currentLesson) {
                        counter += 1
                    } else {
                        scrollInRef(scrollNavigation, counter)
                    }
                })
            }
        })
    }, [currentLesson, currentModule])



    const deleteModule = async (id) => {
        try {
            const deleteModule = await api.delete("/Module/" + id)
            api.delete("/Module/" + id)
                .then((result) => {
                    setModules(Modules.filter(p => p.id != id))
                    setCurrentModule(null)
                    setCurrentLesson(null)
                })
        } catch (error) {
            console.log(error?.status)
            setActionError(`Невозможно удалить модуль пока в нем есть уроки`)
        }
    }

    const AddNewModule = (data) => {
        api.post("Module", { CourseId: cid, Name: data.newModuleName })
            .then((result) => {
                const { course, ...AdditionalLesson } = result.data
                setModules(prev => { return ([...prev, { ...AdditionalLesson, lessons: [] }]) })
                setValue('newModuleName', null)
                setShowNewModuleForm(false)
                // setCurrentModule()
            }
            )
    };

    const AddNewLesson = (newLesson, moduleId) => {
        api.post("/Lesson", {
            ...newLesson,
            Description: newLesson.description,
            LessonsDurationTypeId: 1,
            ModuleId: moduleId,
            Name: newLesson.label,
            isDemo: false
        })
            .then(result => {
                //добвавить добавление в список уроков
                var allModules = [...Modules]
                if (allModules.find(module => module.id == moduleId).lesson == null) {
                    allModules.find(module => module.id == moduleId).lesson = [result.data]
                } else {
                    allModules.find(module => module.id == moduleId).lesson.push(result.data)
                }
                setModules(allModules)
            })
    }

    const deleteLesson = (id, moduleId) => {
        api.delete(`/Lesson/` + id)
            .then(() => {
                var allModules = [...Modules]
                let ModuleIndex = allModules.findIndex(module => module.id == currentModule)
                allModules[ModuleIndex].lesson = (allModules[ModuleIndex].lesson).filter(lesson => lesson.id != currentLesson)
                setCurrentLesson(null)
                setModules(allModules)
            })
    }

    const rebaseLesson = (newModule, lessonId, oldModuleID, rebaseLessonData) => {
        let ModulesCopy = [...Modules]
        let tLesson
        ModulesCopy.map(mod => {
            if (mod.id == oldModuleID) {
                tLesson = mod.lesson.filter(lesson => lesson.id == lessonId)[0]
            }
        })

        //удаление из старого модуля
        ModulesCopy.map((mod, index) => {
            if (mod.id == oldModuleID) {
                mod.lesson = [...mod.lesson].filter(les => les.id !== lessonId)
            }
        })
        //добавление в новый модуль
        ModulesCopy.map(mod => {
            if (mod.id == newModule) {
                mod.lesson.push(rebaseLessonData)
            }
        })
        setCurrentLesson(null)
        setModules(ModulesCopy)
    }

    const UpdateModuleData = (data) => {
        api.put(`/Module/` + currentModule, { ...data })
            .then((result) => {
                var allModules = [...Modules]
                allModules.find(module => module.id == currentModule).name = data.Name
                setModules(allModules)
            })
    }

    return (
        <Layout1200 style={{ maxWidth: '100%' }}>

            <TextAndBackHead text={'Изменение курса'} />
            <div className={cl.content}>

                <Lesson>
                    {/* <EducationLayout> */}
                    <LessonsSidebar>
                        <LessonsNavigateWrap>
                            <LessonsNavigate ref={scrollNavigation} >
                                {Modules?.map((module, Mindex) => (
                                    <>
                                        <NavigateModule
                                            focus={(currentModule == module.id) && currentLesson == null}
                                            as={'span'}
                                            onClick={e => {
                                                {
                                                    resetModuleAdd();
                                                    setCurrentModule(module.id);
                                                    setCurrentLesson(null)
                                                    navigate(`module/${module.id}`)
                                                }
                                            }}
                                        >
                                            <p title={(Mindex + 1) + '. ' + module.name}>
                                                {(Mindex + 1) + '. ' + module.name}
                                            </p>
                                        </NavigateModule>
                                        {_.sortBy(module?.lesson, 'order').map((lesson, Lindex) => (
                                            <NavigateLesson as={'span'}
                                                key={'lesson' + Mindex + lesson.id}
                                                active={lesson.id == currentLesson}
                                                focus={(currentLesson == lesson.id).toString()}
                                                onClick={e => {
                                                    {
                                                        resetModuleAdd();
                                                        setCurrentModule(module.id);
                                                        setCurrentLesson(lesson.id)
                                                        navigate(`module/${module.id}/lesson/${lesson.id}`)
                                                    }
                                                }}
                                            >
                                                <p title={`${Mindex + 1}.${Lindex + 1} ${lesson.name}`}>
                                                    {`${Mindex + 1}.${Lindex + 1} ${lesson.name}`}
                                                </p>
                                            </NavigateLesson>
                                        ))}
                                    </>
                                ))}
                            </LessonsNavigate >
                        </LessonsNavigateWrap >

                        {currentLesson
                            ?
                            <NavigateBtns
                                id_course={cid}
                                wrap={<NavBtns />}
                                from={'constructor'}
                                buttons={{
                                    save:
                                        <MoveBtn
                                            type="button"
                                            title={'Сохранить'}
                                            onClick={() => EditorStore.setCommand('save')}
                                            disabled={!EditorStore.SaveMarker}
                                            className={
                                                EditorStore.SaveMarker
                                                    ? cn(cl.LessonManageBtn, cl.green)
                                                    : cn(cl.LessonManageBtn, cl.while)}
                                        >
                                            <AiOutlineSave />
                                        </MoveBtn>,
                                    clear:
                                        <MoveBtn
                                            className={cn(cl.LessonManageBtn, cl.clear)}
                                            type="button"
                                            title={'Очистить'}
                                            onClick={() => EditorStore.setCommand('clear')}
                                        >
                                            <AiOutlineClear />
                                        </MoveBtn>,
                                    preview:
                                        <Link className={cl.LessonManageBtn}
                                            title={'Предпросмотр'}
                                            to={"/lesson/edit/preview/" + cid + "/" + currentLesson}>
                                            <MoveBtn>
                                                <RiSlideshow3Fill />
                                            </MoveBtn>
                                        </Link>
                                }}
                            />
                            : null}
                        <AcceptButton title='Добавить модуль' onClick={e => setShowNewModuleForm(true)}></AcceptButton>
                        <EscapeLesson>
                            <CustomLink to={`/teachercabinete/course/${cid}/info`}>
                                Выйти с редактирования
                            </CustomLink>
                        </EscapeLesson>
                    </LessonsSidebar>
                    <ListVertical>
                        <div style={{ width: '-webkit-fill-available', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                            <h2 style={{ color: '#163C85', fontSize: '32px' }}>{course.label}</h2>
                            {/* <div style={{ display: 'flex', flexDirection: 'row', width: '-webkit-fill-available', justifyContent: 'space-between' }}>
                                <Link><FaArrowLeft />К модулю</Link>
                                <Link><FaArrowTurnUp />В курс</Link>
                            </div> */}
                        </div>
                        <DefaultWrapperNoPadding>
                            {showNewModuleForm ?
                                <ModuleCardHeaderFull>
                                    <InputValidateUseForm
                                        title={'Название нового модуля'}
                                        type={'text'}
                                        error={errors?.newModuleName}
                                        validateSet={register("newModuleName", {
                                            required: "Пожалуйста, введите название",
                                        })}
                                    />
                                    <AcceptButton onClick={handleSubmit(AddNewModule)}
                                        title={'Сохранить'} />
                                    <RejectButton onClick={e => setShowNewModuleForm(false)}
                                        title={'Отмена'} />
                                </ModuleCardHeaderFull>
                                :
                                <ModuleWindow
                                    deleteLesson={deleteLesson}
                                    AddNewLesson={AddNewLesson}
                                    rebaseLesson={rebaseLesson}
                                    deleteModule={deleteModule}
                                    UpdateModuleData={UpdateModuleData}
                                    ModuleData={Modules.find(elem => elem.id == currentModule) || null}
                                    CurrentModuleId={currentModule}
                                    CurrentLessonId={currentLesson}
                                    setLesson={setCurrentLesson}
                                    AllModules={Modules}
                                    course_id={cid}
                                />
                            }
                        </DefaultWrapperNoPadding>
                    </ListVertical>
                    {/* </EducationLayout> */}
                </Lesson>
                <MainPopup
                    isOpened={(ActionError != null) ? true : false}
                    onClose={e => setActionError(null)}
                    title={'Внимание'}
                    showHeader={true}
                >
                    {ActionError}
                    <RejectButton title={'Ок'} onClick={() => setActionError(null)} />
                </MainPopup>
            </div>
        </Layout1200 >
    );
});

export default CourseContentEditor;