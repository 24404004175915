import React from 'react';
import cl from './ListVertical.module.css'
import cn from 'classnames'
import { ListVerticalContainer } from './styled/ListVerticalContainer.styled'


const ListVertical = ({ children, viewMode, className, flex, style  }) => {

    return (
        <ListVerticalContainer className={cn(cl.listSection, className, cl[viewMode])} flex={flex} style = {style}>
            {children}
        </ListVerticalContainer>
    );
};

export default ListVertical;