import { api } from 'api';
import { BackButton, BlueButton, SimpleWhiteButton } from 'components/UI/Buttons';
import { ErrorField } from 'components/UI/Errors';
import { MainWrap } from 'components/UI/Layouts';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { CustomLink } from 'components/UI/Links';
import { MainPopup } from 'components/UI/Popups';
import { DefaultWrapper } from 'components/UI/Wrappers';
import React, { useState, useEffect, useRef }  from 'react';
import { useParams } from 'react-router-dom';

import _ from 'lodash';
import NavigateBtns from '../../LessonShow/NavigateBtns/NavigateBtns';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { CourseDescription, EscapeLesson, LessonsNavigate, LessonsSidebar, NavigateLesson, NavigateModule, MoveBtn, NavBtns, LessonsNavigateWrap, Lesson, StudyingWrap, } from '../../LessonShow/LearnPrev/LearmPrev.styled';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { ChangerWrap, ListVerticalNoGap, ProfileBackButton } from 'routes/UserComponents/ProfilePage/pages/styled/ProfileStyles';
import { ListVerticalContainer } from 'components/UI/Layouts/ListVertical/styled/ListVerticalContainer.styled';
import { BiBorderRadius } from 'react-icons/bi';
import { QuestionTextStyled } from 'routes/TeacherComponents/TestConstructor/components/TeacherQuestionsList/styled/QuestionTextStyled';
import { BlueButton2, DarkGreenButton } from '../MyLearnCourseLesson/styled/MyLearnCourseLessonStyled';
import { ContentHorizontalCenter, SurveyLobbyLabel, SurveyLobbyParamsWrap, SurveyLobbyTestparam, SurveyLobbyValue } from './SurveyLobbyStyled';
import SurveylobbyTestParametr from './Components/SurveylobbyTestParametr/SurveylobbyTestParametr';


const SurveyLobby = () => {
    const [TrysDate, setTrysDate] = useState(null)
    const [TestParam, setTestParam] = useState({})
    const { id_course, id_lesson, id_test } = useParams()
    const [tryExist, setTryExist] = useState(false)
    // /----------------
    const [courseInfo, setCourseInfo] = useState({})
    const [ShovPrev, setShovPrev] = useState(false)
    const [NavigateArrary, setNavigateArrary] = useState({})
    const [modulesAndLessons, setModulesAndLessons] = useState()
    const scrollNavigation = useRef(null);

    const CreateNewTry = () => {
        //Содание и переход к попытке
        api.post(`Survey/CreateSurveyTry/${id_test}`)
            .then((result) => {
                if (result.status == 200) {
                    localStorage.setItem('SurveyCurrentTry', result.data.id) // установка  id попытки в хранидище
                    window.location.assign(`/mylearn/courseshow/${1}/lesson/${1}/survey/${id_test}`); // переход нв попытку теста
                }
            })
    }

    const TryCheck = () => {
        const lstTry = localStorage.getItem('SurveyCurrentTry')
        if (lstTry == null || lstTry == undefined) {    
            //Если id попытки нет    
            CreateNewTry()
        } else {
            //Если id попытки есть    
            const getTryTime = async () => {
                const survey = await api.get(`Survey/Info/${id_test}`)
                var TryTime = null
                try {
                    TryTime = await api.post(`Survey/TimeSurveyTry/${lstTry}`, { id: lstTry })      
                  } catch (error) {
                    //Если в хранилище неправльный id, то создаём новыую попытку
                    CreateNewTry()
                  } 
                    const CurrentTryTime = Math.floor((Date.parse(TryTime.data)) / 1000)
                    const TestDuration = Math.floor(survey.data.survey.duration)
                    const CurrentTime = Math.floor(Date.parse(new Date()) / 1000)    
                    {
                        if (CurrentTryTime + TestDuration < CurrentTime) {
                            //попытка заврешилась по времени
                            CreateNewTry()    
                        } else {
                            //Попытка ещё не завершена
                            setTryExist(true)                          
                        }
                    }             
            }
            getTryTime()
        }
    }

    useEffect(() => {
        api.get(`Survey/Info/${id_test}`)
            .then(result => setTestParam(result.data.survey))//получение параметров теста
        api.get(`Survey/SurveyTry/${id_test}`)//получение попыток теста
            .then(result => {
                if (result.status == 200) 
                {    
                    localStorage.removeItem('SurveyCurrentTry')
                    const TTrysLength = result.data.length
                    if (TTrysLength !== 0 )
                        {
                            const lastTryStatus = result?.data[result.data.length - 1]?.ended
                            const lastId = result?.data[result?.data?.length - 1]?.id 
                            !lastTryStatus && localStorage.setItem('SurveyCurrentTry', lastId ) // не завершено   
                        }
                    setTrysDate(result.data) } })
    }, [id_test])

    const SortSubArrays = (array, param) => {
        return ([...array.map(subarray =>
            subarray = { ...subarray, [param]: _.sortBy(subarray[param], 'order') })])
    }

    useEffect(() => {
        // setLoader(true)
        // входящая сортировка подмассивов
        const getLessonContent = async () => {
            var lessonsList = (await api.get('/Module/Course/' + id_course))
            const OrderedLessonsList = SortSubArrays([...lessonsList.data.results], 'lesson')
            var response = await api.get("/Lesson/" + id_lesson)
            var result = { ...response.data[0], content: JSON.parse(response.data[0].content) }
            setNavigateArrary(await getNeighbours(OrderedLessonsList, id_lesson))
            setModulesAndLessons(OrderedLessonsList)
            setCourseInfo(lessonsList.data.cource)

            // ---------------------------------------------------
            const scrollInRef = (ref, number) => {
                ref.current.scroll({
                    top: [0, 1, 2, 3].indexOf(number) != -1 ? 0 :
                        (number - 5) * 40,
                    behavior: 'smooth'
                });
            };

            // Скроллинг при переходах
            var counter = 0
            lessonsList.data.results?.map(module => {
                counter += 1
                _.sortBy(module.lesson, 'order').map(lesson => {
                    if (lesson.id != id_lesson) {
                        counter += 1
                    } else {
                        scrollInRef(scrollNavigation, counter)
                    }
                })
            })
        }
        getLessonContent()
        // setLoader(false)
    }, [id_lesson])

    const getNeighbours = async (list, current) => {
        var idList = []
        list.map(module => {
            var moduleLessons = []
            module.lesson.map(lesson => moduleLessons.push(lesson.id))
            idList = [...idList, ...moduleLessons]
        })
        const cuurentIndex = idList.indexOf(Number(current))
        return {
            prev: idList[cuurentIndex - 1] || 'back',
            currentIndex: cuurentIndex,
            next: idList[cuurentIndex + 1] || 'end'
        }   
    }

    return (
        <MainWrap>
              <Lesson>
              <LessonsSidebar>
                                <CourseDescription>
                                    <h2>
                                        <p>Курс:</p>
                                        <p>{courseInfo.label}</p>
                                  </h2>
                                </CourseDescription>
                                <NavigateBtns
                                    id_course={id_course}
                                    NavigateArrary={NavigateArrary}
                                    wrap={<NavBtns />}
                                    from={'learn'}
                                    buttons={{
                                        back:
                                            <CustomLink>
                                                <MoveBtn><IoIosArrowRoundBack />Назад</MoveBtn>
                                            </CustomLink>,
                                        next:
                                            <CustomLink>
                                                <MoveBtn>Далее<IoIosArrowRoundForward /></MoveBtn>
                                            </CustomLink>
                                    }}
                                />
                                <LessonsNavigateWrap>
                                    <LessonsNavigate ref={scrollNavigation} >
                                        {modulesAndLessons?.map((module, Mindex) => (
                                            <ListVertical key = {'MandL_' + Mindex + module.id}>
                                                <NavigateModule
                                                    f={false}
                                                    to={`/mylearn/courseshow/${id_course}/lessonshow/${(module.lesson[0]?.id)}`}>
                                                    <p title={(Mindex + 1) + '. ' + module.name}>
                                                        {(Mindex + 1) + '. ' + module.name}
                                                    </p>
                                                </NavigateModule> 
                                                {module?.lesson?.map((lesson, Lindex) => (
                                                    <ListVerticalNoGap key = {'ModuleWrap_' + Mindex + '_' + Lindex + '_'  + module.id}>
                                                        <NavigateLesson to={`/mylearn/courseshow/${id_course}/lessonshow/${lesson.id}`}
                                                            key={'lesson_' + Mindex + lesson.id}
                                                            active={lesson.id == id_lesson}
                                                        >
                                                            <p title={`${Mindex + 1}.${Lindex + 1} ${lesson.name}`}>
                                                                {`${Mindex + 1}.${Lindex + 1} ${lesson.name}`}
                                                            </p>


                                                            <IoIosArrowRoundForward />
                                                        </NavigateLesson>
                                                        {lesson?.surveys?.length > 0
                                                            ?
                                                            <NavigateLesson
                                                                style={{ paddingLeft: '50px' }}
                                                                to = {`/mylearn/courseshow/${id_course}/lesson/${lesson.id}/surveyLobby/${lesson?.surveys[0]?.id}`}
                                                                // to={`/mylearn/courseshow/${id_course}/lessonshow/${lesson.id}`}
                                                                key={'lessoNav' + Mindex + lesson.id}
                                                            // active={lesson.id == id_lesson}
                                                            >
                                                                <p>Тест: {lesson?.surveys[0].label}</p>
                                                             
                                                                <IoIosArrowRoundForward />
                                                            </NavigateLesson>
                                                            :
                                                            null
                                                        }

                                                    </ListVerticalNoGap>
                                                ))}
                                            </ListVertical>
                                        )
                                        )}
                                    </LessonsNavigate>
                                </LessonsNavigateWrap>
                                <CustomLink to={`/mylearn/courseshow/${id_course}`}>
                                    <EscapeLesson>
                                        Выйти с обучения
                                    </EscapeLesson>
                                </CustomLink>
              </LessonsSidebar>
              
              {/* <ListVertical> */}
                <DefaultWrapper style = {{width : '100%',  padding :'32px', borderRadius: '20px'}}>
                <ListVertical>

                    <QuestionTextStyled>
                        {/* <div>
                            <p>Количество вопросов</p>
                            <span></span>
                        </div>  */}
                        <h4>Тест: {TestParam.label}</h4>                  
                        {/* Параметры теста: */}           
                        <ListVertical style = {{gap : '8px', width: 'fit-content' , alignItems: 'flex-start'}}>
                        <SurveyLobbyLabel style = {{fontSize  : '18px'}}>Длительность:</SurveyLobbyLabel>                            
                            <SurveyLobbyValue>  
                                {TestParam.duration ? 
                                (Math.floor((TestParam.duration / 60)) !== 0 ?Math.floor((TestParam.duration / 60))  : ''   ) 
                                  + ' ' +  (TestParam.duration >= 60 ? ' минут ' : '')  + ' ' +
                                    (TestParam.duration - (TestParam.duration - TestParam.duration % 60)).toFixed(0) + ' секунд ' 
                                : 'нет'}
                            </SurveyLobbyValue>
                        </ListVertical>
                    </QuestionTextStyled>
                     <QuestionTextStyled>   
                        <SurveyLobbyParamsWrap>
                            <SurveylobbyTestParametr label = {'Тип оценивания:' } value ={ TestParam.pointSystem ?'оценка'  :  'зачёт'}/>
                            <SurveylobbyTestParametr label = {'Количество попыток: '} value = {TestParam.tryCount}/>
                            <SurveylobbyTestParametr label = {'Количество раз пройдено:'} value ={TrysDate?.length ? TrysDate?.length : 0 }/>                      
                            <SurveylobbyTestParametr label = {'Переход по вопросам: ' } value ={ TestParam.questJump ? 'произвольный' : 'строгий'}/>                            
                        </SurveyLobbyParamsWrap>
                    </QuestionTextStyled>                
                    {
                        TrysDate?.length == TestParam.tryCount
                        ?
                        <>
                            <ErrorField error = {'Невозможно начать тест, закончились попытки'}/>
                            <BlueButton2  onClick = {e => setShovPrev(true)} > Предыдущие попытки</BlueButton2>
                        </>
                        :
                        TrysDate?.length  === TestParam.tryCount - 1
                            ?                              
                                <ContentHorizontalCenter>
                                    <BlueButton2  onClick = {e => setShovPrev(true)} > Предыдущие попытки</BlueButton2>
                                    <DarkGreenButton  onClick = {e => TryCheck()} >       
                                    Попытка {TrysDate?.length  + 1 } - Последняя
                                    </DarkGreenButton>
                                </ContentHorizontalCenter>                          
                            : 
                            TrysDate?.length  == 0
                            ?
                                <DarkGreenButton style = {{ padding : '16px 30px'} } onClick = {e => TryCheck()} >       
                                Начать тестирование
                                </DarkGreenButton>
                            : 
                                <ContentHorizontalCenter>
                                    <BlueButton2  onClick = {e => setShovPrev(true)} > Предыдущие попытки</BlueButton2>
                                    <DarkGreenButton  onClick = {e => TryCheck()} >       
                                     Начать попытку
                                    </DarkGreenButton>
                                </ContentHorizontalCenter>                               
                    } 
                  
                </ListVertical>
            </DefaultWrapper>
              {/* </ListVertical> */}
          
              </Lesson>
               
            <MainPopup
                isOpened={tryExist}
                onClose={e => setTryExist(false)}            
                title={'Внимание?'}
                showHeader={false}>
                    <p>У вас есть незавершённая попытка, хотите продолжить?</p>
                    <div>
                        <SimpleWhiteButton >
                            <CustomLink to={`/mylearn/courseshow/${1}/lesson/${1}/survey/${id_test}`}>
                                Продожить
                            </CustomLink>                            
                        </SimpleWhiteButton>
                        <SimpleWhiteButton                    
                         onClick={e => CreateNewTry(false)}
                         >
                            Начать заново
                        </SimpleWhiteButton>
                        <SimpleWhiteButton                     
                         onClick={e => setTryExist(false)}
                         >
                           Отмена
                        </SimpleWhiteButton>
                    </div>
            </MainPopup>

            <MainPopup
                isOpened={ShovPrev}
                onClose={e => setShovPrev(false)}            
                title={'Предыдущие попытки'}
                showHeader={true}>                 
                    <div>
                    {TrysDate == null ?
                        null
                        :
                        <>  
                        <ListVertical style = {{gap: '10px'}}>

                        <p>Попыток сделано: {TrysDate?.length}/  {TestParam.tryCount}</p>
                            {TrysDate?.length == 0  
                            ? <p>Попыток пока что не было</p>
                            :  null
                            }
                                {TrysDate?.map((tryDate, index) =>
                                    <ProfileBackButton style = { {width: '100%'}} key={tryDate.id}>
                                        <span>{index + 1}.</span>    
                                        <span >Оценка:
                                        {(() => {
                                                switch (tryDate.grade) {
                                                case 0:
                                                    return (' незачёт');
                                                case 1:
                                                    return (' зачёт');
                                                case 2:
                                                    return (' 2');
                                                case 3:
                                                    return (' 3');
                                                case 4:
                                                    return (' 4');
                                                case 5:
                                                    return (' 5');
                                                case 6:
                                                    return ('незачёт');
                                                default: return null;
                                                }
                                            })()}                                           
                                        </span>                                      
                                    </ProfileBackButton>
                                )}
                            </ListVertical>
                        </>
                    }
                    </div>
            </MainPopup>
        </MainWrap >
    );
};

export default SurveyLobby;




        // setTrysDate({
        //     trysCount: 7,
        //     maxTrysCount: 10,
        //     TestData: null,
        //     TrysList: [{ id: 1, result: 15 }, { id: 2, result: 19 }, { id: 3, result: 14 }, { id: 4, result: 13 }, { id: 5, result: 10 }, { id: 6, result: 2 }, { id: 7, result: 10 }]
        // })
        // setTestParam(
        //     {
        //         id: 1, //id
        //         lessonId: 14, //ID урока
        //         label: 'Знание общества ч1', //название теста
        //         duration: 140, //длительность
        //         tryCount: 5, //попытки
        //         random: false, //случайный порядок
        //         showAny: false, //показывать не все
        //         showAnyCount: 0,  //количество показываемых          
        //         showErros: false, //показ ошибок
        //         failure_3: 14, // количество ошибок на 3 или зачёт
        //         pointSystem: false, // зачёт или оценка
        //         grade_5: 18, //баллов на 5
        //         grade_4: 16, //балоов на 4
        //         questJump: true, //переход по вопросам
        //         solvedMove: false, //переход к решённым
        //         solvedEdit: false, //изменение решённых
        //         tesTimer: 3000
        //     }
        // )