import React from 'react';
import RemovePhoto from './RemovePhoto';
import ChangePhoto from './ChangePhoto';
import cl from '../../modules/ButtonsPhoto.module.css'

const ButtonsPhotoUser = () => {

    return (
        <>
            <ChangePhoto
                className={cl.changePhoto}
            />
            <RemovePhoto
                className={cl.removePhoto}
            />
        </>
    );
};

export default ButtonsPhotoUser;