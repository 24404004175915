import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import Image from '@editorjs/image'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import NestedList from '@editorjs/nested-list';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import AttachesTool from '@editorjs/attaches'
import { sendFile } from 'components/API/FileService/SendFile';
import { api } from 'api'
import { EmptyFile } from 'components/UI/Fillers'
import { host } from 'routes/globals'
const id = localStorage.getItem('userId')

export const EDITOR_JS_TOOLS = {
  table: Table,
  list: {
    class: NestedList,
    inlineToolbar: true,
    config: {
      defaultStyle: 'unordered'
    },
  },
  warning: {
    class: Warning,
    config: {
      titlePlaceholder: 'Заголовок',
      messagePlaceholder: 'Сообщение',
    },
    shortcut: 'CMD+SHIFT+H'
  },
  code: Code,
  image: {
    class: Image,
    config:
    {
      uploader: {
        // на загрузку на сервер
        async uploadByFile(file) {
          //создание файла отправка и получение его id
          const formData = new FormData();
          formData.append('file', file);
          var newFile = await api.post('/uploadfile', formData)
          return {
            success: 1,
            file: {
              url: host + '/api/GetFile/' + newFile.data.id,
            }
          };
        },
      },
    },
  },
  header: Header,
  quote: {
    class: Quote,
    inlineToolbar: true,
    config: {
      quotePlaceholder: 'Введите цитату',
      captionPlaceholder: 'Автор цитаты',
    },
    shortcut: 'CMD+SHIFT+O'
  },
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
  embed: {
    class: Embed,
  },
  anyTuneName: {
    class: AlignmentTuneTool,
    config: {
      class: AlignmentTuneTool,
      config: {
        default: "right",
        blocks: {
          header: 'center',
          list: 'right'
        }
      },
    },

    attaches: {
      class: AttachesTool,
      config:
      {      
        uploader: {
          // на загрузку на сервер
          async uploadByFile(file) {
            //создание файла отправка и получение его id
            const formData = new FormData();
            formData.append('file', file);
            var newFile = await api.post('/uploadfile', formData)
            return {
              success: 1,
              file: {
                url: host + '/api/GetFile/' + newFile.data.id,
              }
            };
          },
        },
        buttonText: "Выберите файл для загрузки"
      },
    }   
  }
}