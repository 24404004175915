import React, { useEffect, useState } from 'react';
import cl from './ListFilters.module.css'
import IntervalFilterWritable from './components/IntervalFilterWritable/IntervalFilterWritable';
import CheckboxFilter from './components/CheckboxFilter/CheckboxFilter';
import cn from 'classnames';
import { AcceptButton } from 'components/UI/Buttons';
import { observer } from 'mobx-react-lite';
import mobxState from 'routes/PublicComponents/Market/AllTeachers/store/mobxState';
import { ResetFilters } from '../../API/ResetFilters';
import { DefaultWrapper } from 'components/UI/Wrappers';
import _ from 'lodash'

const ListFilters = observer((props) => {
    const [LocalFiltersArray, setLocalFiltersArray] = useState([])
    useEffect(() => {
        if (props.FiltersArray) {
            !(_.isEqual(LocalFiltersArray, props.FiltersArray)) && setLocalFiltersArray(props.FiltersArray)
        }
    }, [props.FiltersArray])

    const Reset = async (page) => {
        var result = await ResetFilters(props.urlFilterByParams)
        mobxState.SetData({
            ElementsList: result.data,
            FiltersToFetch: mobxState.DefaultFilters
        })
    }
    return (
        <DefaultWrapper className={cn(cl.filtersWrap, cl.specialSize)} >
            <div className={cl.filtersLabel}>Фильтры</div>
            {LocalFiltersArray.map((filter) => (
                {
                    'Interval':
                        <div  key={String(filter['parametr'])} className={cl.filterWrap}>
                            <
                                IntervalFilterWritable
                               
                                Titile={filter.title}
                                FilterParametr={filter.parametr}
                                urlFilterByParams={props.urlFilterByParams}
                            />
                        </div>,
                    'Checkbox':
                        <div   key={String(filter['parametr'])} className={cl.filterWrap}>
                            <CheckboxFilter
                               
                                Titile={filter.title}
                                FilterParametr={filter.parametr}
                                urlFilterByParams={props.urlFilterByParams}
                            />
                        </div>,
                }[filter.type]
            ))}
            <AcceptButton className={cl.blue} title='Сбросить' onClick={e => Reset()} >Сбросить</AcceptButton>
        </DefaultWrapper>
    );
});
export default ListFilters;