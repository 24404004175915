import React, { useEffect, useState } from 'react';
import { ImgDefaultStyle } from './ImgDefault.styled';
import { EmptyAvatar, EmptyFile } from 'components/UI/Fillers';
import { api } from 'api';
import { host } from 'routes/globals';

const ImgDefault = ({ src, imgId, ...props }) => {
    const [Img, setImg] = useState()

    useEffect(() => {
        src ? setImg(src) :
            imgId !== null
                ? setImg(`${host}/api/getFile/${imgId}`)
                : setImg(EmptyFile)
    }, [src, imgId])

    return (
        <ImgDefaultStyle {...props} >
            <img src={Img} alt="" />
        </ImgDefaultStyle>
    );
};

export default ImgDefault;