const EmptyLesson =
{
    time: 1635603431943,
    blocks: [
        {
            id: "sheNwCUP5A",
            type: "header",
            data: {
                text: "",
            }
        },
    ]
}
export default EmptyLesson