import React from "react";
import TextareaAutosize from 'react-textarea-autosize';
import cn from "classnames";

import cl from './MyTextarea.module.css'

const MyTextarea = ({ className, validateSet, error ,...props }) => {

    return (
        <>
            <TextareaAutosize
                className={cn(cl.mytextarea, className)}
                {...validateSet}
                {...props}
            />
           {error && <div className={cl.error}> {error.message || "Пожалуйста заполните поле"}</div>}
        </>

    );
};

export default MyTextarea;