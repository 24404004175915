import React from 'react';
import cl from './CustomNestedList.module.css'

const NestedList = ({ data, style, classNameNames, config }) => {


    const RecursListRender = (list, indexLevel) => {
        var indexLevel = indexLevel ?? 1
        return (
            <ol
                className={
                    data.style == 'ordered'
                        ?
                        "cdx-nested-list cdx-nested-list__item-children cdx-nested-list--ordered"
                        :
                        "cdx-nested-list cdx-nested-list__item-children cdx-nested-list--unordered"
                }
            >
                {list?.map((listEl, index) => (
                    <li key={indexLevel + index} className="cdx-nested-list__item">
                        <div className="cdx-nested-list__item-body">
                            <div className="cdx-nested-list__item-content" >
                                {listEl.content.replace(/&nbsp;/g, " ") + ' '}
                            </div>
                            {listEl.items !== null ?
                                RecursListRender(listEl.items, indexLevel + 1)
                                : null
                            }
                        </div>
                    </li>
                ))
                }
            </ol>
        )
    }
    const ListEls = RecursListRender(data.items)
    return (
        <div className={cl.ce_block}>
            <div className = ''>
                {ListEls}
            </div>
        </div>
    );
};

export default NestedList;

