import cl from './CodeBoxOutput.module.css'
import cn from 'classnames';
const CodeBoxOutput = ({ data, style, classNames, config }) => {

  return (
    data.code
      ?
      <div className="codex-editor__redactor">
        <div className="ce-block ce-block--focused">
          <div className="cdx-block ce-code">
            <textarea defaultValue =  {data.code} disabled={true} className={cn(cl.textareaOutput, "ce-code__textarea")} placeholder="Enter a code">            
            </textarea>
          </div>
        </div>

      </div>
      :
      null
  );
};

export default CodeBoxOutput;

