import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { CustomLink } from 'components/UI/Links';
import { DefaultWrapper } from 'components/UI/Wrappers';
import React from 'react';
import { useParams } from 'react-router-dom';
import { DefaultWrapper2 } from 'routes/UserComponents/MyLearn/LessonShow/LearnPrev/LearmPrev.styled';
import { Layout1200 } from 'routes/UserComponents/MyLearn/share/EducationLayout/styled/EducationLayoutStyled';
import { DarkGreenButton } from '../../../MyLearnCourseLesson/styled/MyLearnCourseLessonStyled';
import { QuestionTextStyled } from 'routes/TeacherComponents/TestConstructor/components/TeacherQuestionsList/styled/QuestionTextStyled';
import SurveylobbyTestParametr from '../../../SurveyLobby/Components/SurveylobbyTestParametr/SurveylobbyTestParametr';

const TestResultShort = ({ TestResults }) => {
    const { id_test } = useParams()
    return (
        <Layout1200>
            <DefaultWrapper2 padding='40px' gap={'20px'}>
                <QuestionTextStyled style={{ justifyContent: 'center', maxWidth: '800px' }}>
                    {/* <ContentHorizontal > */}

                    <>
                        <SurveylobbyTestParametr
                            label={'Оценка :'}
                            value={TestResults?.pointSystem
                                ? TestResults?.countOfRight >= TestResults?.grade_5 ? 5 :
                                    TestResults?.countOfRight >= TestResults?.grade_4 ? 4 :
                                        TestResults?.countOfRight >= TestResults?.failure_3 ? 3 : 2
                                : TestResults?.countOfRight >= TestResults?.failure_3 ? 'зачёт' : 'незачёт'
                            } />
                    </>
                    {/* </ContentHorizontal> */}
                </QuestionTextStyled>

                <CustomLink to={`/mylearn/courseshow/1/lesson/1/surveyLobby/${id_test}`}>
                    <DarkGreenButton style={{ maxWidth: 'fit-content' }}>
                        На главную теста
                    </DarkGreenButton>
                </CustomLink>
            </DefaultWrapper2>
        </Layout1200>
    );
};
export default TestResultShort;