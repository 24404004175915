import React from 'react';
import cl from './ProfilePage.module.css'
import cn from 'classnames';
import { Outlet } from 'react-router-dom';
import { MainWrap } from 'components/UI/Layouts';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { DefaultWrapper, NavSidebarElem, NavigateSection } from 'components/UI/Wrappers';
import { BackButton } from 'components/UI/Buttons';
import { CustomLink } from 'components/UI/Links';

const ProfilePage = ({ headerTitle }) => {

    return (
        <MainWrap headerTitle={headerTitle}>
            <ContentHorizontal className={cl.frame}>
                <ListVertical flex={1}>
                    <NavigateSection>
                        <NavSidebarElem back={true}>
                            <BackButton />
                        </NavSidebarElem>
                        <NavSidebarElem to={'main'}>
                            <CustomLink>
                                Профиль
                            </CustomLink>
                        </NavSidebarElem>
                        <NavSidebarElem to={'payments'}>
                            <CustomLink>
                                Платежи
                            </CustomLink>
                        </NavSidebarElem>
                        {/* <NavSidebarElem to={'password'}>
                            <CustomLink>
                                Смена пароля
                            </CustomLink>
                        </NavSidebarElem>
                        <NavSidebarElem to={'mail'}>
                            <CustomLink>
                                Смена почты
                            </CustomLink>
                        </NavSidebarElem> */}
                        <NavSidebarElem to={'security'}>
                            <CustomLink>
                                Безопасность
                            </CustomLink>
                        </NavSidebarElem>
                        {/* <NavSidebarElem to={'teacher'}>
                            <CustomLink>
                                Профиль автора
                            </CustomLink>
                        </NavSidebarElem> */}
                    </NavigateSection>
                </ListVertical>
                <ListVertical flex={5}>
                    <Outlet />
                </ListVertical>
                {/* <ListVertical> */}
                {/* </ListVertical> */}
            </ContentHorizontal>
        </MainWrap>
    );
};

export default ProfilePage;