import React, { useEffect, useState } from 'react';
import MyInput from '../MyInput/MyInput';
import MyTextarea from '../MyTextarea/MyTextarea';
import MyInputDate from '../MyInput/MyInputDate';


import cl from './ChangeInput.module.css'

const ChangeInput = (props) => {

    const [changeInput, setChangeInput] = useState(true);

    const handlerInput = (e) => {
        setChangeInput(true)
        props.updateInput(e)
    }

    return (
        <>
            {changeInput
                ? <p className={cl.changeText} onClick={() => setChangeInput(false)}>{props.defaultValue}</p>
                : props.typeInput === 'input'
                    ? <MyInput defaultValue={props.defaultValue} autoFocus onBlur={e => handlerInput(e)} name={props.name} />
                    : props.typeInput === 'textarea'
                        ? <MyTextarea defaultValue={props.defaultValue} autoFocus onBlur={e => handlerInput(e)} name={props.name} />
                        : props.typeInput === 'inputdate'
                            ? <MyInputDate defaultValue={props.defaultValue} autoFocus onBlur={e => handlerInput(e)} name={props.name} />
                            : ''
            }
        </>
    );
};

ChangeInput.defaultProps = { typeInput: 'input' }

export default ChangeInput;