import React from 'react';
import { MyInput } from 'components/UI/Inputs';
import cl from './InputArea.module.css'

const InputArea = ({ id, type, title, onChange, value, palceholder, children, props }) => {

    return (
        <div className={cl.inputArea}>
            <label htmlFor={id}>{title}</label>
            {
                children ||
                <MyInput
                    onChange={onChange}
                    type={type}
                    id={id}
                    value={value}
                    placeholder={palceholder}
                    {...props}
                />
            }
        </div>
    );
};

export default InputArea;