import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import HocConfirmRoute from './HocConfirmRoute';

const HocDirectRoute = () => {

    const location = useLocation();
    return (
        location.pathname.indexOf('/confirmation') === 0 ?
            <HocConfirmRoute  />
            :
            <Outlet />
    );
};

export default HocDirectRoute;