
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import './coursesEdit.css'
import cl from './coursesEdit.module.css'
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { BackButton, SimpleWhiteButton } from 'components/UI/Buttons';
import { MainWrap } from 'components/UI/Layouts';
import { CustomLink } from 'components/UI/Links';
import { DefaultWrapper, NavigateSection } from 'components/UI/Wrappers';
import EducationLayout from 'routes/UserComponents/MyLearn/share/EducationLayout/EducationLayout';
import { Layout1200 } from 'routes/UserComponents/MyLearn/share/EducationLayout/styled/EducationLayoutStyled';
import { TextAndBackHead } from 'components/UI/Other';

const CourseEdit = observer(({ headerTitle }) => {
    const { cid, ...params } = useParams()
    const [CurrentTab, setCurrentTab] = useState()

    useEffect(() => {
        var ost = params['*']
        switch (ost) {
            case 'info': return setCurrentTab('Общая информация');
            case 'lessons': return setCurrentTab('Уроки');
            case 'streams': return setCurrentTab('потоки');
            case null: return setCurrentTab('');
            default: return setCurrentTab('');
        }
    }, [params])

    return (
        <>
            <MainWrap className={cl.MainMoMargin} headerTitle={headerTitle + ': ' + CurrentTab}>
                <div className={cn(cl.LessonManageTabsBtns)}>

                    <Outlet />

                </div>
            </MainWrap>
        </>
    )
})
export default CourseEdit