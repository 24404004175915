import { makeAutoObservable } from "mobx"

class PublickCoursesMenagment {
    OpendCourses = {}
    addOpendCourses(id, tabName) {
       this.OpendCourses[id] = tabName 
    }
    
    clearOpendCourses() {
        this.OpendCourses = []
    }
    
    constructor() {
        makeAutoObservable(this)
    }
}
export default new PublickCoursesMenagment();