import React, { useState } from 'react';
import PublicLessons from './components/PublicLessons';
import cl from './PuplicMoadulesAndLessons.module.css'
import cn from 'classnames';
import _ from 'lodash';
import { IoChevronForwardOutline } from "react-icons/io5";

const PuplicMoadulesAndLessons = ({ Modul, index }) => {
    const [ShowLessons, SetShowLessons] = useState(false)

    return (
        <div className={cl.publicModul}>
            <div onClick={() => { SetShowLessons(!ShowLessons) }} className={cl.LessonsListLabel}>
                <span className={cl.ModuleTitle}> Модуль {index + 1}: {Modul.name}</span>
                {ShowLessons
                    ? <IoChevronForwardOutline className={cl.arrowIcon} />
                    : <IoChevronForwardOutline className={cn(cl.arrowIconcn, cl.down)} />
                }
            </div>

            {ShowLessons && <div className={cl.LessonsList}>
                {[...(Modul.lesson)].length >= 1
                    ?
                    <div className={cl.PublicLessonsList}>
                        {(_.sortBy(Modul.lesson, 'order')).map((locklesson, Lindex) => (
                            <PublicLessons
                                Lesson={locklesson}
                                index={Lindex}
                                key={locklesson.id}
                                cours_id={Modul.courseId}
                            />
                        ))
                        }
                    </div>
                    :
                    null
                }

            </div>}




        </div>



    );
};

export default PuplicMoadulesAndLessons;