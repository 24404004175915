import React from 'react';
import { MyInputMask, MyTextarea } from 'components/UI/Inputs';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import { InputValidateRadio } from 'components/UI/Inputs/HookForm/InputValidateRadio';
import InputArea from 'components/UI/Inputs/InputArea';
import cl from './PersonalInputs.module.css'
const PersonalInput = ({ title, inputType = 'input', type, checked, validateSet, error, ...props }) => {
    return (
        <div className='input_personalData'>
            <p>{title}</p>

            {inputType == 'input' &&
                <InputValidateUseForm
                    error={error}
                    type={type}
                    validateSet={validateSet}
                    {...props}
                />
            }
            {inputType == 'mask' &&
                <InputValidateUseForm
                    error={error}
                    type={'text'}
                >
                    <MyInputMask
                        {...props}
                        validateSet={validateSet}
                        type={'text'}
                        defaultValue={''}

                    />
                </InputValidateUseForm>
            }
            {inputType == 'date' &&
                <InputValidateUseForm
                    {...props}
                    error={error}
                    validateSet={validateSet}
                    type={'date'}
                />
            }
            {inputType == 'number' &&
                <InputValidateUseForm
                    error={error}
                    validateSet={validateSet}
                    {...props}
                />
            }
            {inputType == 'gender' &&
                <InputValidateUseForm error={error}>
                    <InputValidateRadio
                        RadioList={[
                            { id: 1, value: "М" },
                            { id: 2, value: "Ж" }
                        ]}
                        checked={checked}
                        error={error}
                        InputClass={cl.sexInput}
                        RadioClass={cl.radio}
                        {...props}
                        validateSet={validateSet}
                    />
                </InputValidateUseForm>
            }
            {inputType == 'textarea' &&
                <InputArea>
                    <MyTextarea
                        minRows={5}
                        maxRows={7}
                        error={error}
                        validateSet={validateSet}
                        {...props}
                    />
                </InputArea>
            }
            {inputType == null && null}

        </div >
    );
};

export default PersonalInput;