import { api } from "./api.config";

export const newLogIn = (fields) => {
    return api.post("user/login", fields)
}

export const refreshToken = () => {
    return api.get("user/refreshjwt");
}

export const logout = () => {
    return api.get("user/logout")
}

export const getUserData = () => {
    return api.get('user/0')
}
