import React from 'react';
import cl from './loader.module.css'
import cn from 'classnames'

const Loader = ({ className }) => {
    return (
        <div className={cn(cl.loader, className)} id={'loader'}>
        </div>
    );
};

export default Loader;