import React, { cloneElement } from 'react';
import cl from './NavSidebarElem.module.css'
import cn from 'classnames'
import { useMatch, useResolvedPath } from 'react-router-dom';

const NavSidebarElem = ({ back, to, children, proactive }) => {
    const resolved = useResolvedPath(to)
    const match = useMatch({
        path: resolved.pathname,
        end: to?.lenght === 1,
    })
    const coincidence = window.location.href.indexOf(to) > -1
    return (
        <div className={cn(cl.NavSidebarElem, back ? cl.orange : null, !back && coincidence ? cl.active : null)}>
            {
                to ?
                    cloneElement(children, {
                        to: to
                    })
                    :
                    children
            }
        </div>
    );
};

export default NavSidebarElem;