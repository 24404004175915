import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Output from 'editorjs-react-renderer';
import { ShowClasses } from './../ShowConfigs/DefaultConfig';
import renderers from './../CustomRerenders/CustomRerenders'
import EmptyLesson from './../EmptyLesson';
import { api } from 'api';
import { LoaderBlueFullView } from 'components/UI/Loaders';
import { MainWrap } from 'components/UI/Layouts';
import NavigateBtns from '../NavigateBtns/NavigateBtns';
import cl from './../LessonShow.module.css'
import { CourseDescription, EscapeLesson, Lesson, LessonHeader, LessonsSidebar, NavBtns, StudyingWrap } from '../LearnPrev/LearmPrev.styled';
import { CustomLink } from 'components/UI/Links';

const EditPrev = () => {
    const { id_course, id_lesson } = useParams()
    const [lessonData, setLessonData] = useState(null)
    const [LessonContent, setLessonContent] = useState({})
    const [Loader, setLoader] = useState(false)
    const [NavigateArrary, setNavigateArrary] = useState({})
    const [courseName, setCourseName] = useState()

    useEffect(() => {
        setLoader(true)
        const getLessonContent = async () => {
            var lessonsList = (await api.get('/Module/Course/' + id_course))
            var response = await api.get("/Lesson/" + id_lesson)
            var result = { ...response.data[0], content: JSON.parse(response.data[0].content) }
            setLessonData(await result)
            setLessonContent(await result?.content || {})
            setNavigateArrary(await getNeighbours(lessonsList.data.results, id_lesson))
            setCourseName(lessonsList.data.cource.label)
        }
        getLessonContent()
        setLoader(false)
    }, [id_lesson])

    const getNeighbours = async (list, current) => {
        var idList = []
        list.map(module => {
            var moduleLessons = []
            module.lesson.map(lesson => moduleLessons.push(lesson.id))
            idList = [...idList, ...moduleLessons]
        })
        const cuurentIndex = idList.indexOf(Number(current))
        return {
            prev: idList[cuurentIndex - 1] || 'back',
            currentIndex: cuurentIndex,
            next: idList[cuurentIndex + 1] || 'end'
        }
    }
    return (
        <MainWrap >
        
            <Lesson>
                <LessonsSidebar>
                    <CourseDescription>
                        <h2>{courseName}</h2>
                        <p> предпросмотр урока </p>
                    </CourseDescription>
                    {/* <NavigateBtns/> */}                      
                    {/* <CustomLink  to={`/lesson/edit/${id_course}/${id_lesson}`}>
                        <EscapeLesson>
                            В констуктор 
                        </EscapeLesson>
                    </CustomLink> */}
                    <CustomLink state={{ module: lessonData?.moduleId || null, lesson: id_lesson }} to={`/courses/edit/${id_course}/lessons`}>
                        <EscapeLesson>
                            Выйти с предпросмотра
                        </EscapeLesson>
                    </CustomLink>
                </LessonsSidebar>
                <StudyingWrap>
                    <LessonHeader>
                        <h2> {lessonData?.name} </h2>
                    </LessonHeader>
                    {
                        !Loader
                            ?
                            <div className={cl.OutputContent} >

                                <Output
                                    classNames={ShowClasses}
                                    data={(JSON.stringify(LessonContent) === '{}')
                                        ?
                                        EmptyLesson
                                        :
                                        lessonData?.content
                                    }
                                    renderers={renderers}
                                />
                            </div>

                            :
                            <LoaderBlueFullView />
                    }
                </StudyingWrap >
            </Lesson>
        </MainWrap>
    )
}
export default EditPrev