
import { BlueButton, RedButton } from "components/UI/Buttons";
import SimpleImg from "components/UI/Images/SimpleImg/SimpleImg";
import ContentHorizontal from "components/UI/Layouts/ContentHorizontal";
import ListVertical from "components/UI/Layouts/ListVertical";
import { IoChevronForward } from "react-icons/io5";
import { DarkGreenButton } from "routes/UserComponents/MyLearn/courseShow/MyLearnCourseLesson/styled/MyLearnCourseLessonStyled";
import { SpacedApartWrap } from "routes/UserComponents/ProfilePage/pages/styled/ProfileStyles";
import styled from "styled-components";



export const UnderlineDiv = styled.div`
    padding-bottom:30px;
    border-bottom:  1px solid #DADADA ;   
`
export const QuestionTextStyled = styled(SpacedApartWrap)`
    padding-bottom:  ${props => (props.ShowUnderLine == null || props.ShowUnderLine == false) ? '30px' : null}   ;
    border-bottom: ${props => (props.ShowUnderLine == null || props.ShowUnderLine == false) ? '1px solid #DADADA' : null}   ;
    font-size: 24px;
    font-weight: 600;

`
export const QuestionImgStyled = styled(SimpleImg)`
    padding-bottom:30px;
    border-bottom:  1px solid #DADADA ; 
`
export const RedQuestionBtn = styled(RedButton)`
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    padding: 18px ;  
    line-height: 100%;

`

export const BlueQuestionButton = styled(BlueButton)`
    line-height: 100%;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    padding: 18px;
    background-color: #E8F1FF;
    color: var(--base-blue-color) ;
`
export const DarkGreenQuestionButton = styled(DarkGreenButton)`
    line-height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 18px;
    width: 100%;

`

export const RedAnswerBtn = styled(RedButton)`
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 10px 25px ;
    width: fit-content;
    line-height: 100%;
    border-radius: 14px;
    font-weight: 600;
`

export const AnswerOptionList = styled(ListVertical)`
   
`

export const AnswerOptionInputStyled = styled(ContentHorizontal)`
    border-radius: 1px;
    padding: 20px;
    /* cursor: pointer; */
    transition: 0.3s linear ;
    /* &:hover { 
        background-color: #E8F1FF
    } */
`

