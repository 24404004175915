import cl from '../CustomRerenders/CustomRerenders.module.css'
const ShowClasses = {
    paragraph: cl.CustomParagraph,   
    list: {
        container: cl.CustomListContainer,
        listItem: cl.CustomListItem    
    },    
    header: {     
        h3: cl.CustomHeader3
    },
    delimiter: {
        container: cl.CustomDelimiter
    }
}

export {ShowClasses}