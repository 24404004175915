import React from 'react';
import { MainWrap } from 'components/UI/Layouts';
import { observer } from 'mobx-react-lite';
import cl from './styleMyLearn.module.css'
import cn from 'classnames';
import { Outlet } from 'react-router-dom';
import EducationLayout from './share/EducationLayout/EducationLayout';
import { BackButton } from 'components/UI/Buttons';
import { CustomLink } from 'components/UI/Links';
import { DefaultWrapper, NavSidebarElem, NavigateSection } from 'components/UI/Wrappers';
import ListVertical from 'components/UI/Layouts/ListVertical';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import pageState from 'store/pageState';

const MyLearn = observer(({ headerTitle }) => {

    const defaultWay = '/mylearn/requests'
    return (
        <>
            <MainWrap headerTitle={headerTitle}>
                <EducationLayout>
                    <ListVertical>
                        {/* {pageState.title ?
                            <p className={cl.pageTitle}>
                                {pageState.title}
                            </p>
                            :
                            <p className={cl.pageTitle}>
                                пусто
                            </p>
                        } */}
                        <ContentHorizontal className={cl.PageContent}>
                            <NavigateSection>
                                <NavSidebarElem back={true}>
                                    <BackButton/>
                                </NavSidebarElem>
                                <NavSidebarElem to="/mylearn/mycourses">
                                    <CustomLink  >Курсы</CustomLink>
                                </NavSidebarElem>                               
                            </NavigateSection>
                            <Outlet></Outlet>
                        </ContentHorizontal>
                    </ListVertical>
                </EducationLayout>
            </MainWrap >
        </>
    );
});

export default MyLearn;

