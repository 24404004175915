import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import cl from './PageConfirmation.module.css'
import { api, getUserData } from 'api';
import useDeviceInfomation from 'modules/PageLogin/hooks';
import { observer } from 'mobx-react-lite';
import userStore from 'store/userStore';
import { MainWrap } from 'components/UI/Layouts';
import { DefaultWrapper } from 'components/UI/Wrappers';
import { Loader } from 'components/UI/Loaders';
import { TbSquareRoundedCheckFilled } from "react-icons/tb";

const Verification = observer(() => {

    const [isResult, setIsResult] = useState(false);
    const [hasConfirm, setHasConfirm] = useState(false);
    // const [url, setUrl] = useState()

    // const { url } = useParams();
    const { pathname } = useLocation();
    const device = useDeviceInfomation();
    const navigate = useNavigate()
    const goHome = () => navigate('/home')
    const goLogin = (param) => {
        navigate('/login', { state: param })
    }

    var timer = null

    //Получение случайной строки для подтверждения регистрации
    var confirmURL = pathname.split('/')[pathname.indexOf('confirmation') + 1]

    useEffect(() => {
        checkVerification()
        return () => clearTimeout(timer)
    }, [])

    const checkVerification = () => {
        api.post(`user/confirmation/${confirmURL}`, {
            device_type_id: device.type,
            platform: '123',
            geo: device.geo == {} ? "" : JSON.stringify(device.geo),
            system: device.system
        })
            .then(result => {
                localStorage.setItem('token', result.data.token)
                getUserData()
                    .then((result) => {
                        localStorage.setItem('userId', result.data.id)
                        userStore.changeUser(result.data);
                        userStore.signIn()
                        setIsResult(true)
                        timer = setTimeout(goHome, 5000)
                        userStore.getUserPhoto(result)
                    })
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    setHasConfirm(true)
                    setIsResult(true)
                    return
                }
                if (error.response.status === 429) {
                    return goLogin({ error: { code: 1, message: 'Ссылка устарела, войти ещё раз и отправьте ссылку повторно' } })
                }
                return goLogin({ error: { code: 1, message: 'Неизвестная ошибка' } });
            })
    }

    const Redirect = () => {
        if (!hasConfirm && isResult) {
            return goHome()
        }
        if (hasConfirm && isResult) {
            return goLogin()
        }
    }


    const RedirectVerification = () => {
        return (
            <>
                <TbSquareRoundedCheckFilled size={'42px'} color={hasConfirm ? '#df9f09' : '#39AA95'} />
                {
                    !hasConfirm && isResult
                        ? <p>Вы успешно прошли регистрацию и будете перенаправлены на домашнюю страницу через 5 секунд</p>
                        : <p>Ваша почта уже подтверждена. Перейдите на страницу входа</p>
                }
                <button className={cl.redirect} onClick={() => Redirect()}>{!hasConfirm && isResult ? 'Перейти на главную' : 'Перейти'}</button>
            </>
        )
    }

    const AwaitVerification = () => {
        return (
            <>
                <div>
                    <Loader className={cl.loader} />
                </div>
                <p>Ожидайте...</p>
            </>
        )
    }

    return (
        <MainWrap className={cl.main}>
            <DefaultWrapper className={cl.wrapper}>
                {
                    isResult
                        ? <RedirectVerification />
                        : <AwaitVerification />
                }
            </DefaultWrapper>
        </MainWrap>

    );
});

export default Verification;