import React, { useState } from 'react';
import cl from './ButtonDropDown.module.css'
import cn from 'classnames';
import { ModalSmall } from 'components/UI/Modals';

const ButtonDropDown = ({ title, BtnText, disabled, className, BtnClass, DropDownClass, props, children }) => {
    const [ShowDrop, setShowDrop] = useState(false)
    return (
        <>
            <div className={cn(cl.ButtonWrap, className)}>
                <button
                    title={title}
                    className={cn(cl.Btn, BtnClass)}  {...props}
                    onClick={e => { e.stopPropagation(); setShowDrop(!ShowDrop) }}
                    disabled={disabled}>
                    {BtnText || title}
                </button>
                <ModalSmall
                    visible={ShowDrop}
                    setVisible={() => setShowDrop(false)}
                    className={cn(cl.DropDown, DropDownClass)}>
                    <>
                        {children}
                    </>

                </ModalSmall>
            </div>


        </>


    );
};

export default ButtonDropDown;