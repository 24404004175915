import React, { useState, useRef } from 'react';
import { MyInput } from 'components/UI/Inputs';
import cl from './SearchFilter.module.css'
import cn from 'classnames'
import { observer } from 'mobx-react-lite';
import mobxState from 'routes/PublicComponents/Market/AllTeachers/store/mobxState';
import useDebouncedFunction from 'components/hooks/useDebouncedFunction';
import { AcceptButton } from 'components/UI/Buttons';
import useGetElemsByFilters from '../../API/useGetElemsByFilters';
import { DefaultWrapper } from 'components/UI/Wrappers';

const CoursesSearch = observer(({ urlFilterByParams }) => {
    const [SearchValue, setSearchValue] = useState('')
    const SearchString = useRef(null)
    const getData = useGetElemsByFilters(urlFilterByParams)

    const valueSearching = async (value) => {
        if (value !== '') {

            var result = await getData({ page: 0, SearchValue: '%' + value + '%' })
            mobxState.setTotalCountOfElements(result.headers['x-total-count'])
            mobxState.SetData({ ElementsList: result.data, setSearchValue: '%' + value + '%' })

        } else {
            const fetchData = async () => {
                var result = await getData({ page: 0, SearchValue: '%' + value + '%' })
                mobxState.setTotalCountOfElements(result.headers['x-total-count'])
                mobxState.SetData({
                    ElementsList: result.data,
                    setSearchValue: '%' + value + '%'
                })
            }
            fetchData()
        }
    }

    const debouncedValueSearch = useDebouncedFunction(newValue => valueSearching(newValue), 3000);
    const handleChange = (event, newValue) => {
        debouncedValueSearch(newValue);
    };

    return (
        <div className={cl.CoursesSearchInput}  >
            <DefaultWrapper>
                <MyInput refInput={SearchString} onChange={(e) => handleChange(e, e.target.value)} type="text" />
            </DefaultWrapper>
            <DefaultWrapper>
                <AcceptButton title={'сбросить'} onClick={e => { setSearchValue('') }} />
            </DefaultWrapper>
        </div>
    );
})

export default CoursesSearch;