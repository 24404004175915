import React from 'react';
import { AccountAvatar } from 'components/UI/Avatars';
import ButtonsPhotoUser from './ButtonsPhotoUser';

const UserPhoto = (props) => {

    return (
        <div className='personal_Avatar_Row'>
            <AccountAvatar
                size={'130px'}
            />
            <div className='personal_Photo'>
                <ButtonsPhotoUser />
            </div>
        </div>
    );
};

export default UserPhoto;