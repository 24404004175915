import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cl from '../../LessonDetail.module.css'
import _ from 'lodash';
import { ModalSmall } from 'components/UI/Modals';
import { BlueButton, ButtonNo, ButtonYes, GreenButton, RedButton, SimpleWhiteButton, YellowButton } from 'components/UI/Buttons';
import LessonsTests from '../../LessonTests/LessonTests';

const LessonShowData = ({ lesson, LessonData, setEdit, cours_id, index, createTest, deleteLesson, RemoveTest }) => {

    const [DeleteConfimVisibility, setDeleteConfimVisibility] = useState(false)
    return (

        <>
            <div className={cl.PrevtopSectionLesson}>
                <div className={cl.prevContent}>
                    <h4>{`Урок ${index}.${lesson?.order}: ${lesson?.name}`}</h4>
                    {lesson?.id_difficulty}
                    <div className={cl.durationlesson}>
                        <p><span className={cl.label}>Время прохождения:</span> {
                            LessonData?.duration ?
                                LessonData?.duration < 60 ?
                                    <p>
                                        {LessonData?.duration + ' мин.'}
                                    </p>
                                    :
                                    <p>
                                        {(LessonData?.duration / 60).toFixed() + ' ч. ' + ((LessonData?.duration) % 60).toFixed(0) + ' мин.'}

                                    </p>
                                : '0 ч. 0 мин.'
                        }</p>
                        <p>
                            <span className={cl.label}>Cтатус:</span>
                            {LessonData?.isDemo === false
                                ? <p>пробный</p>
                                : <p>платный</p>
                            }
                        </p>
                    </div>
                    <div>{LessonData?.description}</div>
                    {/* <ListVertical > */}

                </div>
                <div className={cl.prevBtns}>
                    <GreenButton
                        data-id={lesson?.id}
                        className={cl.ChangeBtn}
                        onClick={() => setEdit(true)}>
                        изменить
                    </GreenButton>
                    <RedButton
                        data-id={lesson?.id}
                        className={cl.ChangeBtn}
                        onClick={(e) => setDeleteConfimVisibility(true)}>
                        удалить
                    </RedButton>
                    {/* <div className={cl.LessonLinks}>
                        <Link to={"/mylearn/courseshow/" + cours_id + "/lessonshow/" + lesson?.id}>
                            <YellowButton
                                data-id={lesson?.id}
                                className={cl.ChangeBtn} >
                                <p>Предпросмотр</p>
                            </YellowButton>
                        </Link>
                    </div> */}

                    {/* <Link to={`/lesson/edit/${cours_id}/${lesson.id}`}>
                        <GreenButton className={cl.ChangeBtn}
                            data-id={lesson.id}>
                            <p>В конструктор</p>
                        </GreenButton>
                    </Link> */}
                </div>
            </div>
            <LessonsTests
                lesson={lesson}
                createTest={createTest}
                RemoveTest={RemoveTest}
            />

            {/* Попап подтверждения удаления теста */}
            <ModalSmall
                visible={DeleteConfimVisibility}
                setVisible={() => setDeleteConfimVisibility(false)}
                className={cl.LessonDeleteAlert}>
                <p>Вы точно хотите удалить урок  {lesson?.label}</p>
                <div className={cl.LessonDeleteAlertBtns}>
                    <ButtonYes onClick={() => deleteLesson(lesson?.id)}></ButtonYes>
                    <ButtonNo onClick={() => setDeleteConfimVisibility(false)}></ButtonNo>
                </div>
            </ModalSmall>
        </>
    );
};

export default LessonShowData;