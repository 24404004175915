import React from 'react';
import cl from './WrapperNoShadow.module.css';
import cn from 'classnames'

const WrapperNoShadow = ({children, className}) => {

    return (
        <div className={cn(cl.defaultWrapper, className)}>
            {children}
        </div>
    );
};

export default WrapperNoShadow;