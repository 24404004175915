import React from 'react';
import './style.css'
import cl from './TeacherCabinete.module.css'
import cn from 'classnames';
import { MainWrap } from 'components/UI/Layouts';
import { BackButton } from 'components/UI/Buttons';
import { Outlet } from 'react-router-dom';
import { DefaultWrapper, NavSidebarElem, NavigateSection } from 'components/UI/Wrappers';
import { CustomLink } from 'components/UI/Links';
import EducationLayout from 'routes/UserComponents/MyLearn/share/EducationLayout/EducationLayout';
import { observer } from 'mobx-react-lite';
import pageState from 'store/pageState';
import ListVertical from 'components/UI/Layouts/ListVertical';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { Layout1200, LayoutFullWidth } from 'routes/UserComponents/MyLearn/share/EducationLayout/styled/EducationLayoutStyled';


const TeacherCabinete = observer(({ headerTitle }) => {
    return (
        <MainWrap headerTitle={headerTitle}>
            <Outlet />
            {/* <Layout1200> */}
            {/* <ListVertical> */}
            {/* {pageState.title ?
                        <p className={cl.pageTitle}>
                            {pageState.title}
                        </p>
                        :
                        <p className={cl.pageTitle}>
                            пусто
                        </p>
                    } */}
            {/* <ContentHorizontal className={cl.PageContent}> */}
            {/* <NavigateSection>
                            <NavSidebarElem back={true}>
                                <BackButton />
                            </NavSidebarElem>
                            <NavSidebarElem to='courses'>
                                <CustomLink>
                                    Курсы
                                </CustomLink>
                            </NavSidebarElem>       
                        </NavigateSection> */}
            {/* </ContentHorizontal> */}
            {/* </ListVertical> */}
            {/* </Layout1200> */}
        </MainWrap >
    )
})
export default TeacherCabinete