import React, { useEffect, useState } from 'react';
import cl from './AddTestPage.module.css'
import cn from 'classnames';
import { MainWrap } from 'components/UI/Layouts';
import EducationLayout from 'routes/UserComponents/MyLearn/share/EducationLayout/EducationLayout';
import TestSettings from './components/TestSettings';
import TeacherQuestionsList from './components/TeacherQuestionsList';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { GreenButton } from 'components/UI/Buttons';
import { useParams } from 'react-router-dom';
import { api } from 'api';
import { Layout1440 } from 'routes/UserComponents/MyLearn/share/EducationLayout/styled/EducationLayoutStyled';

const AddTestPage = () => {
    const { id } = useParams()
    const [TestData, setTestData] = useState() //данные теста (survey + questions)

    useEffect(() => {
        api.get(`Survey/${id}`)
            .then((result) => {
                setTestData(result.data)
            })
    }, [])

    //добавление нового вопроса в список вопросов
    const PushNewQuestion = (data, ActualList) => {
        const { answers, imgQuest, ...any } = data
        api.post(`Question`, { ...any, SurveyId: id, Points: 1 })
            .then(result => {
                if (result.status == 200) {
                    setTestData({
                        ...TestData, questions: ActualList.concat({ question: { id: result.data.id, ...data }, answerOptions: [] })
                        //  ...TestData, questions: TestData.questions.concat({ question: { id: result.data.id, ...data }, answerOptions: [] })
                    })
                }
            })
    }

    //Удаление вопроса из списка из списка
    const DeleteQuestion = (elId) => {
        api.delete(`Question/${elId}`)
            .then((result) => {
                if (result.status == 200) {
                    setTestData({ ...TestData, questions: TestData.questions.filter(elem => (elem.question.id != elId)) })
                }
            })
    }

    //Функция отправки данных теста в базу, здесь должен быть запрос
    const SaveSettings = (newSettings) => {
        api.put(`Survey/${id}`, { ...newSettings })
            .then(result => {
                setTestData({ ...TestData, survey: newSettings })
            })
    }

    const updateQuestionsList = (data) => {
        setTestData({ ...TestData, questions: data })
    }
    return (
        // <div>
        // <MainWrap>
        <Layout1440>
            {TestData?.questions &&
                //Вывод настроек и параметров теста 
                <TestSettings
                    settings={TestData?.survey}
                    saveSettings={SaveSettings}
                />
            }
            {/* //Добавление/создание и вывод вопросов теста */}
            <TeacherQuestionsList
                testLabel={TestData?.label}
                questionsList={TestData?.questions}
                addQuestion={PushNewQuestion}
                deleteQuestion={DeleteQuestion}
                updateQuestionsList={updateQuestionsList}
            />
            {/* <div className={cl.saveBtn}>
                            {/* Кнопка сохранения теста */}
            {/* <GreenButton text='Сохранить тест' onClick={e => SaveTest()}></GreenButton>
                        </div> */}
        </Layout1440>
        // </MainWrap>
        // </div>
    );
};

export default AddTestPage;



