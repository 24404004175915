import React, { useEffect, useState } from 'react';
import cl from './OptionAnswer.module.css'
import SimpleImg from 'components/UI/Images/SimpleImg/SimpleImg';
import { host } from 'routes/globals';
import ListVertical from 'components/UI/Layouts/ListVertical';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';
import { api } from 'api';
import { RedButton } from 'components/UI/Buttons';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import { AnswerOptionInputStyled, RedAnswerBtn, RedQuestionBtn } from '../../styled/QuestionTextStyled';

const OptionAnswer = ({ element, imgAnswers, type, isEdit = false, editAction, index, EditRightAnswer, DeleteAnswer, questionIndex, setEditErrors }) => {
    const [Image, setImg] = useState(null)


    const { register,
        reset,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onTouched' });

    useEffect(() => {
        if (element.imgId != null) {
            fetch(`${host}/api/GetFile/${element.imgId}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
                .then(response => response.status === 200 && response.blob(response))
                .then(blob => {
                    if (blob) {
                        const fileURL = URL.createObjectURL(blob)
                        setImg(fileURL)
                    }
                })
        }
        else {
            setImg(null)
        }
        reset({ textAnswer: element.textAnswer })
    }, [element])

    useEffect(() => {
        errors.textAnswer ? setEditErrors(true) : setEditErrors(false)
        // editAction({ ...element, textAnswer: watch('textAnswer') }, index)
    }, [errors.textAnswer])

    const uploadPhoto = async (event) => {
        var file = event.target.files;
        const formData = new FormData();
        formData.append('file', file[0]);
        const resUpload = await api.post('/uploadfile', formData)
        editAction({ ...element, imgId: await resUpload.data.id }, index)
    }

    const saveAnswer = (answerData) => {
        editAction({ ...element, textAnswer: answerData.textAnswer }, index)
    }

    return (<>
        {
            isEdit
                ?
                <ContentHorizontal className={cl.questionAnswer}>
                    {type == 'checkbox' ?
                        <>
                            <input style={{ transform: 'scale(1.5)', cursor: 'pointer' }}
                                disabled={!isEdit}
                                type={'checkbox'}
                                checked={element?.right}
                                onChange={e => editAction({ ...element, right: e.target.checked }, index)}
                            />
                        </>
                        : null}
                    {type == 'radio' ?
                        <>
                            <input
                                style={{ transform: 'scale(1.5)', cursor: 'pointer'  }}
                                disabled={!isEdit}
                                type={'radio'}
                                name={`q_${questionIndex}`}
                                checked={element?.right}
                                onChange={e => editAction({ ...element, right: e.target.checked }, index)}
                            />
                        </>
                        : null
                    }
                    {
                        imgAnswers &&
                        <>
                            <SimpleImg
                                src={Image}
                                maxW={'200px'}
                            />
                            <input
                                className={cl.InputImg}
                                type={'file'}
                                accept='image/jpeg,image/png'
                                onChange={(e) => uploadPhoto(e)}

                            />
                        </>
                    }
                    <InputValidateUseForm
                        type={'text'}
                        error={errors?.textAnswer}
                        validateSet={register("textAnswer", {
                            required: "Пожалуйста, введите текст ответа",
                            onChange: (e) => { editAction({ ...element, textAnswer: watch('textAnswer') }, index) },
                        })}
                    />
                    <RedAnswerBtn type={'button'} onClick={() => DeleteAnswer(element.id, index)}> Удалить</RedAnswerBtn>
                </ContentHorizontal >
                :

                <AnswerOptionInputStyled className={cl.questionAnswer}>
                    <>
                        <input
                            disabled={!isEdit}
                            type={type}
                            checked={element.right}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        {imgAnswers == true ?
                            Image == null ? null
                                :
                                <SimpleImg
                                    src={Image}
                                    maxW={'200px'}
                                />
                            : null}
                    </>
                    {element?.textAnswer}
                </AnswerOptionInputStyled>
        }
    </>
    );
};

export default OptionAnswer;