import React, { useEffect, useState } from 'react';
import { FullWrap } from '../Payments/Components/PayHistoryElement/styled/HistoryElem.styled';
import { DefaultWrapper } from 'components/UI/Wrappers';
import ListVertical from 'components/UI/Layouts/ListVertical';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'components/UI/Inputs/HookForm';
import { usePassEncrypt } from 'components/hooks';
import { api } from 'api';
import { AcceptButton } from 'components/UI/Buttons';
import { MainPopup } from 'components/UI/Popups';
import { useLocation } from 'react-router-dom';
import { ErrorField } from 'components/UI/Errors';
import { BlueValidateInput, Line } from 'components/UI/Inputs/HookForm/InputValidateUseForm';
import { CustomLinkFit, EditProfileForm, ProfileBackButton, ProfileHeader } from '../styled/ProfileStyles';
import { BlueAcceptButton, SplitLine } from 'components/UI/Inputs/HookForm/InputValidateUseForm/styled/BlueValidateInput.styled';
import { CustomLink } from 'components/UI/Links';
import { BlueWrap } from 'routes/PublicComponents/Pages/PageRegTeacher/StyledComponents/BlueWrap';
import ContentHorizontal from 'components/UI/Layouts/ContentHorizontal';

const PasswordChanger = () => {

    const [SendOnMail, setSendOnMail] = useState(false)
    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'OnInput' });
    const location = useLocation()

    const passEncrypt = usePassEncrypt()

    const SetNewPassWord = (data) => {

        data.newpassword !== data.newPasswordConffirm
            ? setError('newPasswordConffirm', { validate: '' })
            : (() => {
                api.post(`user/ChangePassword`, {
                    password: passEncrypt(data.password),
                    newpassword: passEncrypt(data.newpassword)

                })
                    .then(() => { setSendOnMail(true); })
                    .catch(error => {
                        if (error.response.status === 401) {
                            setError('password', { type: 'custom', message: 'Неверный пароль' });
                        }
                    })
            })()
    }

    return (
        <FullWrap width={'100%'}>
            <EditProfileForm onSubmit={handleSubmit(SetNewPassWord)} >
                <ListVertical>
                    <ContentHorizontal style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                        <CustomLink style={{ width: 'fit-content' }} to='/profile/security'>
                            <ProfileBackButton>Назад</ProfileBackButton>
                        </CustomLink>
                    </ContentHorizontal>
                    <ProfileHeader>Смена пароля</ProfileHeader>
                    <ErrorField
                        confirm={location?.state?.change === 'success' ? 0 : 1}
                        error={location?.state?.message}
                    />
                    <InputValidateUseForm
                        title={'Старый пароль'}
                        type={'password'}
                        error={errors?.password}
                        validateSet={register("password", {
                            required: "Пожалуйста введите старый пароль.",
                            validate: "Пароль неверный"
                        })}
                    />
                    <InputValidateUseForm
                        title={'Новый пароль'}
                        type={'password'}
                        error={errors?.newpassword}
                        validateSet={register("newpassword", {
                            required: "Пожалуйста введите новый пароль.",
                            // validate: value => value == watch('newPasswordConffirm') || 'пароли не ссвпадают' 
                        })}
                    />
                    <InputValidateUseForm
                        title={'Подтверждение пароля'}
                        type={'password'}
                        error={errors?.newPasswordConffirm}
                        validateSet={register("newPasswordConffirm", {
                            required: "Пожалуйста введите новый пароль ещё раз",
                            validate: value => value == watch('newpassword') || 'пароли не совпадают'
                        })}
                    />
                    <SplitLine />
                    <BlueAcceptButton title={'Сменить'} />
                </ListVertical>
            </EditProfileForm>
            <MainPopup
                isOpened={SendOnMail}
                onClose={e => setSendOnMail(false)}
                title={'!!!'}
                showHeader={true}>
                Письмо с подтверждением смены пароля отправлено вам на электроную почту!!
                <AcceptButton title={'Отлично'} onClick={() => setSendOnMail(false)}></AcceptButton>
            </MainPopup>
        </FullWrap >
    );
};

export default PasswordChanger;